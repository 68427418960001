// import LazyLoad from 'react-lazy-load';
import whiteImage from 'assets/images/white-img.png';
import { Flex } from 'atoms/Flex';
import React, { FC, useCallback, useEffect, useState } from 'react';
// import { BRAND_PLACEHOLDER } from 'settings/constants';
import { BaseWrapperProps, ImageWrapper } from './Image.styles';

interface BaseImageWrapperProps extends BaseWrapperProps {
	src?: any;
	alt?: string | null;
	/**
	 * If true, will enable lazy loading of the image,(will load image when scrolled over)
	 */
	lazy?: boolean;
}

type LazyImageProps = {} & Omit<BaseImageWrapperProps, 'lazy'>;

const LazyImage: FC<LazyImageProps> = ({ src, alt }) => {
	const [loaded, setLoaded] = useState(false);
	const [fetched, setFetched] = useState(false);

	// lazy loaded
	const contentLoaded = useCallback(() => {
		setLoaded(true);
	}, []);

	console.warn('unused component => ', contentLoaded);

	const contentFetched = useCallback(() => {
		setFetched(true);
	}, []);

	return (
		<>
			{/* <LazyLoad debounce={false} offset={500} height="100%" onContentVisible={contentLoaded}> */}
			<Flex opacity={Number(fetched)} height="100%">
				<img
					src={src}
					alt={alt as string | undefined}
					width="100%"
					height="100%"
					onLoad={contentFetched}
				/>
			</Flex>
			{/* </LazyLoad> */}
			{(!loaded || !fetched) && (
				<Flex
					height="100%"
					opacity={Number(!loaded || !fetched)}
					position="absolute"
					top={0}
					left={0}
					right={0}
					maxWidth={{ xs: '20rem', md: '35rem' }}
					margin="auto"
				></Flex>
			)}
		</>
	);
};

export const Image: FC<BaseImageWrapperProps> = React.memo(
	({ lazy, src, alt, ...rest }) => {
		const [srcImg, setSrcImg] = useState(src);

		useEffect(() => {
			setSrcImg(src);
		}, [src]);

		function onError() {
			setSrcImg(whiteImage);
		}

		return (
			<ImageWrapper {...rest}>
				{lazy ? (
					<LazyImage src={srcImg} alt={alt} />
				) : (
					<img
						onError={onError}
						src={srcImg || whiteImage}
						alt={alt as string | undefined}
						width="100%"
						height="100%"
					/>
				)}
			</ImageWrapper>
		);
	}
);
