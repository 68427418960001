import { InputFieldBase, Flex, Text } from 'atoms';
import { Error } from 'atoms/Error';
import { Warning } from 'atoms/Warning';
import ReactPhoneInput, { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import './phonenumber.css';
const StyledPhoneInput = styled(ReactPhoneInput)``;
export interface PhoneInputProps extends ReactPhoneInputProps {
	label?: string;
	error?: string;
	warning?: string;
	showOptional?: boolean;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
	label,
	error,
	warning,
	showOptional,
	...props
}: PhoneInputProps) => {
	return (
		<InputFieldBase>
			{/* <Text as="label" fontSize="16px" color="primary.50" fontFamily="semibold">
				{label}
      </Text> */}

			<Flex justifyContent={'space-between'}>
				<Text as="label" fontSize="h5" color="primary.50" fontFamily="semibold">
					{label}
				</Text>
				{showOptional && <Text>Optional</Text>}
			</Flex>

			<StyledPhoneInput
				country={'gb'}
				preferredCountries={['gb', 'us']}
				containerClass={`phone-number ${error && 'error'}`}
				inputClass={`phone-input ${error && 'error'}`}
				placeholder={'Phone number'}
				{...props}
				containerStyle={{ marginBottom: '1.6rem' }}
			/>
			{error && <Error text={error} bottom="0%" />}
			{warning && <Warning text={warning} />}
		</InputFieldBase>
	);
};
