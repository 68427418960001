import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isNull, isEmpty } from 'lodash';

import { Box, Icon, Flex } from 'atoms';
import Welcome from './Welcome';
import SetupAccount from './SetupAccount';
import { base64 } from 'utils/helpers';

import useAuth from 'pages/Auth/context/Authentication';

export const FormContext = createContext({});

type formValuesType = {
	userType: number;
	type: string;
};

function RegisterPage({ userType, type }: formValuesType) {
	const navigate = useNavigate();
	const {
		actions: { register, switchProtal },
	} = useAuth();

	const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

	const [formData, setFormData] = useState<any>({});
	const [serverErrors, setServerErrors] = useState<any>([]);

	function _handleSubmit(values: any) {
		if (activeStepIndex === 1) {
			register({ ...values, ...formData }, ({ data, error }: any) => {
				if (data) {
					if (type === 'regular') {
						if (
							values?.googleClientId &&
							!isNull(values?.googleClientId) &&
							!isEmpty(values?.googleClientId)
						) {
							switchProtal('coach');
							navigate('/plans'); //Move to client profile.
						} else if (data.status === 1) {
							//Check if email is verified, else, send user to confirm email page.
							const coach = data.roles.coach;
							// Check the highest profile users higher profile is coach.
							if (coach) {
								switchProtal('coach');
								navigate('/coach/home'); //Move to coach profile.
							} else {
								switchProtal('coachee');
								navigate('/coachee/home'); //Move to client profile.
							}
						} else {
							navigate(
								`/confirm-email?code=${base64.encode(
									JSON.stringify({ email: values.email })
								)}`
							);
						}
					} else {
						if (data?.inviteInfo?.role === 1) {
							//Check if register is from invite and user is client.
							switchProtal('coachee');
							navigate('/coachee/home');
						} else {
							if (
								values?.googleClientId &&
								!isNull(values?.googleClientId) &&
								!isEmpty(values?.googleClientId)
							) {
								switchProtal('coach');
								navigate('/plans'); //Move to client profile.
							} else {
								//Redirect to a invited coach/supervisor to Brand profile page.
								switchProtal('coach');
								navigate('/coach/home');
							}
						}
					}
				}
				if (error) {
					setServerErrors(error?.errors || []);
				}
			});
		} else {
			setFormData(values);
			setActiveStepIndex(activeStepIndex + 1);
		}
	}

	return (
		<FormContext.Provider
			value={{
				activeStepIndex,
				setActiveStepIndex,
				formData,
				setFormData,
				serverErrors,
			}}
		>
			{/* <Text
				fontSize="h4"
				lineHeight="h4"
				color="secondary.50"
				fontFamily="Archivo-Bold"
				mt="0.7rem"
				mb="5.6rem"
			>
				My Coaching Place
			</Text> */}

			<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
				<Icon name="logo" height="7rem" type={'text'} />
			</Flex>

			<Box>
				{activeStepIndex === 1 ? (
					<SetupAccount onSubmit={_handleSubmit} />
				) : (
					<>
						<Flex
							//height={{xs:"unset", md:"calc( 100vh - 18rem )"}}
							flexDirection="column"
							justifyContent="space-between"
						>
							<Welcome
								userType={userType === 1 ? 'coachee' : 'coach'}
								onSubmit={_handleSubmit}
							/>
						</Flex>
					</>
				)}
			</Box>
		</FormContext.Provider>
	);
}

export default RegisterPage;
