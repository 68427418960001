import * as React from 'react';

function MoreIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="4"
			height="14"
			viewBox="0 0 4 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
      {...props}
		>
			<path
				d="M1.75 7.74999C2.16421 7.74999 2.5 7.41421 2.5 6.99999C2.5 6.58578 2.16421 6.24999 1.75 6.24999C1.33579 6.24999 1 6.58578 1 6.99999C1 7.41421 1.33579 7.74999 1.75 7.74999Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const MemoMoreIcon = React.memo(MoreIcon);
export default MemoMoreIcon;
