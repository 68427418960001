import { Empty } from 'antd';
import { Box, Button, Flex, Icon, StyledNavLink, Text } from 'atoms';
import parse from 'html-react-parser';
import moment from 'moment';
import React from 'react';

interface NoticationDrawerProps {
	onClose: () => void;
	onMarkAllRead: () => void;
	onMarkOneRead: any;
	list: any;
}
const getIcon = (module: string = '') => {
	if (module == 'Tasks') return 'tasks';
	else if (module == 'Sessions') return 'sessions';
	else if (module == 'Connections') return 'users';
	else if (module == 'Messages') return 'messages';
	else if (module == 'Subscription') return 'tasks';
	else return 'users';
};

function removeCurrentPortal(link: string) {
	/**
	 * For instant meeting form backend they are sending the /coach, /client string in link,
	 * this function will remove tha
	 */
	if (link.includes('conference')) {
		return link.replace('/coachee', '').replace('/coach', '');
	}
	if (link.includes('schedules')) {
		return link.replace('+00:00', '.000Z');
	}
	return link;
}

function isConference(link: string) {
	/**
	 * For instant meeting form backend they are sending the /coach, /client string in link,
	 * so open that link in new tab
	 */
	if (link.includes('conference')) {
		return '_blank';
	}
	return '_self';
}

export const NotificationDrawer: React.FC<NoticationDrawerProps> = ({
	onClose,
	onMarkAllRead,
	onMarkOneRead,
	list = [],
}: NoticationDrawerProps) => {
	return (
		<Box
			position="absolute"
			background="white.0"
			right={{ xs: '-5rem', md: '-10px' }}
			top="97%"
			p="2rem 1rem"
			minWidth="min(80vw,40rem)"
			// minHeight="15rem"
			maxHeight="40rem"
			overflowY="scroll"
			className="scroll-hide"
			boxShadow="0 0 20px rgba(179,179,179,0.19)"
			borderRadius="5px"
			onMouseLeave={onClose}
			zIndex={1}
		>
			<Box maxHeight="30rem" overflow="auto">
				{list.length === 0 && (
					<Empty
						description={
							<Box as="h5" fontSize="12px" fontFamily="regular">
								No notifications available
							</Box>
						}
						style={{ height: '15rem' }}
					/>
				)}

				{list.length > 0 &&
					list.map((x: any, index: any) => (
						<>
							{x?.redirectURL ? (
								<StyledNavLink
									to={removeCurrentPortal(`${x?.redirectURL}`)}
									target={isConference(`${x?.redirectURL}`)}
									key={index}
								>
									<Flex
										p="1rem 4px"
										borderBottom={`${
											index === list.length - 1 ? '0' : '1'
										}px solid rgba(0,0,0,0.1)`}
										cursor="pointer"
										onClick={() => {
											onMarkOneRead(x?._id);
										}}
									>
										<Box width="2rem">
											{x?.notification?.module && (
												<Icon
													name={getIcon(x?.notification?.module)}
													fill={x.read ? '#8A8A8A' : '#4BB7BA'}
													width="2rem"
													height="2rem"
												/>
											)}
										</Box>
										{/* <Icon name="messages" fill="#000000" width="2rem" height="2rem" />
											<Icon name="sessions" fill="#000000" width="2rem" height="2rem" />
											<Icon name="users" fill="#000000" width="2rem" height="2rem" /> */}
										<Box ml="1rem">
											<Text
												as="h5"
												fontSize="14px"
												fontFamily="bold"
												color={x.read ? 'grey.900' : 'primary.50'}
												mb="8px"
											>
												<Box
													className="remove-p-padding"
													style={{
														overflowWrap: 'break-word',
													}}
												>
													{parse(x.msgContent) || x.msgContent}
												</Box>
											</Text>
											<Text
												as="h5"
												fontSize="12px"
												fontFamily="regular"
												color={x.status === 1 ? 'grey.900' : 'primary.50'}
												textTransform="capitalize"
											>
												<Box
													style={{
														overflowWrap: 'break-word',
													}}
												>
													{moment
														.utc(x.createdAt, 'YYYY-MM-DDTHH:mm:ss')
														.fromNow(true)}{' '}
													ago
												</Box>
											</Text>
										</Box>
									</Flex>
								</StyledNavLink>
							) : (
								<Flex
									p="1rem 4px"
									key={index}
									borderBottom={`${
										index === list.length - 1 ? '0' : '1'
									}px solid rgba(0,0,0,0.1)`}
									cursor="pointer"
									onClick={() => {
										onMarkOneRead(x?._id);
									}}
								>
									<Box width="2rem">
										{x?.notification?.module && (
											<Icon
												name={getIcon(x?.notification?.module)}
												fill={x.read ? '#8A8A8A' : '#4BB7BA'}
												width="2rem"
												height="2rem"
											/>
										)}
									</Box>
									{/* <Icon name="messages" fill="#000000" width="2rem" height="2rem" />
									<Icon name="sessions" fill="#000000" width="2rem" height="2rem" />
									<Icon name="users" fill="#000000" width="2rem" height="2rem" /> */}
									<Box ml="1rem">
										<Text
											as="h5"
											fontSize="14px"
											fontFamily="bold"
											color={x.read ? 'grey.900' : 'primary.50'}
											mb="8px"
										>
											<Box
												className="remove-p-padding"
												style={{
													overflowWrap: 'break-word',
												}}
											>
												{parse(x.msgContent) || x.msgContent}
											</Box>
										</Text>
										<Text
											as="h5"
											fontSize="12px"
											fontFamily="regular"
											color={x.status === 1 ? 'grey.900' : 'primary.50'}
											textTransform="capitalize"
										>
											<Box
												style={{
													overflowWrap: 'break-word',
												}}
											>
												{moment
													.utc(x.createdAt, 'YYYY-MM-DDTHH:mm:ss')
													.fromNow(true)}{' '}
												ago
											</Box>
										</Text>
									</Box>
								</Flex>
							)}
						</>
					))}
			</Box>
			{list.length > 1 && (
				<Box borderTop="1px solid rgba(0,0,0,0.1)">
					<Button
						variant="transparent"
						width="100%"
						onClick={() => {
							onMarkAllRead();
							onClose();
						}}
					>
						Mark all as read
					</Button>
				</Box>
			)}
		</Box>
	);
};
