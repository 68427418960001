import React from 'react';

import { Box } from '../Box';

interface LoaderProps {
	children?: React.ReactNode;
}

export const Tag = ({ children }: LoaderProps) => {
	return (
		<Box m="4px" p="2px 12px" bg="grey.200" borderRadius="5px" fontSize="1.6rem" width="fit-content">
			{children}
		</Box>
	);
};
