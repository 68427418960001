import { DatePickerField, DatePickerFieldProps } from 'atoms/DatePickerField';
import { useField } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { Merge } from 'typings/utils';

interface FormProps {
	name: string;
	marginBottom?: string;
	labelSpace?: string;
	placeholder?: string;
	labelHide?: boolean;
	disabled?: boolean;
	onChangeCustom?: any;
}
export type FormDatePickerProps = Merge<FormProps, DatePickerFieldProps>;

export const FormDatePicker: FC<FormDatePickerProps> = ({
	name = '',
	onChangeCustom,
	...props
}) => {
	const [field, meta] = useField(name);
	const error = (meta.touched && meta.error) || '';
	return (
		<DatePickerField
			{...field}
			error={error}
			onChangeDate={(date, dateStrings) => {
				field.onChange({ target: { name, value: date } });
				onChangeCustom && onChangeCustom(date);
			}}
			{...props}
			value={
				typeof field.value === 'string' ? moment(field.value) : field.value || undefined
			}
		/>
	);
};
