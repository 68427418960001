import * as React from 'react';

function GlobeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M8.5 15.584a7.083 7.083 0 100-14.167 7.083 7.083 0 000 14.167zM1.416 8.5h14.167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1.417A10.837 10.837 0 0111.332 8.5a10.838 10.838 0 01-2.834 7.084A10.838 10.838 0 015.666 8.5a10.837 10.837 0 012.833-7.083v0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoGlobeIcon = React.memo(GlobeIcon);
export default MemoGlobeIcon;
