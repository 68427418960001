import * as React from 'react';

function TemplateIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="16"
			viewBox="0 0 15 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.8335 0C1.00507 0 0.333496 0.671573 0.333496 1.5V14.5C0.333496 15.3284 1.00507 16 1.8335 16H12.8335C13.6619 16 14.3335 15.3284 14.3335 14.5V5.62132C14.3335 5.2235 14.1755 4.84197 13.8942 4.56066L9.77284 0.43934C9.49153 0.158035 9.11 0 8.71218 0H1.8335ZM4.0835 8.5C3.66928 8.5 3.3335 8.83579 3.3335 9.25C3.3335 9.66421 3.66928 10 4.0835 10H10.5835C10.9977 10 11.3335 9.66421 11.3335 9.25C11.3335 8.83579 10.9977 8.5 10.5835 8.5H4.0835ZM4.0835 11.5C3.66928 11.5 3.3335 11.8358 3.3335 12.25C3.3335 12.6642 3.66928 13 4.0835 13H10.5835C10.9977 13 11.3335 12.6642 11.3335 12.25C11.3335 11.8358 10.9977 11.5 10.5835 11.5H4.0835Z"
				fill="#6B7280"
			/>
		</svg>
	);
}

export const MemoTemplateIcon = React.memo(TemplateIcon);
export default MemoTemplateIcon;
