import * as React from 'react';

function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32.02 32.02" {...props}>
      <path d="M32.02 27.26c0 .274-.225.5-.5.5H.499a.499.499 0 01-.418-.773L14.665 4.488a.499.499 0 01.824-.024L31.838 26.87c.11.095.182.234.182.39z" />
    </svg>
  );
}

const MemoArrowUp = React.memo(ArrowUp);
export default MemoArrowUp;
