import * as React from 'react';
import IconProps from './IconProps';
function ScheduleIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				width="22"
				height="22"
				viewBox="0 0 22 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.58301 1.25C7.99722 1.25 8.33301 1.58579 8.33301 2V3.83398H14.167V2C14.167 1.58579 14.5028 1.25 14.917 1.25C15.3312 1.25 15.667 1.58579 15.667 2V3.83398H17.6667C18.6792 3.83398 19.5 4.6548 19.5 5.66732V8.25H3V5.66732C3 4.6548 3.82081 3.83398 4.83333 3.83398H6.83301V2C6.83301 1.58579 7.16879 1.25 7.58301 1.25Z"
					fill="white"
				/>
				<path
					d="M19.5 9.75V18.5007C19.5 19.5132 18.6792 20.334 17.6667 20.334H4.83333C3.82081 20.334 3 19.5132 3 18.5007V9.75H19.5Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.4167 3.66699H4.58333C3.57081 3.66699 2.75 4.4878 2.75 5.50033V18.3337C2.75 19.3462 3.57081 20.167 4.58333 20.167H17.4167C18.4292 20.167 19.25 19.3462 19.25 18.3337V5.50033C19.25 4.4878 18.4292 3.66699 17.4167 3.66699Z"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.667 1.83301V5.49967"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.33301 1.83301V5.49967"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.75 9.16699H19.25"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const MemoScheduleIcon = React.memo(ScheduleIcon);
export default MemoScheduleIcon;
