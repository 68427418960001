import * as React from 'react';

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M2.833 2.833h11.333c.78 0 1.417.638 1.417 1.417v8.5a1.42 1.42 0 01-1.417 1.416H2.833a1.42 1.42 0 01-1.417-1.416v-8.5c0-.78.638-1.417 1.417-1.417z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.583 4.25L8.499 9.208 1.416 4.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMailIcon = React.memo(MailIcon);
export default MemoMailIcon;
