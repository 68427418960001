import { Dropdown, Menu, message } from 'antd';
import { Text } from 'atoms';
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
var querystring = require('querystring');

type MenuType = {
	isLink?: boolean;
	link?: string;
	onClick: (item: any) => void;
	label: string;
	key: string;
};

interface DropdownMenuProps {
	children: React.ReactNode;
	menuItems?: any;
}

const returnMenu = (menuItems: any) => {
	return (
		<Menu getPopupContainer={(trigger: any) => trigger.parentNode}>
			{menuItems.map((item: MenuType) => {
				if (item === null) return;
				if (item.key === 'google') {
					return (
						<Menu.Item key={item.key}>
							<GoogleLogin
								accessType="offline"
								responseType="code"
								clientId={`${process.env.REACT_APP_GOOGLE_APP_ID}`}
								scope="https://www.googleapis.com/auth/calendar"
								onScriptLoadFailure={(error) => {
									if (error) {
										if (
											error.error ===
											'idpiframe_initialization_failed'
										) {
											message.error(
												'Issue accessing Cookies, Please unblock third party cookies in your browser'
											);
										}
									}
								}}
								render={(renderProps) => {
									return (
										<Text
											as="label"
											fontSize="12px"
											color="primary.50"
											fontFamily="medium"
											{...renderProps}
										>
											{item.label}
										</Text>
									);
								}}
								onSuccess={(response) => {
									axios
										.post(
											'https://oauth2.googleapis.com/token',
											querystring.stringify({
												client_id:
													process.env
														.REACT_APP_GOOGLE_APP_ID,
												client_secret:
													process.env
														.REACT_APP_GOOGLE_APP_CLIENT_SECRET,
												code: response?.code,
												grant_type:
													'authorization_code',
												access_type: 'offline',
												// redirect_uri:
												// 	'http://localhost:3000',
												redirect_uri:
													process.env
														.REACT_APP_REDIRECT_URL,
											}),
											{
												headers: {
													'Content-Type':
														'application/x-www-form-urlencoded',
												},
											}
										)
										.then(function (response) {
											const {
												id_token,
												access_token,
												refresh_token,
												profileObj,
											}: any = response.data;
											item.onClick({
												googleIdToken: id_token,
												googleClientId:
													process.env
														.REACT_APP_GOOGLE_APP_ID,
												googleAccessToken: access_token,
												googleSyncEmail:
													profileObj?.email,
												googleSyncAccessToken:
													refresh_token,
											});
										});
								}}
								onFailure={(error) => {
									if (
										error.error !==
											'popup_closed_by_user' &&
										error.error !==
											'idpiframe_initialization_failed'
									)
										return message.error(
											JSON.stringify(error)
										);
									return;
								}}
								cookiePolicy={'single_host_origin'}
								//redirectUri={'http://localhost:3000'}
								redirectUri={process.env.REACT_APP_REDIRECT_URL}
							/>
						</Menu.Item>
					);
				}
				return (
					<Menu.Item
						onClick={() => item.onClick(item)}
						key={item.key}
					>
						<a href={item.link}>
							<Text
								as="label"
								fontSize="12px"
								color="primary.50"
								fontFamily="medium"
							>
								{item.label}
							</Text>
						</a>
					</Menu.Item>
				);
			})}
		</Menu>
	);
};

export const DrowdownMenu: React.FC<DropdownMenuProps> = ({
	children,
	menuItems = [],
}: DropdownMenuProps) => {
	return (
		<Dropdown overlay={returnMenu(menuItems)} placement="bottomRight">
			{children}
		</Dropdown>
	);
};
