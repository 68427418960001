import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

export function useGoogleAnalytics() {
	useEffect(() => {
		// Initialize Google Analytics 4 with your measurement ID
		ReactGA4.initialize('G-K9HTBYJTL5');
	}, []);

	const trackPageView = (pathname: string) => {
		// Track a page view for a specific pathname
		ReactGA4.send(pathname);
	};

	const trackEvent = (eventName: string, eventParams: Record<string, any>) => {
		// Track custom events
		ReactGA4.event({ action: eventName, ...eventParams });
	};

	return { trackPageView, trackEvent };
}
