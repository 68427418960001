import * as React from 'react';

function ResourcesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none"
		>
			<path
				d="M5.39998 2.20039C5.39998 1.20628 6.20586 0.400391 7.19998 0.400391H11.8544C12.3318 0.400391 12.7896 0.590033 13.1272 0.927598L16.8728 4.67318C17.2103 5.01075 17.4 5.46859 17.4 5.94598V13.0004C17.4 13.9945 16.5941 14.8004 15.6 14.8004H14.4V10.746C14.4 9.7912 14.0207 8.87552 13.3456 8.20039L9.59997 4.45481C8.92484 3.77968 8.00917 3.40039 7.05439 3.40039H5.39998V2.20039Z"
				fill="white"
			/>
			<path
				d="M2.39998 5.20039C1.40586 5.20039 0.599976 6.00628 0.599976 7.00039V17.8004C0.599976 18.7945 1.40586 19.6004 2.39998 19.6004H10.8C11.7941 19.6004 12.6 18.7945 12.6 17.8004V10.746C12.6 10.2686 12.4103 9.81075 12.0728 9.47318L8.32718 5.7276C7.98962 5.39003 7.53178 5.20039 7.05439 5.20039H2.39998Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoResourcesIcon = React.memo(ResourcesIcon);
export default MemoResourcesIcon;
