import * as React from 'react';

function RefreshIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.24967 13.3747C0.944119 12.8469 0.714952 12.3052 0.562174 11.7497C0.409397 11.1941 0.333008 10.6247 0.333008 10.0413C0.333008 8.18023 0.978841 6.5969 2.27051 5.29134C3.56217 3.98579 5.13856 3.33301 6.99967 3.33301H7.14551L5.81217 1.99967L6.97884 0.833008L10.3122 4.16634L6.97884 7.49967L5.81217 6.33301L7.14551 4.99967H6.99967C5.61079 4.99967 4.43023 5.48926 3.45801 6.46842C2.48579 7.44759 1.99967 8.63856 1.99967 10.0413C1.99967 10.4025 2.04134 10.7566 2.12467 11.1038C2.20801 11.4511 2.33301 11.7913 2.49967 12.1247L1.24967 13.3747ZM7.02051 19.1663L3.68717 15.833L7.02051 12.4997L8.18717 13.6663L6.85384 14.9997H6.99967C8.38856 14.9997 9.56912 14.5101 10.5413 13.5309C11.5136 12.5518 11.9997 11.3608 11.9997 9.95801C11.9997 9.5969 11.958 9.24273 11.8747 8.89551C11.7913 8.54829 11.6663 8.20801 11.4997 7.87467L12.7497 6.62467C13.0552 7.15245 13.2844 7.69412 13.4372 8.24968C13.59 8.80523 13.6663 9.37467 13.6663 9.95801C13.6663 11.8191 13.0205 13.4025 11.7288 14.708C10.4372 16.0136 8.86079 16.6663 6.99967 16.6663H6.85384L8.18717 17.9997L7.02051 19.1663Z" fill="white"/>
		</svg>

	);
}

const MemoRefreshIcon = React.memo(RefreshIcon);
export default MemoRefreshIcon;
