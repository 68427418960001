/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { Button, Icon } from 'atoms';
import classNames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import { MediaDevice } from '../video-types';
import CallOutModal from './call-out-modal';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
interface MicrophoneButtonProps {
	isStartedAudio: boolean;
	isMuted: boolean;
	isSupportPhone?: boolean;
	disabled?: boolean;
	audio?: string;
	phoneCountryList?: any[];
	onMicrophoneClick: () => void;
	onMicrophoneMenuClick: (key: string) => void;
	onPhoneCallClick?: (code: string, phoneNo: string, name: string, option: any) => void;
	onPhoneCallCancel?: (code: string, phoneNo: string, option: any) => Promise<any>;
	className?: string;
	microphoneList?: MediaDevice[];
	speakerList?: MediaDevice[];
	activeMicrophone?: string;
	activeSpeaker?: string;
	phoneCallStatus?: { text: string; type: string };
}
const MicrophoneButton = (props: MicrophoneButtonProps) => {
	const {
		isStartedAudio,
		isSupportPhone,
		isMuted,
		audio,
		className,
		microphoneList,
		speakerList,
		phoneCountryList,
		activeMicrophone,
		activeSpeaker,
		phoneCallStatus,
		disabled,
		onMicrophoneClick,
		onMicrophoneMenuClick,
		onPhoneCallClick,
		onPhoneCallCancel,
	} = props;
	const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
	const tooltipText = isStartedAudio ? (isMuted ? 'Unmute' : 'Mute') : 'Start Audio';
	const menuItems = [];
	if (microphoneList?.length && audio !== 'phone') {
		menuItems.push(
			getAntdItem(
				'Select a Microphone',
				'microphone',
				undefined,
				microphoneList.map((i) =>
					getAntdItem(
						i.label,
						`microphone|${i.deviceId}`,
						activeMicrophone === i.deviceId && <CheckOutlined />
					)
				),
				'group'
			)
		);
		menuItems.push(getAntdItem('', 'd1', undefined, undefined, 'divider'));
	}
	if (speakerList?.length && audio !== 'phone') {
		menuItems.push(
			getAntdItem(
				'Select a speaker',
				'speaker',
				undefined,
				speakerList.map((i) =>
					getAntdItem(
						i.label,
						`speaker|${i.deviceId}`,
						activeSpeaker === i.deviceId && <CheckOutlined />
					)
				),
				'group'
			)
		);
		menuItems.push(getAntdItem('', 'd2', undefined, undefined, 'divider'));
	}
	if (audio !== 'phone') {
		menuItems.push(getAntdItem('Audio Statistic', 'statistic'));
	}
	menuItems.push(getAntdItem(audio === 'phone' ? 'Hang Up' : 'Leave Audio', 'leave audio'));

	const onMenuItemClick = (payload: { key: any }) => {
		onMicrophoneMenuClick(payload.key);
	};
	const onPhoneMenuClick = (payload: { key: any }) => {
		if (payload.key === 'phone') {
			setIsPhoneModalOpen(true);
		}
	};
	useEffect(() => {
		if (isStartedAudio) {
			setIsPhoneModalOpen(false);
		}
	}, [isStartedAudio]);
	return (
		<div className={classNames('microphone-footer', className)}>
			<Tooltip title={tooltipText} color="#ffffff55">
				<Button
					mx="8px"
					display="flex"
					borderRadius="50%"
					height="50px !important"
					width="50px !important"
					justifyContent="center"
					alignItems="center"
					cursor="pointer"
					variant={`${isMuted ? 'danger' : 'dark'}`}
					onClick={onMicrophoneClick}
				>
					<Icon
						name="audio"
						type={`${!isMuted ? 'on' : 'off'}`}
						stroke="#ffffff"
						width="24px"
						height="24px"
					/>
				</Button>
			</Tooltip>
		</div>
	);
};

export default MicrophoneButton;
