import * as React from 'react';

function ContractIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M2.19485 13.7618L0.933058 16.9163C0.769796 17.3244 1.17484 17.7295 1.58299 17.5662L4.73746 16.3044C5.24042 16.1033 5.69728 15.802 6.08033 15.419L16.9995 4.50023C17.8279 3.6718 17.8279 2.32865 16.9995 1.50023C16.1711 0.671801 14.8279 0.671798 13.9995 1.50023L3.08033 12.419C2.69728 12.802 2.39603 13.2589 2.19485 13.7618Z"
				fill="#4B5563"
			/>
		</svg>
	);
}

export const MemoContractIcon = React.memo(ContractIcon);
export default MemoContractIcon;
