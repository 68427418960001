import * as React from 'react';

function UsersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M12.042 14.875v-1.417a2.833 2.833 0 00-2.833-2.833H3.542A2.833 2.833 0 00.71 13.458v1.417M6.374 7.792a2.833 2.833 0 100-5.667 2.833 2.833 0 000 5.667zM16.291 14.875v-1.417a2.833 2.833 0 00-2.125-2.741M11.334 2.217a2.834 2.834 0 010 5.49"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoUsersIcon = React.memo(UsersIcon);
export default MemoUsersIcon;
