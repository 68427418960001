import { Flex, StyledNavLink, Text } from 'atoms';

export const AccountDeletedPage = () => {
	return (
		<Flex
			width="100vw"
			height="100vh"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
		>
			<Text as="h1" fontFamily="medium" fontSize="18px" color="primary.50">
				Account Deleted
			</Text>
			<Text fontFamily="regular" fontSize="16px" color="primary.50">
				We are sorry to see you go. We are always looking for way to improve MCP. Please let
				us know if there is any way we can help you.
			</Text>
			<StyledNavLink to="/">
				<Text as="p" fontSize="h1" color="secondary.0">
					← Go back to Home Page
				</Text>
			</StyledNavLink>
		</Flex>
	);
};
