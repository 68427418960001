import { useEffect, useRef } from 'react';

const useIdleTimeout = (callback: () => void, options: { timeout: number }) => {
	const { timeout } = options;
	const timeoutId: any = useRef<number | null>(null);

	const resetTimeout = () => {
		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		timeoutId.current = setTimeout(callback, timeout);
	};

	useEffect(() => {
		const handleUserActivity = () => {
			resetTimeout();
		};

		resetTimeout(); // Set initial timeout

		// Attach event listeners for user activity
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);
		window.addEventListener('touchstart', handleUserActivity);

		// Cleanup event listeners on component unmount
		return () => {
			if (timeoutId.current) {
				clearTimeout(timeoutId.current);
			}

			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
			window.removeEventListener('touchstart', handleUserActivity);
		};
	}, [callback, timeout]);

	return { resetTimeout };
};

export default useIdleTimeout;
