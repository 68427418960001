/* eslint-disable no-restricted-globals */
import ZoomVideo from '@zoom/videosdk';
import { useEffect } from 'react';
import './index.css';
import App from './App';
import ZoomContext from './context/zoom-context';
import { devConfig } from './config/dev';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'pages/Auth/context/Authentication';
import { getSessionById } from 'pages/Session/services';
import { b64DecodeUnicode, generateVideoToken } from './utils/util';
import { SessionProvider } from 'pages/Session/context/sessions';
import { UserProvider } from 'pages/Users/context/users';
import { Routes, Route } from 'react-router-dom';
import Preview from './feature/preview/preview';

let meetingArgs: any = Object.fromEntries(new URLSearchParams(location.search));
// Add enforceGalleryView to turn on the gallery view without SharedAddayBuffer
// const key = window.location.search.replace('?session=', '');

export const Meeting = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const {
		state: { currentPortal: userType, user },
	} = useAuth();

	console.log('state: ', state);

	useEffect(() => {
		return () => {
			navigate(0);
		};
	}, []);

	const getSession = async () => {
		const url = window.location.pathname;
		const sessionId = url.substring(url.lastIndexOf('/') + 1);
		const res = await getSessionById(sessionId);
		navigate(`/meeting/preview/${sessionId}`, {
			state: {
				sessionId: sessionId,
				userType: userType,
				topic: res?.meta?.zoomSessionId,
				showFeedback: true,
				target: 'preview',
				status: res?.status,
			},
		});
	};

	const init = (topic: any) => {
		let newDev = devConfig;
		newDev.name = user ? `${user.firstName ?? ''} ${user.lastName ?? ''}` : 'Unknown';
		newDev.topic = topic;
		newDev.sessionKey = topic;
		newDev.userIdentity = user ? `${user._id}` : '';
		newDev.role = userType == 'coach' ? 1 : 0;
		if (
			!meetingArgs.sdkKey ||
			!meetingArgs.topic ||
			!meetingArgs.name ||
			!meetingArgs.signature
		) {
			meetingArgs = { ...newDev, ...meetingArgs };
			meetingArgs.enforceGalleryView = true;
		}

		if (meetingArgs.web) {
			if (meetingArgs.topic) {
				try {
					meetingArgs.topic = b64DecodeUnicode(meetingArgs.topic);
				} catch (e) {}
			} else {
				meetingArgs.topic = '';
			}

			if (meetingArgs.name) {
				try {
					meetingArgs.name = b64DecodeUnicode(meetingArgs.name);
				} catch (e) {}
			} else {
				meetingArgs.name = '';
			}

			if (meetingArgs.password) {
				try {
					meetingArgs.password = b64DecodeUnicode(meetingArgs.password);
				} catch (e) {}
			} else {
				meetingArgs.password = '';
			}

			if (meetingArgs.sessionKey) {
				try {
					meetingArgs.sessionKey = b64DecodeUnicode(meetingArgs.sessionKey);
				} catch (e) {}
			} else {
				meetingArgs.sessionKey = '';
			}

			if (meetingArgs.userIdentity) {
				try {
					meetingArgs.userIdentity = b64DecodeUnicode(meetingArgs.userIdentity);
				} catch (e) {}
			} else {
				meetingArgs.userIdentity = '';
			}

			if (meetingArgs.role) {
				meetingArgs.role = parseInt(meetingArgs.role, 10);
			} else {
				meetingArgs.role = 1;
			}
		}

		if (!meetingArgs?.cloud_recording_option) {
			meetingArgs.cloud_recording_option = '0';
		}
		if (!meetingArgs?.cloud_recording_election) {
			meetingArgs.cloud_recording_election = '';
		}

		if (!meetingArgs.signature && meetingArgs.sdkSecret && meetingArgs.topic) {
			meetingArgs.signature = generateVideoToken(
				meetingArgs.sdkKey,
				meetingArgs.sdkSecret,
				meetingArgs.topic,
				meetingArgs.password,
				meetingArgs.sessionKey,
				meetingArgs.userIdentity,
				parseInt(meetingArgs.role, 10),
				meetingArgs.cloud_recording_option,
				meetingArgs.cloud_recording_election
			);

			const urlArgs = {
				topic: meetingArgs.topic,
				name: meetingArgs.name,
				password: meetingArgs.password,
				sessionKey: meetingArgs.sessionKey,
				userIdentity: meetingArgs.userIdentity,
				role: meetingArgs.role || 1,
				cloud_recording_option: meetingArgs.cloud_recording_option,
				cloud_recording_election: meetingArgs.cloud_recording_election,
				web: '1',
			};
		}
	};

	if (state) {
		const { topic } = state;
		init(topic);
	} else {
		getSession();
	}

	const zmClient = ZoomVideo.createClient();
	return (
		<ZoomContext.Provider value={zmClient}>
			<UserProvider>
				<SessionProvider>
					<Routes>
						<Route path="/preview/:id" element={<Preview />} />
						<Route
							path="/video/:id"
							element={<App meetingArgs={meetingArgs as any} />}
						/>
					</Routes>
				</SessionProvider>
			</UserProvider>
		</ZoomContext.Provider>
	);
};
