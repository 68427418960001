import Italic from './italic.svg';
import Underline from './underline.svg';

import CenterAlign from './CenterAlign.svg';
import RightAlign from './RightAlign.svg';
import LeftAlign from './LeftAlign.svg';

import OrderedList from './OrderedList.svg';
import UnOrderedList from './UnOrderedList.svg';

import Link from './Link.svg';
import Unlink from './Unlink.svg';

import Attachment from './Attachment.svg';

export const Icons = {
    Italic,
    Underline,
    CenterAlign,
    RightAlign,
    LeftAlign,
    OrderedList,
    UnOrderedList,
    Attachment,
    Link,
    Unlink
}