import * as React from 'react';

function AttachmentIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
		>
			<path
				d="M2.72311 10.2154L9.04124 3.89594C9.62143 3.31575 10.4083 2.9898 11.2289 2.9898C12.0494 2.9898 12.8363 3.31575 13.4165 3.89594C13.9967 4.47613 14.3226 5.26305 14.3226 6.08357C14.3226 6.90408 13.9967 7.691 13.4165 8.27119L6.12624 15.5628C6.03116 15.6614 5.9174 15.74 5.79161 15.7941C5.66582 15.8482 5.53051 15.8767 5.39358 15.8779C5.25666 15.8792 5.12085 15.8532 4.99409 15.8014C4.86733 15.7496 4.75215 15.6731 4.65528 15.5763C4.55841 15.4795 4.48178 15.3644 4.42987 15.2377C4.37796 15.111 4.3518 14.9752 4.35293 14.8383C4.35405 14.7013 4.38243 14.566 4.43642 14.4402C4.49041 14.3143 4.56892 14.2005 4.66736 14.1053L11.959 6.81232C12.1412 6.61682 12.2403 6.35826 12.2356 6.0911C12.2309 5.82393 12.1227 5.56903 11.9337 5.38009C11.7448 5.19114 11.4899 5.08291 11.2227 5.0782C10.9555 5.07349 10.697 5.17266 10.5015 5.35482L3.20849 12.6464C2.6283 13.2266 2.30235 14.0135 2.30235 14.8341C2.30235 15.6546 2.6283 16.4415 3.20849 17.0217C3.78868 17.6019 4.5756 17.9278 5.39612 17.9278C6.21663 17.9278 7.00354 17.6019 7.58374 17.0217L14.8767 9.73007C15.8439 8.76295 16.3872 7.45127 16.3872 6.08357C16.3872 4.71586 15.8439 3.40418 14.8767 2.43707C13.9096 1.46995 12.5979 0.926636 11.2302 0.926636C9.86254 0.926636 8.55085 1.46995 7.58374 2.43707L1.26424 8.75657C1.1657 8.85165 1.08709 8.9654 1.03298 9.0912C0.978875 9.21699 0.950363 9.35229 0.949109 9.48922C0.947855 9.62615 0.973884 9.76195 1.02568 9.88871C1.07747 10.0155 1.15399 10.1307 1.25077 10.2275C1.34755 10.3244 1.46265 10.401 1.58937 10.4529C1.71608 10.5048 1.85186 10.531 1.98879 10.5299C2.12572 10.5288 2.26105 10.5004 2.38689 10.4464C2.51273 10.3924 2.62656 10.3139 2.72174 10.2154H2.72311Z"
				fill="#4BB7BA"
			/>
		</svg>
	);
}

const MemoAttachmentIcon = React.memo(AttachmentIcon);
export default MemoAttachmentIcon;
