import * as React from 'react';

function FileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M9.917 1.417H4.251a1.417 1.417 0 00-1.417 1.417v11.333a1.416 1.416 0 001.417 1.417h8.5a1.417 1.417 0 001.416-1.417v-8.5l-4.25-4.25z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.916 1.417v4.25h4.25M11.333 9.208H5.666M11.333 12.042H5.666M7.083 6.375H5.666"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoFileIcon = React.memo(FileIcon);
export default MemoFileIcon;
