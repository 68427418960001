import * as React from 'react';

function SupportIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="18"
			viewBox="0 0 22 18"
			fill="none"
		>
			<path
				d="M0.5 5.6691V14.25C0.5 15.9069 1.84315 17.25 3.5 17.25H18.5C20.1569 17.25 21.5 15.9069 21.5 14.25V5.6691L12.5723 11.1631C11.6081 11.7564 10.3919 11.7564 9.42771 11.1631L0.5 5.6691Z"
				fill="white"
			/>
			<path
				d="M21.5 3.90783V3.75C21.5 2.09315 20.1569 0.75 18.5 0.75H3.5C1.84315 0.75 0.5 2.09315 0.5 3.75V3.90783L10.2139 9.88558C10.696 10.1823 11.304 10.1823 11.7861 9.88558L21.5 3.90783Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoSupportIcon = React.memo(SupportIcon);
export default MemoSupportIcon;
