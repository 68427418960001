import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';

import { Box, Button, Checkbox, Text } from 'atoms';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import { FormInput } from 'molecules/FormInput';
import { FormPhoneNumber } from 'molecules/FormPhoneNumber';
import { FormSelect } from 'molecules/FormSelect';

import { capitalizeFirstLetter } from 'helpers/common';

import useCohorts from 'pages/Cohorts/context/cohorts';
import useSchools from 'pages/Schools/context/schools';

export const CompleteProfileValidationSchema = (userType: string) => {
	return userType === 'coach'
		? yup.object().shape({
				firstName: yup
					.string()
					.required('First Name is required')
					.min(2, 'First Name should accept a minimum 2 to 20 characters length')
					.max(20, 'First Name should accept a minimum 2 to 20 characters length')
					.matches(/^[a-zA-Z ]*$/, 'Letters only allowed'),
				lastName: yup
					.string()
					.required('Last Name is required')
					.min(2, 'Last Name should accept a minimum 2 to 20 characters length')
					.max(20, 'Last Name should accept a minimum 2 to 20 characters length')
					.matches(/^[a-zA-Z\s\-]*$/, 'Only letters, space, and hyphen are allowed'),
				hoursCoached: yup
					.number()
					.typeError('Hours should be a number')
					.nullable()
					.test(
						'is-valid-format',
						'Hours should have at most one decimal digit',
						(value: any) => {
							return !value || /^[0-9]+(\.[0-9])?$/.test(value);
						}
					),
				//phoneNo: yup.string().required('Phone required'),
				//school: yup.string().required('School is required'),
				//cohort: yup.string().required('Cohort is required'),
		  })
		: yup.object().shape({
				firstName: yup
					.string()
					.trim()
					.required('First Name is required')
					.min(2, 'First Name should accept a minimum 2 to 20 characters length')
					.max(20, 'First Name should accept a minimum 2 to 20 characters length')
					.matches(/^[a-z\d\-_\s]+$/i, 'Letters only allowed'),
				lastName: yup
					.string()
					.trim()
					.required('Last Name is required')
					.min(2, 'Last Name should accept a minimum 2 to 20 characters length')
					.max(20, 'Last Name should accept a minimum 2 to 20 characters length')
					.matches(/^[a-z\d\-_\s]+$/i, 'Letters only allowed'),
		  });
};

type CompleteProfileFormValuesType = {
	firstName: string;
	lastName?: string;
	hoursCoached?: string;
	phone?: string;
};

export type CompleteProfileFormProps = {
	onSubmit?: (
		values?: CompleteProfileFormValuesType,
		formikHelpers?: FormikHelpers<CompleteProfileFormValuesType>
	) => void | Promise<void>;
	userType: string;
	defaultValue?: any;
};

export const CompleteProfileForm: FC<CompleteProfileFormProps> = ({
	onSubmit,
	userType,
	defaultValue,
}) => {
	const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

	const [initialValue, setInitialValue]: any = useState({
		firstName: '',
		lastName: '',
		hoursCoached: undefined,
		phone: '',
		marketingAgreed: true,
	});

	const {
		state: { list: schools },
		actions: { fetchAllSchools },
	} = useSchools();

	const {
		state: { list: cohorts },
		actions: { fetchAllCohorts },
	} = useCohorts();

	useEffect(() => {
		if (userType === 'coach') fetchAllSchools({ all: true, statuses: [1] });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (defaultValue) {
			setInitialValue({
				...initialValue,
				firstName: defaultValue?.firstName || '',
				lastName: defaultValue?.lastName || '',
			});
		}
	}, [defaultValue, defaultValue?.firstName, defaultValue?.lastName]);

	return (
		<Formik
			initialValues={initialValue}
			onSubmit={(formValues, actions) => {
				onSubmitHandler(formValues);
				setTimeout(() => {
					actions.setSubmitting(false);
				}, 1000);
			}}
			validationSchema={CompleteProfileValidationSchema(userType)}
			enableReinitialize
		>
			{({ values, setFieldValue, isSubmitting }) => (
				<Form>
					<Box mt="3rem">
						<FormInput
							label="First Name"
							name="firstName"
							onChange={(e: any) => {
								setFieldValue('firstName', capitalizeFirstLetter(e?.target?.value));
							}}
						/>
						<FormInput
							label="Last Name"
							name="lastName"
							onChange={(e: any) => {
								setFieldValue('lastName', capitalizeFirstLetter(e?.target?.value));
							}}
						/>

						{userType === 'coach' && (
							<Box mb="1rem">
								<FormInput
									label="Hours coached so far"
									name="hoursCoached"
									showOptional={true}
								/>

								<FormPhoneNumber
									label={`Phone number`}
									name="phone"
									value={values?.phone}
									showOptional={true}
									setCountryCode={(value: any) => {
										setFieldValue('countryCode', value);
									}}
									onChangeCustom={(e: any) => {
										setFieldValue('phone', e);
									}}
								/>

								<FormSelect
									name="school"
									placeholder="Select an option"
									label="Training Provider"
									showSearch={true}
									showOptional={true}
									options={
										schools?.schools?.map((x: any, key: any) => ({
											label: x?.name,
											value: x?._id,
										})) || []
									}
									onChangeCustom={(x: any) => {
										// School based cohorts listing
										let params = { all: true };
										//params['filter[school]'] = x;
										params['schools'] = x;
										fetchAllCohorts(params);
										setFieldValue('cohort', undefined);
									}}
									allowClear
									onClear={() => {
										setFieldValue('school', undefined);
										setFieldValue('cohort', undefined);
									}}
								/>

								<FormSelect
									name="cohort"
									placeholder="Select Cohort"
									label="Cohort"
									showSearch={true}
									showOptional={true}
									options={
										cohorts?.cohorts?.map((x: any, key: any) => ({
											label: x?.name,
											value: x?._id,
										})) || []
									}
									allowClear
									onClear={() => {
										setFieldValue('cohort', undefined);
									}}
									mb="2rem"
								/>

								<Checkbox
									checked={values?.marketingAgreed}
									activeColor="secondary.50"
									onChange={(e: any) => {
										setFieldValue('marketingAgreed', !values?.marketingAgreed);
									}}
								>
									<Text fontFamily="regular" fontSize={'1.6rem'}>
										Please keep me updated with news and special offers
									</Text>
								</Checkbox>
							</Box>
						)}

						<Button
							fontFamily="semibold"
							fontSize="16px"
							my="1rem"
							type="submit"
							px="72px"
							py="8px"
							width="100%"
							loading={loading}
							disabled={isSubmitting}
						>
							Continue
						</Button>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
