import * as React from 'react';

function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM9 4.5C9 5.05228 8.55228 5.5 8 5.5C7.44771 5.5 7 5.05228 7 4.5C7 3.94772 7.44771 3.5 8 3.5C8.55228 3.5 9 3.94772 9 4.5ZM7 7.5C6.58579 7.5 6.25 7.83579 6.25 8.25C6.25 8.66421 6.58579 9 7 9H7.25338C7.41332 9 7.53213 9.1481 7.49743 9.30423L7.03829 11.3704C6.79542 12.4633 7.62706 13.5 8.74662 13.5H9C9.41421 13.5 9.75 13.1642 9.75 12.75C9.75 12.3358 9.41421 12 9 12H8.74662C8.58668 12 8.46787 11.8519 8.50257 11.6958L8.96171 9.62963C9.20458 8.53673 8.37294 7.5 7.25338 7.5H7Z"
				fill={props?.fill || '#4BB7BA'}
			/>
		</svg>
	);
}

export const MemoInfoIcon = React.memo(InfoIcon);
export default MemoInfoIcon;
