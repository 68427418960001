import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /* Set 1rem to the browser default (16px) */
  font-size: 62.5%; 
}

#root {
  min-width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}
`;

export default GlobalStyle;
