import { FC } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
//import type { SelectProps } from 'antd';

const StyledSelect = styled(Select)`
	height: 3.2rem;
	width: 105px;
	font-size: 14px;
	font-family: 'Inter-Regular' !important;
	.ant-select-selector {
		border: 1px solid #e5e7eb !important;
		border-radius: 16px !important;
		height: 3.2rem !important;
	}
	.ant-select-selection-search-input {
		height: 3.2rem;
	}
	.ant-select-selection-item {
		line-height: 3.2rem !important;
		font-family: 'Inter-Regular' !important;
	}
	.ant-select-focused .ant-select-selector,
	.ant-select-selector:focus,
	.ant-select-selector:active,
	.ant-select-open .ant-select-selector {
		border-color: none !important;
		box-shadow: none !important;
	}
`;

//type optionList = { options: { value: number; label: string }[] };
//const options: SelectProps['options'] = [];
//const optionList = Array();

interface SelectableDropdownProps {
	optionsList?: any;
	defaultValue?: any;
}

export const SelectableDropdown: FC<SelectableDropdownProps> = ({ optionsList, defaultValue }) => {
	return (
		<StyledSelect
			labelInValue
			defaultValue={defaultValue}
			style={{ width: 85 }}
			onChange={() => {}}
			options={optionsList}
			disabled={true}
		></StyledSelect>
	);
};
