import * as React from 'react';
function FaqIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10ZM11.6277 6.08328C10.7389 5.30557 9.26159 5.30557 8.37278 6.08328C8.06106 6.35604 7.58723 6.32445 7.31447 6.01272C7.04171 5.701 7.0733 5.22717 7.38503 4.95441C8.83937 3.68186 11.1611 3.68186 12.6154 4.95441C14.1285 6.27835 14.1285 8.47165 12.6154 9.79559C12.3588 10.0202 12.0761 10.2041 11.778 10.3484C11.1018 10.6756 10.7502 11.1222 10.7502 11.5V12.25C10.7502 12.6642 10.4144 13 10.0002 13C9.58601 13 9.25023 12.6642 9.25023 12.25V11.5C9.25023 10.221 10.3095 9.39264 11.1246 8.99817C11.3073 8.90977 11.4765 8.79899 11.6277 8.66672C12.4577 7.9404 12.4577 6.80959 11.6277 6.08328ZM10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoFaqIcon = React.memo(FaqIcon);
export default MemoFaqIcon;
