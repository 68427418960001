import { TASK_STATUS } from 'typings/enums/users';

export const base64 = {
	decode: (s: string) => window.atob(s),
	encode: (b: string) => window.btoa(b),
};

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => void) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index);
	}
}

export const toJSON = (jsonString: string): object | null => {
	try {
		return JSON.parse(jsonString);
	} catch (e) {
		console.log(e);
		return null;
	}
};

export const getExtension = (fileName: string) => fileName.split('.').pop();

export const randomString = (length: number) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters?.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const returnTODOStatus = (
	currentPortal: string,
	currentTodo: any,
	isCompleted: boolean
): number => {
	if (isCompleted) {
		return 3;
	} else if (currentTodo.status === 2) {
		return 2;
	} else if (currentPortal === 'coach') {
		if (currentTodo.status === 3 && !isCompleted) {
			return 1;
		}
		return currentTodo.status;
	}
	return 1;
};

// export const returnTaskStatus = (status: string) => {
// 	if (status === 'inProgress') return 'Inprogress';
// 	if (status === 'completed') return 'Completed';
// 	if (status === 'todo') return 'Todo';
// };

export const returnTaskStatus = (
	currentPortal: string,
	currentTask: any,
	isCompleted: boolean
): number => {
	if (isCompleted) {
		return TASK_STATUS.COMPLETED;
	} else if (currentTask.status === TASK_STATUS.SHARED) {
		return TASK_STATUS.SHARED;
	} else if (currentPortal === 'coach') {
		if (currentTask.status === TASK_STATUS.COMPLETED && !isCompleted) {
			return TASK_STATUS.IN_PROGRESS;
		}
		return currentTask.status;
	}
	return TASK_STATUS.IN_PROGRESS;
};

export const returnEditorVisible = (
	currentPortal: string,
	currentTask: any,
	userId: string
): boolean => {
	if (currentPortal === 'coachee') {
		return true;
	}
	if (currentTask.assignee?._id === userId) {
		return true;
	}
	if (currentPortal === 'coach' && currentTask.status !== TASK_STATUS.IN_PROGRESS) {
		return true;
	}
	return false;
};

export const buildUsersList = (users: any): any => {
	return users?.map((x: any) => ({
		value: `${x.alias ? x.alias : x.firstName + ' ' + x.lastName}`,
		key: `${x._id}`,
		label: `${x.alias ? x.alias : x.firstName + ' ' + x.lastName}`,
		data: x,
	}));
};

export const Linkify = (inputText: string) => {
	if (inputText.match(/<img/)) {
		return inputText;
	}
	//URLs starting with http://, https://, or ftp://
	const replacePattern1 =
		/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line
	let replacedText = inputText.replace(
		replacePattern1,
		'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
	);

	//URLs starting with www. (without // before it, or it'd re-link the ones done above)
	const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim; // eslint-disable-line
	replacedText = replacedText.replace(
		replacePattern2,
		'$1<a href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>'
	);

	//Change email addresses to mailto:: links
	const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim; // eslint-disable-line
	replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

	return replacedText;
};

export const ALL_COMPENTENCIES = [
	{
		label: 'EMCC - Understanding self',
		value: 'EMCC - Understanding self',
	},
	{
		label: 'EMCC - Commitment to self-development',
		value: 'EMCC - Commitment to self-development',
	},
	{
		label: 'EMCC - Managing the contract',
		value: 'EMCC - Managing the contract',
	},
	{
		label: 'EMCC - Building the relationship',
		value: 'EMCC - Building the relationship',
	},
	{
		label: 'EMCC - Enabling insight and learning',
		value: 'EMCC - Enabling insight and learning',
	},
	{
		label: 'EMCC - Outcome and action orientation',
		value: 'EMCC - Outcome and action orientation',
	},
	{
		label: 'AC - Establishing the coaching agreement and outcomes',
		value: 'AC - Establishing the coaching agreement and outcomes',
	},
	{
		label: 'AC - Managing self and maintaining coaching presence',
		value: 'AC - Managing self and maintaining coaching presence',
	},
	{
		label: 'AC - Communicating effectively',
		value: 'AC - Communicating effectively',
	},
	{
		label: 'AC - Raising awareness and insight',
		value: 'AC - Raising awareness and insight',
	},
	{
		label: 'AC - Designing strategies and actions',
		value: 'AC - Designing strategies and actions',
	},
	{
		label: 'AC - Maintaining forward momentum and evaluation',
		value: 'AC - Maintaining forward momentum and evaluation',
	},
	{
		label: 'AC - Undertaking continuous coach development',
		value: 'AC - Undertaking continuous coach development',
	},
	{
		label: 'AC- Working within the organisational context  * For Executive Coaches',
		value: 'AC- Working within the organisational context  * For Executive Coaches',
	},
	{
		label: 'AC - Comprehending leadership issues * For Executive Coaches',
		value: 'AC - Comprehending leadership issues * For Executive Coaches',
	},
	{
		label: 'AC- Working in partnership with the organisation * For Executive Coaches',
		value: 'AC- Working in partnership with the organisation * For Executive Coaches',
	},
	{
		label: 'ICF - Foundation Competency- Demonstrates Ethical Practice',
		value: 'ICF - Foundation Competency- Demonstrates Ethical Practice',
	},
	{
		label: 'ICF - Foundation Competency- Embodies a Coaching Mindset',
		value: 'ICF - Foundation Competency- Embodies a Coaching Mindset',
	},
	{
		label: 'ICF - Co-Creating the Relationship Competency- Establishes and Maintains Agreements',
		value: 'ICF - Co-Creating the Relationship Competency- Establishes and Maintains Agreements',
	},
	{
		label: 'ICF - Co-Creating the Relationship Competency- Cultivates Trust and Safety',
		value: 'ICF - Co-Creating the Relationship Competency- Cultivates Trust and Safety',
	},
	{
		label: 'ICF - Co-Creating the Relationship Competency- Maintains Presence',
		value: 'ICF - Co-Creating the Relationship Competency- Maintains Presence',
	},
	{
		label: 'ICF -Communicating Effectively Competency- Listens Actively',
		value: 'ICF -Communicating Effectively Competency- Listens Actively',
	},
	{
		label: 'ICF - Communicating Effectively  Competency- Evokes Awareness',
		value: 'ICF - Communicating Effectively  Competency- Evokes Awareness',
	},
	{
		label: 'ICF - Cultivating Learning and Growth Competency- Facilitates Client Growth',
		value: 'ICF - Cultivating Learning and Growth Competency- Facilitates Client Growth',
	},
];
