import * as React from 'react';
import viewImg from 'assets/icons/png/view.png';

function EyeOpenIcon(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'img') return <img id="view" src={viewImg} alt="view" />;
	return (
		<svg
			aria-hidden="true"
			fill={props?.fill ? props?.fill : 'currentColor'}
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path d="M12 7c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7zm0 7.2c-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7s2.7 1.21 2.7 2.7c0 1.49-1.21 2.7-2.7 2.7z" />
			<path d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 13a9.77 9.77 0 01-8.82-5.5C4.83 8.13 8.21 6 12 6s7.17 2.13 8.82 5.5A9.77 9.77 0 0112 17z" />
		</svg>
	);
}

const MemoEyeOpenIcon = React.memo(EyeOpenIcon);
export default MemoEyeOpenIcon;
