import * as React from 'react';

function Calendar(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.25 0C4.66421 0 5 0.335786 5 0.75V2H12V0.75C12 0.335786 12.3358 0 12.75 0C13.1642 0 13.5 0.335786 13.5 0.75V2H13.75C15.2688 2 16.5 3.23122 16.5 4.75V13.25C16.5 14.7688 15.2688 16 13.75 16H3.25C1.73122 16 0.5 14.7688 0.5 13.25V4.75C0.5 3.23122 1.73122 2 3.25 2H3.5V0.75C3.5 0.335786 3.83579 0 4.25 0ZM3.25 5.5C2.55964 5.5 2 6.05964 2 6.75V13.25C2 13.9404 2.55964 14.5 3.25 14.5H13.75C14.4404 14.5 15 13.9404 15 13.25V6.75C15 6.05964 14.4404 5.5 13.75 5.5H3.25Z"
				fill="white"
			/>
		</svg>
	);
}

const MemoCalendar = React.memo(Calendar);
export default MemoCalendar;
