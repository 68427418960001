const breakpoints: any = ['20em', '45em', '64em', '80em', '90em', '100em'];
//const breakpoints: any = ['480', '720', '1024', '1280', '1440', '1600'];

const [xs, sm, md, lg, xl, xxl] = breakpoints;

breakpoints.xs = xs;
breakpoints.sm = sm;
breakpoints.md = md;
breakpoints.lg = lg;
breakpoints.xl = xl;
breakpoints.xxl = xxl;

const space = [
	0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 8, 10, 12, 16, 20,
].map((s) => `${s}rem`);

// #endregion
// #region typography
export const fontSets = [
	{
		name: 'heading1',
		alias: 'h1',
		fontSize: '5.052rem', // 50.52px
		lineHeight: '1.1',
		fontWeight: 'bold',
	},
	{
		name: 'heading2',
		alias: 'h2',
		fontSize: '3.79rem', // 37.9px
		lineHeight: '1.1',
		fontWeight: 'bold',
	},
	{
		name: 'heading3',
		alias: 'h3',
		fontSize: '2.843rem', // 28.4px
		lineHeight: '1.1',
		fontWeight: 'bold',
	},
	{
		name: 'heading4',
		alias: 'h4',
		fontSize: '2.262rem', // 22.62px
		lineHeight: '1.1',
		fontWeight: 'bold',
	},
	{
		name: 'heading5',
		alias: 'h5',
		fontSize: '1.6rem', // 16px
		lineHeight: '1.459',
		fontWeight: 'bold',
	},
	{
		name: 'body1',
		alias: 'p1',
		fontSize: '1.6rem', // 16px
		lineHeight: '1.459',
		fontWeight: 'regular',
	},
	{
		name: 'body2',
		alias: 'p2',
		fontSize: '1.4rem', // 14px
		lineHeight: '1.313', // 21px
		fontWeight: 'regular',
	},
	{
		name: 'body3',
		alias: 'p3',
		fontSize: '1.2rem', // 12px
		lineHeight: '1.125', // 18px
		fontWeight: 'regular',
	},
	{
		name: 'button',
		alias: 'btn',
		fontSize: '1.2rem',
		lineHeight: '1.125',
		fontWeight: 'semibold',
	},
];

const fontMaps = fontSets.reduce(
	(fontMap, { name, alias = name, fontSize, lineHeight, fontWeight }, index) => {
		let fm = fontMap;
		fm.fontSizes[index] = fontSize;
		fm.fontSizes[name] = fontSize;
		fm.fontSizes[alias] = fontSize;
		fm.fontWeights[index] = fontWeight;
		fm.fontWeights[name] = fontWeight;
		fm.fontWeights[alias] = fontWeight;
		fm.lineHeights[name] = lineHeight;
		fm.lineHeights[alias] = lineHeight;
		return fm;
	},
	{
		fontFamily: {
			light: 'Inter-Light', //fontWeight:100
			regular: 'Inter-Regular', //fontWeight:400
			medium: 'Inter-Medium', //fontWeight:500
			semibold: 'Inter-SemiBold', //fontWeight:600
			bold: 'Inter-Bold', //fontWeight:700
			black: 'Inter-Black', //fontWeight:900
		},
		fontSizes: {},
		fontWeights: {
			light: 100,
			regular: 400,
			medium: 500,
			semibold: 600,
			bold: 700,
			block: 900,
		},
		lineHeights: {},
	}
);
// #endregion

// #region colors
const colorSets = [
	{
		name: 'primary',
		alias: 'primary',
		colors: [
			{ name: 50, hex: '#111827' },
			{ name: 100, hex: '#1F2937' },
			{ name: 150, hex: '#374151' },
			{ name: 200, hex: '#4B5563' },
		],
	},
	{
		name: 'secondary',
		alias: 'secondary',
		colors: [
			{ name: 50, hex: '#4BB7BA' },
			{ name: 100, hex: '#265C7E' },
		],
	},
	{
		name: 'grey',
		alias: 'grey',
		colors: [
			{ name: 50, hex: '#6B7280' },
			{ name: 100, hex: '#9CA3AF' },
			{ name: 150, hex: '#D1D5DB' },
			{ name: 200, hex: '#E5E7EB' },
			{ name: 250, hex: '#E8EDF2' },
		],
	},
	{
		name: 'teal',
		alias: 'teal',
		colors: [
			{ name: 50, hex: '#216E7F' },
			{ name: 100, hex: '#14424C' },
			{ name: 400, hex: '#0D2C33' },
			{ name: 600, hex: '#071619' },
		],
	},
	{
		name: 'red',
		alias: 'danger',
		colors: [
			{ name: 100, hex: '#FFF1F1' },
			{ name: 200, hex: '#DE7373' },
			{ name: 300, hex: '#DC2626' },
		],
	},
	{
		name: 'orange',
		alias: 'warning',
		colors: [{ name: 900, hex: '#D97706' }],
	},
	{
		name: 'green',
		alias: 'success',
		colors: [{ name: 900, hex: '#059669' }],
	},
	{
		name: 'customwhite',
		alias: 'white',
		colors: [
			{ name: 0, hex: '#FFFFFF' },
			{ name: 25, hex: '#F9FAFB' },
			{ name: 30, hex: '#F3F4F6' },
			{ name: 40, hex: '#FAFAFA' },
		],
	},
	{
		name: 'customblack',
		alias: 'black',
		colors: [
			{ name: 0, hex: '#000000' },
			{ name: 100, hex: '#3d4043' },
			{ name: 400, hex: '#8A8A8A' },
			{ name: 500, hex: '#424242' },
			{ name: 600, hex: '#333333' },
			{ name: 700, hex: '#292929' },
			{ name: 800, hex: '#1F1F1F' },
			{ name: 900, hex: '#0F0F0F' },
		],
	},
];
const colors = colorSets.reduce((colorMap, { name, alias = name, colors: colorSet }) => {
	const color = {};
	const cm = colorMap;
	for (let colorIndex = 0; colorIndex < colorSet.length; colorIndex++) {
		const { name: colorName, hex } = colorSet[colorIndex];
		color[colorIndex] = hex;
		color[colorName] = hex;
	}
	cm[name] = color;
	cm[alias] = color;
	return cm;
}, {});

// #endregion
const radii = {
	// ...defaultTheme.radii,
	small: '0.125rem',
	normal: '0.1875rem',
	large: '0.375rem',
	full: '10rem',
	square: 0,
};
const zIndices = {
	// ...defaultTheme.zIndices,
	modal: 2000,
	tooltip: 5000,
	toast: 7000,
};

const shadows = [
	{ name: 'none', shadow: undefined },
	{ name: 'sm', shadow: '0 .075rem .15rem rgba(0,0,0,.15)' },
	{ name: 'xl', shadow: '0 0 1rem rgba(0,0,0,.15)' },
].reduce((shadowSet, { name, shadow }, index) => {
	const s = shadowSet;
	s[name] = shadow;
	s[index] = shadow;
	return s;
});

export const theme = {
	// ...defaultTheme,
	breakpoints,
	radii,
	colors,
	space,
	zIndices,
	shadows,
	...fontMaps,
};
