import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast as message } from 'react-toastify';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from 'routes';
import useAuth from './context/Authentication';
import { useGoogleAnalytics } from 'components/useGoogleAnalytics';
import useIdleTimeout from 'hooks/useIdleTimeout';

export type AuthGateProps = {};

export const AuthGate: FC<AuthGateProps> = () => {
	const {
		state: { isLoggedIn, user },
		actions: { logout },
	} = useAuth();

	const { trackPageView, trackEvent } = useGoogleAnalytics();
	const location = useLocation();

	// Track page view on route change
	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			trackPageView(location.pathname);
		}
	}, [location.pathname]);

	// Track page view on route change
	useEffect(() => {
		let redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
		localStorage.setItem('redirectUrl', redirectUrl);
	}, [location.search]);

	const handleIdleTimeout = () => {
		message.error('Logon has timed out due to inactivity');
		// Handle logout or any other actions on timeout
		logout();
	};

	const { resetTimeout } = useIdleTimeout(handleIdleTimeout, {
		timeout: 120 * 60 * 1000, // 60 minutes
	});

	useEffect(() => {
		if (user) resetTimeout(); // Reset idle timeout on any user activity
	}, [resetTimeout]);

	if (isLoggedIn || !!localStorage.getItem('token')) {
		return <AuthenticatedRoutes />;
	}

	return <UnauthenticatedRoutes />;
};
