import * as React from 'react';

function ArrowUpTray(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="27"
			viewBox="0 0 26 27"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.2929 0.792893C12.6834 0.402369 13.3166 0.402369 13.7071 0.792893L19.7071 6.79289C20.0976 7.18342 20.0976 7.81658 19.7071 8.20711C19.3166 8.59763 18.6834 8.59763 18.2929 8.20711L14 3.91421L14 19.5C14 20.0523 13.5523 20.5 13 20.5C12.4477 20.5 12 20.0523 12 19.5L12 3.91421L7.70711 8.20711C7.31658 8.59763 6.68342 8.59763 6.29289 8.20711C5.90237 7.81658 5.90237 7.18342 6.29289 6.79289L12.2929 0.792893ZM1 18.5C1.55228 18.5 2 18.9477 2 19.5V22.5C2 23.6046 2.89543 24.5 4 24.5H22C23.1046 24.5 24 23.6046 24 22.5V19.5C24 18.9477 24.4477 18.5 25 18.5C25.5523 18.5 26 18.9477 26 19.5V22.5C26 24.7091 24.2091 26.5 22 26.5H4C1.79086 26.5 0 24.7091 0 22.5V19.5C0 18.9477 0.447715 18.5 1 18.5Z"
				fill="#374151"
			/>
		</svg>
	);
}

const MemoArrowUpTray = React.memo(ArrowUpTray);
export default MemoArrowUpTray;
