import { FC } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';

import { Text } from 'atoms';
import { TabsList } from './TabsList';

export type CustomTabsProps = {
	user?: any;
};

export const CustomTabs: FC<CustomTabsProps> = ({ user }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	let OptionList: any = TabsList(location?.pathname, params, user);

	return (
		<>
			{OptionList?.length > 0 ? (
				<div className="ant-tabs ant-tabs-top custom-nav-tabs">
					<div className="ant-tabs-nav">
						<div className="ant-tabs-nav-wrap">
							<div className="ant-tabs-nav-list">
								{OptionList?.map((x: any, key: any) => {
									return (
										<div
											key={`${key}`}
											className={`ant-tabs-tab ${
												location?.pathname === x?.url
											} ${x?.disabled ? 'ant-tabs-tab-disabled' : ''}`}
											onClick={() => {
												if (!x?.disabled) navigate(x?.url);
											}}
										>
											<div className="ant-tabs-tab-btn">
												<Text
													fontFamily="Inter-SemiBold"
													color={
														location?.pathname === x?.url
															? '#4BB7BA'
															: ''
													}
												>
													{x?.label}
												</Text>
											</div>
											{location?.pathname === x?.url && (
												<div
													className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
													style={{ left: '0px', width: '100%' }}
												></div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};
