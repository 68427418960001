import * as React from "react";
function SignOffIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <path
        d="M18.3599 6.64014C19.6183 7.89893 20.4752 9.50258 20.8222 11.2483C21.1692 12.9941 20.9908 14.8035 20.3096 16.4479C19.6283 18.0923 18.4747 19.4977 16.9948 20.4865C15.5148 21.4753 13.7748 22.0031 11.9949 22.0031C10.215 22.0031 8.47509 21.4753 6.99511 20.4865C5.51513 19.4977 4.36158 18.0923 3.6803 16.4479C2.99903 14.8035 2.82063 12.9941 3.16766 11.2483C3.51468 9.50258 4.37156 7.89893 5.62994 6.64014"
        stroke="#F1F5F6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2V12"
        stroke="#F1F5F6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const MemoSignOffIcon = React.memo(SignOffIcon);
export default MemoSignOffIcon;
