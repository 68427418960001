import * as React from 'react';

function Diary(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="29"
			height="29"
			viewBox="0 0 29 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				stroke={props.fill || '#0C0442'}
				fill="none"
				d="M2.41699 3.625H9.66699C10.9489 3.625 12.1782 4.13422 13.0847 5.04065C13.9911 5.94708 14.5003 7.17645 14.5003 8.45833V25.375C14.5003 24.4136 14.1184 23.4916 13.4386 22.8117C12.7588 22.1319 11.8367 21.75 10.8753 21.75H2.41699V3.625Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				stroke={props.fill || '#0C0442'}
				fill="none"
				d="M26.5833 3.625H19.3333C18.0515 3.625 16.8221 4.13422 15.9157 5.04065C15.0092 5.94708 14.5 7.17645 14.5 8.45833V25.375C14.5 24.4136 14.8819 23.4916 15.5617 22.8117C16.2416 22.1319 17.1636 21.75 18.125 21.75H26.5833V3.625Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const MemoDiary = React.memo(Diary);
export default MemoDiary;
