import * as React from 'react';

function HeadedArrow(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'up') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="8"
				height="10"
				viewBox="0 0 8 10"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4 10C3.58579 10 3.25 9.66421 3.25 9.25L3.25 2.61208L1.29063 4.76983C1.00353 5.06841 0.528748 5.07772 0.230169 4.79062C-0.0684092 4.50353 -0.077719 4.02875 0.209376 3.73017L3.45938 0.230168C3.60078 0.0831099 3.79599 6.48486e-09 4 0C4.20401 -6.48486e-09 4.39922 0.0831098 4.54063 0.230168L7.79063 3.73017C8.07772 4.02875 8.06841 4.50353 7.76983 4.79062C7.47125 5.07772 6.99647 5.06841 6.70938 4.76983L4.75 2.61208V9.25C4.75 9.66421 4.41421 10 4 10Z"
					fill={props?.fill || "#4BB7BA"}
				/>
			</svg>
		);
	}
	if (props?.type === 'down') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="8"
				height="10"
				viewBox="0 0 8 10"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4 0C4.41421 0 4.75 0.335786 4.75 0.75V7.38792L6.70938 5.23017C6.99647 4.93159 7.47125 4.92228 7.76983 5.20938C8.06841 5.49647 8.07772 5.97125 7.79063 6.26983L4.54063 9.76983C4.39922 9.91689 4.20401 10 4 10C3.79599 10 3.60078 9.91689 3.45938 9.76983L0.209376 6.26983C-0.077719 5.97125 -0.0684094 5.49647 0.230169 5.20938C0.528748 4.92228 1.00353 4.93159 1.29063 5.23017L3.25 7.38792V0.75C3.25 0.335786 3.58579 0 4 0Z"
					fill={props?.fill || "#4BB7BA"}
				/>
			</svg>
		);
	}
	if (props?.type === 'right') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="10"
				viewBox="0 0 12 10"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.40039 4.9998C0.40039 4.66843 0.66902 4.3998 1.00039 4.3998L9.51073 4.3998L6.18453 1.2323C5.94566 1.00263 5.93821 0.622802 6.16789 0.383939C6.39757 0.145076 6.77739 0.137627 7.01626 0.367304L11.4163 4.5673C11.5339 4.68043 11.6004 4.83659 11.6004 4.9998C11.6004 5.16301 11.5339 5.31918 11.4163 5.4323L7.01626 9.6323C6.77739 9.86198 6.39757 9.85453 6.16789 9.61567C5.93822 9.37681 5.94566 8.99698 6.18453 8.7673L9.51073 5.5998L1.00039 5.5998C0.66902 5.5998 0.40039 5.33117 0.40039 4.9998Z"
					fill={props?.fill || "#4B5563"}
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="12"
			viewBox="0 0 14 12"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 6C14 6.41421 13.6642 6.75 13.25 6.75L2.61208 6.75L6.76983 10.7094C7.06841 10.9965 7.07772 11.4713 6.79062 11.7698C6.50353 12.0684 6.02875 12.0777 5.73017 11.7906L0.230168 6.54062C0.0831096 6.39922 -2.71186e-07 6.20401 -2.62268e-07 6C-2.53351e-07 5.79599 0.0831096 5.60078 0.230168 5.45938L5.73017 0.209376C6.02875 -0.0777193 6.50353 -0.0684098 6.79062 0.230169C7.07772 0.528748 7.06841 1.00353 6.76983 1.29063L2.61208 5.25L13.25 5.25C13.6642 5.25 14 5.58579 14 6Z"
				fill={props?.fill || "#4BB7BA"}
			/>
		</svg>
	);
}

const MemoHeadedArrow = React.memo(HeadedArrow);
export default MemoHeadedArrow;
