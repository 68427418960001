import { FC, Suspense } from 'react';
import { Box, Flex, Text, Loader, Image } from 'atoms';

import { UserType } from 'typings/enums/users';

export type AuthLayoutProps = {
	children?: React.ReactNode;
	userType?: UserType;
};

export const AuthLayout: FC<AuthLayoutProps> = ({
	children,
	userType,
	...props
}: AuthLayoutProps) => {
	return (
		<Box
			height={{
				xs: location?.pathname?.includes('/code-verify') ? 'unset' : '100vh',
				md: '100vh',
			}}
			overflowY="scroll"
			display="flex"
			flexDirection={{ xs: 'column-reverse', md: 'row' }}
		>
			<Box
				width={{ xs: '100%', md: '50%' }}
				py="5rem"
				backgroundColor="white.0"
				boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
				overflowY={'scroll'}
				className={'scroll-hide'}
			>
				<Box height="100%">
					<Box
						mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
						minWidth={{ sm: '32rem', lg: '44rem' }}
						maxWidth="90%"
					>
						<Suspense fallback={<Loader />}>{children}</Suspense>
					</Box>
				</Box>
			</Box>
			<Box
				backgroundColor="#F1F9FA"
				minHeight={{ xs: 'unset', md: '100vh' }}
				p={{ xs: '3rem', md: '2rem' }}
				display="flex"
				flexGrow="1"
				justifyContent="center"
				alignItems="center"
				width={{ xs: '100%', md: '50%' }}
			>
				{location?.pathname?.includes('/complete-profile') && (
					<Image
						src={require('assets/images/login/s4.png')}
						alt="A man seeing his goal"
					/>
				)}
				{location?.pathname?.includes('/code-verify') && (
					<Box>
						<Flex justifyContent="center">
							<Image
								src={require('assets/images/login/s5.png')}
								alt="A man seeing his goal"
								width="320px"
							/>
						</Flex>
						<Box px={{ xs: '0', sm: '3rem' }}>
							<Text fontSize={'1.6rem'} mt="2rem" mb="1rem">
								My Coaching Place provides a unique code to partner coach training
								providers. When used, this code provides additional free session
								credits for use on the platform. For certain partner training
								providers, the code also provides access to the client matching side
								of the My Coaching Place platform.{' '}
							</Text>
							<Text fontSize={'1.6rem'} mb="1rem">
								The code can be added to the platform either now or when you are
								completing your profile in the next screens.
							</Text>
							<Text fontSize={'1.6rem'} mb="2rem">
								If you cannot locate a code from your training provider, please
								contact the My Coaching Place support team{' '}
								<a href="mailto:support@mycoachingplace.com">
									<Text as="span" color="secondary.50" fontFamily="semibold">
										here
									</Text>
								</a>{' '}
								and we will do our best to help.
							</Text>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};
