import * as React from 'react';
import deleteImg from 'assets/icons/png/delete.png';

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'img') return <img id="delete" src={deleteImg} alt="delete" />;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="19"
			viewBox="0 0 14 19"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.75 0.5C4.23122 0.5 3 1.73122 3 3.25V3.6927C2.20472 3.76972 1.41602 3.86947 0.634578 3.99129C0.225308 4.0551 -0.0547462 4.4386 0.00905886 4.84787C0.0728639 5.25714 0.456367 5.5372 0.865637 5.47339L1.01355 5.45062L1.85504 15.9693C1.96938 17.3985 3.16254 18.5 4.59629 18.5H9.40351C10.8372 18.5 12.0304 17.3985 12.1447 15.9693L12.9862 5.45055L13.1346 5.47339C13.5438 5.5372 13.9274 5.25714 13.9912 4.84787C14.055 4.4386 13.7749 4.0551 13.3656 3.99129C12.5841 3.86946 11.7954 3.7697 11 3.69268V3.25C11 1.73122 9.76878 0.5 8.25 0.5H5.75ZM7.00011 3.5C7.83946 3.5 8.673 3.52523 9.5 3.57499V3.25C9.5 2.55964 8.94036 2 8.25 2H5.75C5.05964 2 4.5 2.55964 4.5 3.25V3.575C5.32707 3.52524 6.16068 3.5 7.00011 3.5ZM5.57948 7.22002C5.56292 6.80614 5.21398 6.48404 4.8001 6.5006C4.38622 6.51716 4.06412 6.86609 4.08068 7.27998L4.38069 14.78C4.39725 15.1939 4.74619 15.516 5.16007 15.4994C5.57395 15.4828 5.89605 15.1339 5.87949 14.72L5.57948 7.22002ZM9.91949 7.27998C9.93605 6.86609 9.61395 6.51715 9.20007 6.5006C8.78619 6.48404 8.43725 6.80614 8.42069 7.22002L8.12069 14.72C8.10414 15.1339 8.42623 15.4828 8.84012 15.4994C9.254 15.516 9.60294 15.1939 9.61949 14.78L9.91949 7.27998Z"
				fill={props?.fill || 'white'}
			/>
		</svg>
	);
}

const MemoDeleteIcon = React.memo(DeleteIcon);

export default MemoDeleteIcon;
