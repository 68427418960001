import { Calendar as AntCalendar, CalendarProps as AntCalendarProps, Row } from 'antd';
import 'antd/dist/antd.min.css';
import { Box, Button, Flex, Icon, Text } from 'atoms';
import moment from 'moment';
import styled from 'styled-components';

export const StyledCalendar = styled(AntCalendar)``;

export interface CalendarProps extends AntCalendarProps<moment.Moment> {
	onChangeDate?: (date: any, dateStrings: any) => void;
	onChangeMonth?: (date: any) => void;
	onSelect?: (date: any) => void;
	onChange?: (date: any) => void;
	style?: object;
	value?: moment.Moment;
}

export const Calendar = ({
	onChangeDate,
	onChangeMonth,
	onSelect,
	onChange,
	style,
	value = moment(),
	...props
}: CalendarProps) => {
	return (
		<Box>
			<StyledCalendar
				fullscreen={false}
				style={style}
				onSelect={(date: any) => onSelect && onSelect(date)}
				onChange={(date: any) => onChange && onChange(date)}
				value={value}
				headerRender={({ value, onChange }) => {
					return (
						<Row gutter={8} justify="space-between" align="middle">
							<Flex
								width="100%"
								alignItems="center"
								my="2rem"
								justifyContent="space-between"
							>
								<Text
									textTransform="uppercase"
									fontWeight="semibold"
									fontSize="16px"
									color="primary.50"
								>
									{moment(value).format('MMMM YYYY')}
								</Text>
								<Box>
									<Flex alignItems="center" justifyContent="flex-start">
										<Button 
										variant="transparent" 
										maxWidth="35px" 
										maxHeight="35px" 
										borderRadius="50%"
										display="flex"
										justifyContent="center"
										alignItems="center"
											marginRight="8px"
											type="button"
											
										onClick={() => {
											onChangeMonth(moment(value).subtract(1, 'M'));
										}}
										className="bghover">
											<Icon
												name="chevronLeft"
												width="24px"
												height="24px"
												cursor="pointer"
												fill="secondary.50"
											/>
										</Button>
										<Button 
										variant="transparent" 
										maxWidth="35px" 
										maxHeight="35px" 
										borderRadius="50%"
										display="flex"
										justifyContent="center"
											alignItems="center"
											type="button"
										onClick={() => {
											onChangeMonth(moment(value).add(1, 'M'));
										}}
										className="bghover">
											<Icon
												name="chevronRight"
												width="24px"
												height="24px"
												cursor="pointer"
												fill="secondary.50"
											/>
										</Button>
									</Flex>
								</Box>
							</Flex>
						</Row>
					);
				}}
				{...props}
			/>
		</Box>
	);
};

// https://stackoverflow.com/questions/44099560/how-to-use-ant-design-localeprovider-with-react-boilerplate
// https://github.com/ant-design/ant-design/issues/5605#issuecomment-547326165
// https://github.com/ant-design/ant-design/issues/5605#issuecomment-618835126
// https://stackoverflow.com/questions/59069572/ant-design-calendar-how-to-change-the-day-of-week-format
