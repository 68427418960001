import * as AWS from "aws-sdk";

const AWSService = AWS;

const { REACT_APP_S3_BUCKET, REACT_APP_AWS_IDENTITY_POOL_ID, REACT_APP_S3_REGION }: any = process.env;

AWSService.config.update({
  region: REACT_APP_S3_REGION,
  credentials: new AWSService.CognitoIdentityCredentials({
    IdentityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID,
  }),
});

const s3 = new AWSService.S3({
  params: { Bucket:REACT_APP_S3_BUCKET },
});

export default s3;
