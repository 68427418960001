import * as React from "react";

function TickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66666 1L3.70833 6.95833L1 4.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoTickIcon = React.memo(TickIcon);
export default MemoTickIcon;
