import React, { useEffect, useContext, useState } from 'react';
import * as yup from 'yup';

import { Form, Formik, FormikHelpers } from 'formik';
import { FormInput } from 'molecules/FormInput';
import { Box, Button, Text } from 'atoms';
import { FormContext } from '../RegisterPage';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import Strings from 'strings';
import { PASSWORD_REGEX } from 'settings/constants';

const { User: UserString } = Strings;

type formValuesType = {
	password: any;
};

interface BusinessInfoProps {
	onSubmit?: (
		values?: formValuesType,
		formikHelpers?: FormikHelpers<formValuesType>
	) => void | Promise<void>;
	roleId?: string;
	registerConfirmationToken?: any;
}

const SetupAccountValidationSchema = yup.object().shape({
	password: yup
		.string()
		.trim()
		.required(UserString.password.required)
		.matches(PASSWORD_REGEX, UserString.password.strength),
	confirm_password: yup
		.string()
		.required(UserString.confirmpassword.required)
		.oneOf([yup.ref('password'), 'password'], UserString.confirmpassword.notMatch),
});

const SetupAccount: React.FC<BusinessInfoProps> = ({
	onSubmit,
	roleId,
	registerConfirmationToken,
}: BusinessInfoProps) => {
	const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
	const { activeStepIndex, formData, setFormData }: any = useContext(FormContext);

	function getInitValue() {
		return {
			email: '',
			password: '',
			confirm_password: '',
		};
	}

	const [initialValue, setInitialValue] = useState<any>(getInitValue());

	useEffect(() => {
		setInitialValue(getInitValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activeStepIndex === 1) {
			setFormData({
				...formData,
				completedStep: 1,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStepIndex]);

	return (
		<Formik
			initialValues={initialValue}
			enableReinitialize
			onSubmit={async (formValues: any, actions) => {
				onSubmitHandler(
					{
						...formValues,
						...(registerConfirmationToken && {
							registerConfirmationToken,
							meta: { registerConfirmationToken },
						}),
					},
					actions
				);
			}}
			validationSchema={SetupAccountValidationSchema}
		>
			{() => (
				<Form>
					<Box mt="3rem">
						<Text
							fontSize="h3"
							lineHeight="h3"
							color="primary.50"
							fontFamily="Archivo-Bold"
							mt="5.6rem"
							mb="2.4rem"
						>
							Welcome
						</Text>
						<Text fontSize="p1" lineHeight="p1" mb="1.2rem">
							Welcome to My Coaching Place: the all-in-one coaching platform for
							managing your engagements and staying organised
						</Text>
						<Text fontSize="p1" lineHeight="p1" mb="2.4rem">
							To get started please create your password.{' '}
							<b>Select one that is easy to remember</b> as it will be required
							throughout your engagement.
						</Text>
						<FormInput
							label="Password"
							name="password"
							type="password"
							placeholder={'Password'}
							fontFamily="medium"
							maxLength={20}
						/>
						<FormInput
							label="Confirm Password"
							name="confirm_password"
							type="password"
							placeholder="Password"
							fontFamily="medium"
							maxLength={20}
						/>
						<Box mb="2rem">
							<Text>Minimum length of 8 characters.</Text>
							<Text>Maximum length of 20 characters.</Text>
							<Text>Include at least one uppercase letter (A-Z).</Text>
							<Text>Include at least one lowercase letter (a-z).</Text>
							<Text>Include at least one number (0-9).</Text>
							<Text>Include at least one special character (!@$%&*).</Text>
						</Box>
						<Button
							type="submit"
							variant="primary"
							fontSize="16px"
							my="1rem"
							height="41px"
							width="100%"
							loading={loading}
							disabled={!roleId}
						>
							Continue
						</Button>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

export default SetupAccount;
