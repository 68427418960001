import { FC, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router';

import { Box, Image } from 'atoms';
import { urlQueryToObj } from 'utils/buildQuery';

import VerifyAdmin from 'pages/Auth/VerifyAdmin';
import ConfirmEmail from '../Confirm';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import Login from '../Login';
import Register from '../Register';
import ClientRegister from '../ClientRegister';
import RegisterContinue from '../RegisterContinue';
import LoginContinue from '../LoginContinue';
import { Page404 as PageNotFound } from 'pages/Errors';

import useAuth from 'pages/Auth/context/Authentication';

export type AuthContainerProps = {};

export const AuthContainer: FC<AuthContainerProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [parsedData] = useState<any>();
	const [registrationComplete, setRegistrationComplete] = useState(false);

	const {
		state: { outsetaRef },
	} = useAuth();

	useEffect(() => {
		outsetaRef?.current?.on('signup', (data: any) => {
			if (!data?.PersonAccount?.[0]?.Person?.google_id_token) {
				setRegistrationComplete(true);
				navigate(`/registration-success`, { state: { email: data?.Name } });
			}
		});
	}, []);

	/*Not logged in redirect to login*/
	useEffect(() => {
		if (
			location?.pathname?.includes('/coach/') ||
			location?.pathname?.includes('/coachee/') ||
			location?.pathname?.includes('/admin/')
		) {
			navigate(`/login?redirectUrl=${location?.pathname}`);
		}
	}, []);

	useEffect(() => {
		if (
			location?.pathname?.includes('/login') &&
			location?.search?.includes('?confirmationToken')
		) {
			window.location.href = '/login';
		}
	}, [location?.search]);

	return (
		<Box
			height="100vh"
			overflowY="scroll"
			backgroundColor="#F1F9FA"
			display="flex"
			flexDirection={{ xs: 'column-reverse', md: 'row' }}
		>
			<Box
				width={{ xs: '100%', md: '50%' }}
				py="5rem"
				backgroundColor="white.0"
				boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
				overflowY={'scroll'}
				className={'scroll-hide'}
			>
				<Box height="100%">
					<Box>
						<Routes>
							<Route path="/login" element={<Login />} />

							<Route path="login/continue" element={<LoginContinue />} />

							<Route path="register/coach" element={<Register />} />

							<Route path="register/coachee" element={<ClientRegister />} />

							<Route path="organization/invite" element={<ClientRegister />} />

							<Route path="register/continue" element={<RegisterContinue />} />

							<Route path="/forgot-password" element={<ForgotPassword />} />

							<Route path="/reset-password" element={<ResetPassword />} />

							<Route path="/verify-admin" element={<VerifyAdmin />} />

							<Route path="/registration-success" element={<ConfirmEmail />} />
							<Route
								path="/confirm-email"
								element={parsedData?.email ? <ConfirmEmail /> : <PageNotFound />}
							/>
							<Route
								path="/verify/email"
								element={(props: any) => {
									try {
										//Restrict user to not use this route if query params not exist.
										let { code, selectedPlan }: any = urlQueryToObj(
											location.search
										);
										if (code) {
											return (
												<ConfirmEmail
													email={code.email}
													withCode={code}
													selectedPlan={selectedPlan}
													{...props}
												/>
											);
										}
									} catch (e) {
										return <Navigate to="/pagenotfound" replace />;
									}
									//404
									return <Navigate to="/pagenotfound" replace />;
								}}
							/>
							<Route
								path="/"
								element={
									<Navigate
										to={{
											pathname: '/login',
											// this will cause the single system with multiple user login redirect issue
											search: `?redirectUrl=${window.location.pathname}`,
										}}
										replace
									/>
								}
							/>
						</Routes>
					</Box>
				</Box>
			</Box>
			<Box
				backgroundColor="#F1F9FA"
				minHeight={{ xs: 'unset', md: '100vh' }}
				p={{ xs: '3rem', md: '2rem' }}
				display="flex"
				flexGrow="1"
				justifyContent="center"
				alignItems="center"
				width={{ xs: '100%', md: '50%' }}
			>
				<Box>
					{(location?.pathname == '/register/coachee' ||
						location?.pathname == '/register/coach') &&
						registrationComplete && (
							<Image
								src={require('assets/images/login/s2.png')}
								alt="A man seeing his goal"
							/>
						)}

					{location?.pathname == '/registration-success' && (
						<Image
							src={require('assets/images/login/s2.png')}
							alt="A man seeing his goal"
						/>
					)}
					{(location?.pathname?.includes('/login') ||
						(location?.pathname?.includes('/register') &&
							!location?.pathname?.includes('/register/continue') &&
							!registrationComplete)) && (
						<Image
							src={require('assets/images/login/s1.png')}
							alt="A man seeing his goal"
						/>
					)}

					{(location?.pathname?.includes('/forgot-password') ||
						location?.pathname?.includes('/register/continue')) && (
						<Image
							src={require('assets/images/login/s3.png')}
							alt="A man seeing his goal"
						/>
					)}
					{location?.pathname?.includes('/complete-profile') && (
						<Image
							src={require('assets/images/login/s4.png')}
							alt="A man seeing his goal"
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};
