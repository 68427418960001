import { useReducer } from 'react';
import { toast as message } from 'react-toastify';
import moment, { utc } from 'moment';
import { api } from 'utils/api/api';
import { buildQuery } from 'utils/buildQuery';
import { createAction, createContainer, createReducer } from 'utils/context';
import * as APIRoutes from './APIRoutes';

export type SessionsState = {
	list: {
		sessions: any;
		meta: any;
	};
	currentSession: any;
	loading: boolean;
	sessionUserData: {
		meetingDefaultsOfCoach: {
			meetingSessionLocation: any[];
		};
		meetingSessionLocations: any[];
		meetingSessionTypes: any[];
	};
	available: any;

	feedback: any;
	feedbackOption: {
		answers: any[];
		questions: any[];
	};
};

const initialState: SessionsState = {
	list: {
		sessions: [],
		meta: {},
	},
	currentSession: {},
	loading: true,
	sessionUserData: {
		meetingDefaultsOfCoach: {
			meetingSessionLocation: [],
		},
		meetingSessionLocations: [],
		meetingSessionTypes: [],
	},
	available: 0,

	feedback: {},
	feedbackOption: {
		answers: [],
		questions: [],
	},
};

const actions = {
	getSessions: createAction('GET_SESSION_LIST'),
	getSessionUserData: createAction('GET_SESSION_USER_DATA'),
	resetSession: createAction('RESET_SESSION'),
	createSession: createAction('CREATE_SESSION'),
	updateSession: createAction('UPDATE_SESSION'),
	getSessionById: createAction('GET_SESSION_BY_ID'),
	deleteSession: createAction('DELETE_SESSION'),
	setLoading: createAction('SET_LOADING'),
	getAvailability: createAction('GET_AVAILABILITY'),

	createFeedback: createAction('CREATE_FEEDBACK'),
	updateFeedback: createAction('UPDATE_FEEDBACK'),
	getFeedback: createAction('GET_FEEDBACK'),
	getFeedbackQuestions: createAction('GET_FEEDBACK_QNA'),
};

const userReducer = createReducer<SessionsState>({
	[`${actions.getSessions}`]: (state, { payload }) => ({
		...state,
		list: {
			sessions: payload?.meetingSessions || payload?.data || [],
			meta: payload?.meta,
		},
		loading: false,
	}),
	[`${actions.resetSession}`]: (state) => ({
		...state,
		list: {
			sessions: [],
			meta: {},
		},
		currentSession: {},
		available: 0,
		loading: true,
	}),
	[`${actions.getSessionById}`]: (state, { payload }) => ({
		...state,
		currentSession: payload.meetingSession,
	}),
	[`${actions.setLoading}`]: (state, { payload }) => ({
		...state,
		loading: payload,
	}),
	[`${actions.getSessionUserData}`]: (state, { payload }) => ({
		...state,
		sessionUserData: payload,
	}),
	[`${actions.getAvailability}`]: (state, { payload }) => ({
		...state,
		available: payload.length == 0 ? 1 : 2,
	}),

	[`${actions.getFeedback}`]: (state, { payload }) => ({
		...state,
		feedback: payload,
	}),
	[`${actions.getFeedbackQuestions}`]: (state, { payload }) => ({
		...state,
		feedbackOption: payload,
	}),
});

export const {
	useContext: useSessions,
	Context: SessionContext,
	Provider: SessionProvider,
} = createContainer(() => {
	const [state, dispatch] = useReducer(userReducer, {
		...initialState,
	});

	const getSessions = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else {
				queryParams = {
					...queryParams,
				};
			}
			await dispatch(actions.setLoading(true));
			if (parseInt(queryParams?.page) === 1) {
				resetSession();
			}
			const { data } = await api(`${APIRoutes.SESSIONS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			dispatch(actions.getSessions(data));

			if (typeof callback === 'function') {
				callback(data);
			}
			return data?.sessions;
		} catch (error) {
			message.error('Failed to get sessions list');
		}
	};

	const getSessionsAllforCalender = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else {
				queryParams = {
					...queryParams,
				};
			}
			await dispatch(actions.setLoading(true));
			if (parseInt(queryParams?.page) === 1) {
				resetSession();
			}
			const { data } = await api(`${APIRoutes.SESSIONS}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			//message.error('Failed to get sessions list');
		}
	};

	const getSessionUserData = async (coachId: any, callback?: (data: any) => void) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.SESSIONS}/coaches/${coachId}/types-locations`, {
				method: 'get',
			});
			dispatch(actions.getSessionUserData(data));
			dispatch(actions.setLoading(false));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to get the session user data');
		}
	};

	const getSessionById = async (id: any, callback?: (data: any) => void) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.SESSIONS}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getSessionById(data));
			dispatch(actions.setLoading(false));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to get this session');
		}
	};
	const createSession = async (payload1: any, callback?: (data: any, error?: any) => void) => {
		const payload = {
			...payload1,
			startDateTime: moment(payload1.startDateTime.format()).utc().format(),
			endDateTime: moment(payload1.endDateTime.format()).utc().format(),
		};
		try {
			dispatch(actions.setLoading(true));
			await checkAvailability(payload);
			const { data } = await api(`${APIRoutes.SESSIONS}`, {
				method: 'post',
				data: { ...payload },
			});
			message.success('Session created successfully');
			dispatch(actions.setLoading(false));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('The maximum number of sessions has been reached.');
			if (typeof callback === 'function') {
				callback('', 'The maximum number of sessions has been reached.');
			}
		}
	};
	const updateSession = async (id: any, payload: any, callback?: (data: any) => void) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.SESSIONS}/${id}`, {
				method: 'put',
				data: { ...payload },
			});
			dispatch(actions.getSessionById(data));
			dispatch(actions.setLoading(false));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error?.message || error?.error || 'Failed to update the session');
		}
	};
	const deleteSession = async (id: any, callback?: (data: any) => void) => {
		try {
			dispatch(actions.setLoading(true));
			const { data } = await api(`${APIRoutes.SESSIONS}/${id}`, {
				method: 'put',
				data: { status: 4 },
			});
			dispatch(actions.setLoading(false));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to cancel the session');
		}
	};
	const resetSession = async (callback?: () => void) => {
		try {
			dispatch(actions.resetSession());
			if (typeof callback === 'function') {
				callback();
			}
		} catch (error) {
			message.error('Failed to reset Sessions');
		}
	};
	const checkAvailability = async (payload: any, callback?: (data: any) => void) => {
		try {
			const query = {
				// _id: payload.coach,
				startDateTime: payload.startDateTime,
				endDateTime: payload.endDateTime,
			};
			const { data } = await api(
				`${APIRoutes.SESSIONS}/coaches/${payload?.coach}/availability?${buildQuery(query)}`,
				{
					method: 'get',
				}
			);
			if (!data?.availability) {
				message.warning('Overlapping Sessions Present');
			}
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to check Availability');
		}
	};

	const createFeedback = async (payload: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.FEEDBACK}`, {
				method: 'post',
				data: { ...payload },
			});
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			updateFeedback(payload.meetingSession, { rating: payload.rating });
			// message.error('Failed to create Feedback');
		}
	};
	const updateFeedback = async (id: any, payload: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.FEEDBACK}/${id}`, {
				method: 'put',
				data: { ...payload },
			});
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to update the session');
		}
	};
	const getFeedback = async (meetingId: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.FEEDBACK}/${meetingId}`, {
				method: 'get',
			});

			dispatch(actions.getFeedback(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			// message.error('Failed to get feedback');
		}
	};
	const getFeedbackQuestions = async (callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.FEEDBACK}/question-answer`, {
				method: 'get',
			});
			const customOrder = [
				'Met all of my needs',
				'Met Most of my needs',
				'Met Some of my needs',
				"Didn't meet my needs",
			];

			const sortedArray = data?.answers?.sort(
				(a: any, b: any) => customOrder.indexOf(a.content) - customOrder.indexOf(b.content)
			);

			dispatch(actions.getFeedbackQuestions({ ...data, answers: sortedArray }));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error('Failed to feedback questions');
		}
	};

	return {
		state,
		actions: {
			getSessions,
			getSessionsAllforCalender,
			getSessionUserData,
			getSessionById,
			createSession,
			updateSession,
			deleteSession,
			resetSession,
			getFeedback,
			createFeedback,
			updateFeedback,
			getFeedbackQuestions,
		},
	};
});

export default useSessions;
