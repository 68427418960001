import { Box, Text, Icon, Flex } from 'atoms';
import { FC } from 'react';
import { useLocation } from 'react-router';

export const ConfirmEmail: FC = () => {
	const location = useLocation();

	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<Box mb="2rem">
				<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
					<Icon name="logo" height="7rem" type={'text'} />
				</Flex>
			</Box>

			<Box>
				<Text
					fontSize="h3"
					lineHeight="h3"
					color="primary.50"
					fontFamily="Archivo-Bold"
					mt="3rem"
					mb="2.5rem"
				>
					Check your email
				</Text>

				<Text color="primary.50" fontSize="16px" mt="40px">
					We’ve sent you a verification email to{' '}
					<Text as="span" fontFamily="semibold">
						{location?.state?.email || ''}
					</Text>
					.
				</Text>

				<Text color="primary.50" fontSize="16px" my="20px">
					<b>
						You will need to verify your email address and set your password within the{' '}
						next 30 mins
					</b>
					. Otherwise you will need to contact{' '}
					<Text as="span" fontFamily="semibold">
						<a href="mailto:support@mycoachingplace.com">support@mycoachingplace.com</a>
					</Text>{' '}
				</Text>

				<Text color="primary.50" fontSize="16px" my="20px">
					Can’t see the email? Have a look at your spam folder. <br />
					Click{' '}
					<Text as="span" fontFamily="semibold">
						<a href="mailto:support@mycoachingplace.com">here</a>
					</Text>{' '}
					if you still can’t see it.
				</Text>
			</Box>
		</Box>
	);
};
