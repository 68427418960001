// Organization
export const coaches: string = `/coaches`;
export const organizations: string = `/organizations`;
export const organizationContacts: string = `/organization-contacts`;
export const organizationGroups: string = `/organization-verticals`;
export const organizationGroupCodes: string = `/organization-vertical-codes`;
export const organizationGroupCodesHistory: string = `/organization-vertical-codes/codes`;
export const addOrganizationGroupCodes: string = `/organization-group-codes/insert-many`;
export const organizationSessionPackages: string = `/session-packages`;

export const allCodes: string = `/admin/organization/codes`;
export const reportSendContacts: string = `/admin/organization/codes/send`;
export const admins: string = `/admin`;
