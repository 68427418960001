import React, { useState, useCallback, useContext, useEffect, MutableRefObject } from 'react';
import classNames from 'classnames';
import { message, Modal } from 'antd';
import { Text, Box, Button, Flex } from 'atoms';
import { useNavigate, useLocation } from 'react-router-dom';
import { AskConfirmation } from 'molecules/AskConfirmation';

import ZoomContext from '../../../context/zoom-context';
import useAuth from 'pages/Auth/context/Authentication';
import useUsers from 'pages/Users/context/users';

import RecordingContext from '../../../context/recording-context';
import CameraButton from './camera';
import SwitchCameraButton from './switchCamera';
import ChatButton from './chat';
import MicrophoneButton from './microphone';
import { ScreenShareButton } from './screen-share';
import AudioVideoStatisticModal from './audio-video-statistic';
import ZoomMediaContext from '../../../context/media-context';
import { useUnmount, useMount } from '../../../hooks';
import { MediaDevice } from '../video-types';
import './video-footer.scss';
import { isAndroidOrIOSBrowser } from '../../../utils/platform';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import {
	DialoutState,
	RecordingStatus,
	MutedSource,
	AudioChangeAction,
	DialOutOption,
	VideoCapturingState,
	SharePrivilege,
	MobileVideoFacingMode,
} from '@zoom/videosdk';
import { LeaveButton } from './leave';
import { TranscriptionSubtitle } from './transcription-subtitle';
import { current } from 'immer';

import useSessions from 'pages/Session/context/sessions';

interface VideoFooterProps {
	className?: string;
	shareRef?: MutableRefObject<HTMLCanvasElement | null>;
	sharing?: boolean;
	chatOpened?: any;
	videoPreview?: any;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = (props: VideoFooterProps) => {
	const {
		state: { currentPortal: userType, user, meetingNotification },
		actions: { sendMeetingResponse },
	} = useAuth();

	const {
		state: { currentSession },
		actions: { getSessionById, updateSession },
	} = useSessions();

	const {
		state: { currentUser },
		actions: { getOne, fetchUsers },
	} = useUsers();

	const navigate = useNavigate();
	const { state } = useLocation();
	const { isMutedInit, isStartedVideoInit, showFeedback } = state;
	const { className, shareRef, sharing, chatOpened, videoPreview = {} } = props;
	const [isStartedAudio, setIsStartedAudio] = useState(true);
	const [isStartedVideo, setIsStartedVideo] = useState(false);
	const [audio, setAudio] = useState('');
	const [isSupportPhone, setIsSupportPhone] = useState(false);
	const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
	const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
	const [isStartedScreenShare, setIsStartedScreenShare] = useState(false);
	const [isStartedLiveTranscription, setIsStartedLiveTranscription] = useState(false);
	const [isMirrored, setIsMirrored] = useState(false);
	const [isBlur, setIsBlur] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [activeMicrophone, setActiveMicrophone] = useState('');
	const [activeSpeaker, setActiveSpeaker] = useState('');
	const [activeCamera, setActiveCamera] = useState('');
	const [micList, setMicList] = useState<MediaDevice[]>([]);
	const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
	const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
	const [statisticVisible, setStatisticVisible] = useState(false);
	const [selecetedStatisticTab, setSelectedStatisticTab] = useState('audio');
	const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
	const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
	const [caption, setCaption] = useState({ text: '', isOver: false });
	const [activePlaybackUrl, setActivePlaybackUrl] = useState('');
	const [open, setOpen] = useState(false);
	const [allowParticipant, setAllowParticipant] = useState(false);

	const { mediaStream } = useContext(ZoomMediaContext);
	// const liveTranscriptionClient = useContext(LiveTranscriptionContext);
	const recordingClient = useContext(RecordingContext);
	const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(
		recordingClient?.getCloudRecordingStatus() || ''
	);
	const [recordingIsoStatus, setRecordingIsoStatus] = useState<'' | RecordingStatus>('');
	const zmClient = useContext(ZoomContext);

	const onCameraClick = useCallback(async () => {
		if (isStartedVideo) {
			await mediaStream?.stopVideo();
			setIsStartedVideo(false);
			videoPreview(false);
		} else {
			if (mediaStream?.isRenderSelfViewWithVideoElement()) {
				const videoElement = document.querySelector(
					`#${SELF_VIDEO_ID}`
				) as HTMLVideoElement;
				if (videoElement) {
					await mediaStream?.startVideo({ videoElement });
				}
			} else {
				const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
				if (mediaStream?.isSupportVirtualBackground() && isBlur) {
					Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
				}
				await mediaStream?.startVideo(startVideoOptions);
				if (!mediaStream?.isSupportMultipleVideos()) {
					const canvasElement = document.querySelector(
						`#${SELF_VIDEO_ID}`
					) as HTMLCanvasElement;
					mediaStream?.renderVideo(
						canvasElement,
						zmClient.getSessionInfo().userId,
						canvasElement.width,
						canvasElement.height,
						0,
						0,
						3
					);
				}
			}

			setIsStartedVideo(true);
			videoPreview(true);
		}
	}, [mediaStream, isStartedVideo, zmClient, isBlur]);

	const onMicrophoneClick = useCallback(async () => {
		if (isStartedAudio) {
			if (isMuted) {
				await mediaStream?.unmuteAudio();
				setIsMuted(false);
			} else {
				await mediaStream?.muteAudio();
				setIsMuted(true);
			}
		} else {
			//await mediaStream?.startAudio({ speakerOnly: true });
			//await mediaStream?.startAudio();
			// setTimeout(async () => {
			// 	await mediaStream?.startAudio();
			// 	setIsStartedAudio(true);
			// }, 1000);
			try {
				await mediaStream?.startAudio();
			} catch (e: any) {
				if (
					e.type === 'INSUFFICIENT_PRIVILEGES' &&
					e.reason === 'USER_FORBIDDEN_MICROPHONE'
				) {
					//setIsMicrophoneForbidden(true);
				}
				console.warn(e);
			}
			setIsStartedAudio(true);
		}
	}, [mediaStream, isStartedAudio, isMuted]);

	const onMicrophoneMenuClick = async (key: string) => {
		if (mediaStream) {
			const [type, deviceId] = key.split('|');
			if (type === 'microphone') {
				if (deviceId !== activeMicrophone) {
					await mediaStream.switchMicrophone(deviceId);
					setActiveMicrophone(mediaStream.getActiveMicrophone());
				}
			} else if (type === 'speaker') {
				if (deviceId !== activeSpeaker) {
					await mediaStream.switchSpeaker(deviceId);
					setActiveSpeaker(mediaStream.getActiveSpeaker());
				}
			} else if (type === 'leave audio') {
				if (audio === 'computer') {
					await mediaStream.stopAudio();
				} else if (audio === 'phone') {
					await mediaStream.hangup();
					setPhoneCallStatus(undefined);
				}
				setIsStartedAudio(false);
			} else if (type === 'statistic') {
				setSelectedStatisticTab('audio');
				setStatisticVisible(true);
			}
		}
	};
	const onSwitchCamera = async (key: string) => {
		if (mediaStream) {
			if (activeCamera !== key) {
				await mediaStream.switchCamera(key);
				setActiveCamera(mediaStream.getActiveCamera());
				setActivePlaybackUrl('');
			}
		}
	};
	const onMirrorVideo = async () => {
		await mediaStream?.mirrorVideo(!isMirrored);
		setIsMirrored(!isMirrored);
	};
	const onBlurBackground = async () => {
		const vbStatus = mediaStream?.getVirtualbackgroundStatus();
		if (vbStatus?.isVBPreloadReady) {
			if (!isBlur) {
				await mediaStream?.updateVirtualBackgroundImage('blur');
			} else {
				await mediaStream?.updateVirtualBackgroundImage(undefined);
			}

			setIsBlur(!isBlur);
		}
	};
	const onPhoneCall = async (
		code: string,
		phoneNo: string,
		name: string,
		option: DialOutOption
	) => {
		await mediaStream?.inviteByPhone(code, phoneNo, name, option);
	};
	const onPhoneCallCancel = async (
		code: string,
		phoneNo: string,
		option: { callMe: boolean }
	) => {
		if (
			[DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(
				phoneCallStatus as any
			)
		) {
			await mediaStream?.cancelInviteByPhone(code, phoneNo, option);
			await new Promise((resolve) => {
				setTimeout(() => {
					resolve(true);
				}, 3000);
			});
		}
		return Promise.resolve();
	};
	const onHostAudioMuted = useCallback((payload) => {
		const { action, source, type } = payload;
		if (action === AudioChangeAction.Join) {
			setIsStartedAudio(true);
			setAudio(type);
		} else if (action === AudioChangeAction.Leave) {
			setIsStartedAudio(false);
		} else if (action === AudioChangeAction.Muted) {
			setIsMuted(true);
			if (source === MutedSource.PassiveByMuteOne) {
				message.info('Host muted you');
			}
		} else if (action === AudioChangeAction.Unmuted) {
			setIsMuted(false);
			if (source === 'passive') {
				message.info('Host unmuted you');
			}
		}
	}, []);
	const onScreenShareClick = useCallback(async () => {
		if (!isStartedScreenShare && shareRef && shareRef.current) {
			await mediaStream?.startShareScreen(shareRef.current, { requestReadReceipt: true });
			setIsStartedScreenShare(true);
		} else if (isStartedScreenShare) {
			await mediaStream?.stopShareScreen();
			setIsStartedScreenShare(false);
		}
	}, [mediaStream, isStartedScreenShare, shareRef]);

	const onLeaveClick = useCallback(async () => {
		const url = window.location.pathname;
		const sessionId = url.substring(url.lastIndexOf('/') + 1);
		if (isStartedAudio) {
			mediaStream?.stopAudio();
		}
		if (isStartedVideo) {
			mediaStream?.stopVideo();
		}
		if (isStartedScreenShare) {
			mediaStream?.stopShareScreen();
		}
		await zmClient.leave();
		await markSessionComplete(sessionId, () => {
			if (userType == 'coach') {
				navigate(`/${userType}/sessions/sessionEnd/${sessionId}`);
			} else if (showFeedback) {
				navigate(`/${userType}/sessions/feedback/${sessionId}`);
			} else {
				navigate(`/${userType}/sessions`);
			}
		});
	}, [zmClient]);

	const onEndClick = useCallback(async () => {
		const url = window.location.pathname;
		const sessionId = url.substring(url.lastIndexOf('/') + 1);
		if (isStartedAudio) {
			mediaStream?.stopAudio();
		}
		if (isStartedVideo) {
			mediaStream?.stopVideo();
		}
		if (isStartedScreenShare) {
			mediaStream?.stopShareScreen();
		}
		await zmClient.leave(true);
		await markSessionComplete(sessionId, () => {
			if (userType == 'coach') {
				navigate(`/${userType}/sessions/sessionEnd/${sessionId}`);
			} else if (showFeedback) {
				navigate(`/${userType}/sessions/feedback/${sessionId}`);
				navigate(0);
			} else {
				navigate(`/${userType}/sessions`);
			}
		});
	}, [zmClient]);

	const checkMeetingEnds = (payload: any) => {
		if (payload && payload?.state === 'Closed' && payload?.reason == 'ended by host') {
			setTimeout(() => {
				onLeaveClick();
			}, 500);
		}
	};

	const onPassivelyStopShare = useCallback(({ reason }) => {
		console.log('passively stop reason:', reason);
		setIsStartedScreenShare(false);
	}, []);
	const onDeviceChange = useCallback(() => {
		if (mediaStream) {
			setMicList(mediaStream.getMicList());
			setSpeakerList(mediaStream.getSpeakerList());
			if (!isAndroidOrIOSBrowser()) {
				setCameraList(mediaStream.getCameraList());
			}
			setActiveMicrophone(mediaStream.getActiveMicrophone());
			setActiveSpeaker(mediaStream.getActiveSpeaker());
			setActiveCamera(mediaStream.getActiveCamera());
		}
	}, [mediaStream]);

	const onRecordingChange = useCallback(() => {
		setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
	}, [recordingClient]);

	const onRecordingISOChange = useCallback(
		(payload: any) => {
			if (
				payload?.userId === zmClient.getSessionInfo().userId ||
				payload?.status === RecordingStatus.Ask
			) {
				setRecordingIsoStatus(payload?.status);
			}
			console.log('recording-iso-change', payload);
		},
		[zmClient]
	);

	const onDialOutChange = useCallback((payload) => {
		setPhoneCallStatus(payload.code);
	}, []);

	const onRecordingClick = async (key: string) => {
		switch (key) {
			case 'Record': {
				await recordingClient?.startCloudRecording();
				break;
			}
			case 'Resume': {
				await recordingClient?.resumeCloudRecording();
				break;
			}
			case 'Stop': {
				await recordingClient?.stopCloudRecording();
				break;
			}
			case 'Pause': {
				await recordingClient?.pauseCloudRecording();
				break;
			}
			case 'Status': {
				break;
			}
			default: {
				await recordingClient?.startCloudRecording();
			}
		}
	};

	const onVideoCaptureChange = useCallback((payload) => {
		if (payload.state === VideoCapturingState.Started) {
			setIsStartedVideo(true);
			videoPreview(true);
		} else {
			setIsStartedVideo(false);
			videoPreview(false);
		}
	}, []);

	const onShareAudioChange = useCallback(
		(payload) => {
			const { state } = payload;
			if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
				if (state === 'on') {
					setIsComputerAudioDisabled(true);
				} else if (state === 'off') {
					setIsComputerAudioDisabled(false);
				}
			}
		},
		[mediaStream]
	);

	const onHostAskToUnmute = useCallback((payload) => {
		const { reason } = payload;
		console.log(`Host ask to unmute the audio.`, reason);
	}, []);

	const onCaptionStatusChange = useCallback((payload) => {
		const { autoCaption } = payload;
		if (autoCaption) {
			message.info('Auto live transcription enabled!');
		}
	}, []);

	const onCaptionMessage = useCallback((payload) => {
		const { text, done } = payload;
		setCaption({
			text,
			isOver: done,
		});
	}, []);

	const onCanSeeMyScreen = useCallback(() => {
		message.info('Users can now see your screen', 1);
	}, []);

	const onSelectVideoPlayback = useCallback(
		async (url: string) => {
			if (activePlaybackUrl !== url) {
				await mediaStream?.switchCamera({ url, loop: true });
				if (isStartedAudio) {
					await mediaStream?.switchMicrophone({ url, loop: true });
				} else {
					await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
				}
				setActivePlaybackUrl(url);
			}
		},
		[isStartedAudio, activePlaybackUrl, mediaStream]
	);
	const showModal = () => {
		setOpen(true);
	};
	// const hideModal = () => {
	// 	setOpen(false);
	// };
	// const hideParticipantsModal = () => {
	// 	setAllowParticipant(false);
	// };
	const markSessionComplete = async (sessionId: any, fn: any) => {
		const payload = {
			status: 5,
		};
		updateSession(sessionId, payload, fn);
	};

	useEffect(() => {
		if (meetingNotification) {
			if (
				meetingNotification?.userId !== user?._id &&
				meetingNotification?.msg?.meetingId &&
				meetingNotification?.msg?.meetingId === currentSession?._id
			) {
				getOne(meetingNotification?.userId);
				getSessionById(meetingNotification?.msg?.meetingId);
			}
		}
	}, [meetingNotification, currentSession?._id, user?._id]);

	useEffect(() => {
		if (currentSession?.meta?.onlineUsers?.length > 0) {
			setAllowParticipant(true);
		}
	}, [currentUser, currentUser?._id, currentSession]);

	useEffect(() => {
		zmClient.on('current-audio-change', onHostAudioMuted);
		zmClient.on('passively-stop-share', onPassivelyStopShare);
		zmClient.on('device-change', onDeviceChange);
		zmClient.on('recording-change', onRecordingChange);
		zmClient.on('individual-recording-change', onRecordingISOChange);
		zmClient.on('dialout-state-change', onDialOutChange);
		zmClient.on('video-capturing-change', onVideoCaptureChange);
		zmClient.on('share-audio-change', onShareAudioChange);
		zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
		zmClient.on('caption-status', onCaptionStatusChange);
		zmClient.on('caption-message', onCaptionMessage);
		zmClient.on('share-can-see-screen', onCanSeeMyScreen);
		zmClient.on('connection-change', checkMeetingEnds);
		return () => {
			zmClient.off('current-audio-change', onHostAudioMuted);
			zmClient.off('passively-stop-share', onPassivelyStopShare);
			zmClient.off('device-change', onDeviceChange);
			zmClient.off('recording-change', onRecordingChange);
			zmClient.off('individual-recording-change', onRecordingISOChange);
			zmClient.off('dialout-state-change', onDialOutChange);
			zmClient.off('video-capturing-change', onVideoCaptureChange);
			zmClient.off('share-audio-change', onShareAudioChange);
			zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
			zmClient.off('caption-status', onCaptionStatusChange);
			zmClient.off('caption-message', onCaptionMessage);
			zmClient.off('share-can-see-screen', onCanSeeMyScreen);
			zmClient.off('connection-change', checkMeetingEnds);
		};
	}, [
		zmClient,
		onHostAudioMuted,
		onPassivelyStopShare,
		onDeviceChange,
		onRecordingChange,
		onDialOutChange,
		onVideoCaptureChange,
		onShareAudioChange,
		onHostAskToUnmute,
		onCaptionStatusChange,
		onCaptionMessage,
		onCanSeeMyScreen,
		onRecordingISOChange,
	]);

	useUnmount(() => {
		if (isStartedAudio) {
			mediaStream?.stopAudio();
		}
		if (isStartedVideo) {
			mediaStream?.stopVideo();
		}
		if (isStartedScreenShare) {
			mediaStream?.stopShareScreen();
		}
	});

	useMount(async () => {
		if (mediaStream) {
			setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
			setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
			setSharePrivileg(mediaStream.getSharePrivilege());
			if (isAndroidOrIOSBrowser()) {
				setCameraList([
					{ deviceId: MobileVideoFacingMode.User, label: 'Front-facing' },
					{ deviceId: MobileVideoFacingMode.Environment, label: 'Rear-facing' },
				]);
			}
			console.log('res isMutedInit: ', isMutedInit);

			if (isMutedInit) {
				await mediaStream?.muteAudio();
				setIsMuted(true);
			}
			if (isStartedVideoInit) {
				onCameraClick();
			}

			// try {
			// 	await mediaStream?.startAudio();
			// 	setIsStartedAudio(true);
			// 	setIsMuted(isMutedInit);
			// } catch (e) { }
			// if (isStartedVideoInit) {
			// 	onCameraClick();
			// }
		}
	});

	// const init = async () =>{
	// 	console.log('init: ');
	// 	console.log('isMutedInit: ', isMutedInit);
	// 	console.log('isStartedVideoInit: ', isStartedVideoInit);
	// 	// try {
	// 	// 	await mediaStream?.startAudio();
	// 	// 	setIsStartedAudio(true);
	// 	// 	setIsMuted(isMutedInit);
	// 	// } catch (e) { }
	// 	try {
	// 		onMicrophoneClick();
	// 	} catch (e) {
	// 		console.log("error is: ", e);
	// 	}
	// 	if (!isMutedInit) {
	// 		onMicrophoneClick();
	// 	}
	// 	if (!isStartedVideoInit) {
	// 		onCameraClick();
	// 	}
	// }

	// useEffect(() => {
	// 	if (zmClient && state?.sessionId) {
	// 		// const info = zmClient?.getSessionInfo();
	// 		init();
	// 		// if (info?.topic) {
	// 		// 	console.log("zmCLient: " )
	// 		// }
	// 	}
	// }, [zmClient,state]);

	useEffect(() => {
		if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
			mediaStream.subscribeAudioStatisticData();
			mediaStream.subscribeVideoStatisticData();
			mediaStream.subscribeShareStatisticData();
		}
		return () => {
			if (zmClient.getSessionInfo().isInMeeting) {
				mediaStream?.unsubscribeAudioStatisticData();
				mediaStream?.unsubscribeVideoStatisticData();
				mediaStream?.unsubscribeShareStatisticData();
			}
		};
	}, [mediaStream, zmClient]);

	return (
		<div className={classNames('video-footer', className)}>
			{isAudioEnable && (
				<MicrophoneButton
					isStartedAudio={isStartedAudio}
					isMuted={isMuted}
					isSupportPhone={isSupportPhone}
					audio={audio}
					phoneCountryList={phoneCountryList}
					onPhoneCallClick={onPhoneCall}
					onPhoneCallCancel={onPhoneCallCancel}
					phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
					onMicrophoneClick={onMicrophoneClick}
					onMicrophoneMenuClick={onMicrophoneMenuClick}
					microphoneList={micList}
					speakerList={speakerList}
					activeMicrophone={activeMicrophone}
					activeSpeaker={activeSpeaker}
					disabled={isComputerAudioDisabled}
				/>
			)}
			{/* {
				isAndroidOrIOSBrowser() && cameraList.length>0 && (
					<SwitchCameraButton
						onSwitchCamera={onSwitchCamera}
						cameraList={cameraList}
						activeCamera={activeCamera}
					/>
				)
			} */}

			<CameraButton
				isStartedVideo={isStartedVideo}
				onCameraClick={onCameraClick}
				onSwitchCamera={onSwitchCamera}
				onMirrorVideo={onMirrorVideo}
				onVideoStatistic={() => {
					setSelectedStatisticTab('video');
					setStatisticVisible(true);
				}}
				onBlurBackground={onBlurBackground}
				onSelectVideoPlayback={onSelectVideoPlayback}
				activePlaybackUrl={activePlaybackUrl}
				cameraList={cameraList}
				activeCamera={activeCamera}
				isMirrored={isMirrored}
				isBlur={isBlur}
			/>
			<ChatButton chatOpened={chatOpened} />

			{!isAndroidOrIOSBrowser() && sharing && (
				<ScreenShareButton
					sharePrivilege={sharePrivilege}
					isHostOrManager={zmClient.isHost() || zmClient.isManager()}
					isStartedScreenShare={isStartedScreenShare}
					onScreenShareClick={isStartedScreenShare ? onScreenShareClick : showModal}
					onSharePrivilegeClick={async (privilege) => {
						await mediaStream?.setSharePrivilege(privilege);
						setSharePrivileg(privilege);
					}}
				/>
			)}
			<LeaveButton
				onLeaveClick={onLeaveClick}
				isHost={zmClient.isHost()}
				onEndClick={onEndClick}
			/>

			<AudioVideoStatisticModal
				visible={statisticVisible}
				setVisible={setStatisticVisible}
				defaultTab={selecetedStatisticTab}
				isStartedAudio={isStartedAudio}
				isMuted={isMuted}
				isStartedVideo={isStartedVideo}
			/>
			{/* <Modal onCancel={()=>{setOpen(false)}} centered open={open} footer={false}>
				<Text as="h3" fontWeight="regular">
					You are going to share your screen in a moment. Please make sure that you
					minimize any private information open in your screen
				</Text>
				<Flex alignItems="center" mt="16px">
					<Button
						onClick={() => {
							setOpen(false);
							onScreenShareClick();
						}}
						className="sm"
						mr="5px"
					>
						Share Screen
					</Button>
					<Button onClick={()=>{setOpen(false)}} variant="secondary" className="sm">
						Cancel
					</Button>
				</Flex>
			</Modal> */}

			{open && (
				<AskConfirmation
					title={'Share Screen Confirmation'}
					message={
						<Box maxWidth="600px">
							<Text>
								You are going to share your screen in a moment. Please make sure
								that you minimize any private information open in your screen
							</Text>
						</Box>
					}
					visible={open}
					onClose={() => {
						setOpen(false);
					}}
					onOk={() => {
						console.log('Clicked Screen Share');
						onScreenShareClick();
						setOpen(false);
					}}
					onOkText={'Share Screen'}
					showDeleteIcon={false}
				/>
			)}

			{allowParticipant && userType == 'coach' && (
				<AskConfirmation
					title={'Allow User to Join Session'}
					message={
						<Text>
							{`${
								currentUser?.firstName || currentSession?.coachee?.firstName || '-'
							} ${
								currentUser?.lastName || currentSession?.coachee?.lastName || ''
							} is in waiting room. Allow the user to join`}
						</Text>
					}
					visible={allowParticipant}
					onClose={() => {
						sendMeetingResponse(
							currentSession?.coachee?._id || meetingNotification?.userId,
							{
								status: 'declined',
								meetingId: currentSession?._id,
							}
						);
						setAllowParticipant(false);
					}}
					onOk={() => {
						sendMeetingResponse(
							currentSession?.coachee?._id || meetingNotification?.userId,
							{ status: 'accepted', meetingId: currentSession?._id }
						);
						getSessionById(currentSession?._id, () => {
							setAllowParticipant(false);
						});
					}}
					onOkText={'Allow Participant'}
					showDeleteIcon={false}
				/>
			)}
			{/* { !isStartedAudio && (
					<AskConfirmation
						title={'Start Audio'}
						message={
							<Text>
								Enable this so that other people can hear you
							</Text>
						}
						visible={!isStartedAudio}
					onClose={()=>{}}
					onOk={onMicrophoneClick}
						onOkText={'Start Audio'}
						showDeleteIcon={false}
					/>
				)} */}
		</div>
	);
};
export default VideoFooter;
