import * as React from 'react';
import IconProps from './IconProps';
function TodoIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				width="22"
				height="22"
				viewBox="0 0 22 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.084 3.6665H3.66732C3.18109 3.6665 2.71477 3.85966 2.37096 4.20347C2.02714 4.54729 1.83398 5.01361 1.83398 5.49984V18.3332C1.83398 18.8194 2.02714 19.2857 2.37096 19.6295C2.71477 19.9733 3.18109 20.1665 3.66732 20.1665H16.5007C16.9869 20.1665 17.4532 19.9733 17.797 19.6295C18.1408 19.2857 18.334 18.8194 18.334 18.3332V11.9165"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.959 2.29171C17.3237 1.92704 17.8183 1.72217 18.334 1.72217C18.8497 1.72217 19.3443 1.92704 19.709 2.29171C20.0737 2.65638 20.2785 3.15099 20.2785 3.66671C20.2785 4.18244 20.0737 4.67704 19.709 5.04171L11.0007 13.75L7.33398 14.6667L8.25065 11L16.959 2.29171Z"
					fill="white"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	}
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.0834 3.66663H3.66671C3.18048 3.66663 2.71416 3.85978 2.37034 4.2036C2.02653 4.54741 1.83337 5.01373 1.83337 5.49996V18.3333C1.83337 18.8195 2.02653 19.2858 2.37034 19.6297C2.71416 19.9735 3.18048 20.1666 3.66671 20.1666H16.5C16.9863 20.1666 17.4526 19.9735 17.7964 19.6297C18.1402 19.2858 18.3334 18.8195 18.3334 18.3333V11.9166"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.9584 2.29171C17.323 1.92704 17.8176 1.72217 18.3334 1.72217C18.8491 1.72217 19.3437 1.92704 19.7084 2.29171C20.073 2.65638 20.2779 3.15099 20.2779 3.66671C20.2779 4.18244 20.073 4.67704 19.7084 5.04171L11 13.75L7.33337 14.6667L8.25004 11L16.9584 2.29171Z"
				stroke="#DEE4E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const MemoTodoIcon = React.memo(TodoIcon);
export default MemoTodoIcon;
