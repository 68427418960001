import * as React from 'react';

function AdminDashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M12.75 7.083h-8.5M14.875 4.25H2.125M14.875 9.917H2.125M12.75 12.75h-8.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoAdminDashboardIcon = React.memo(AdminDashboardIcon);
export default MemoAdminDashboardIcon;
