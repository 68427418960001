import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { useParams } from 'react-router';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Video from './feature/video/video';
import VideoSingle from './feature/video/video-single';
import VideoNonSAB from './feature/video/video-non-sab';
import useAuth from 'pages/Auth/context/Authentication';
import { socket } from 'pages/Auth/context/Authentication';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import ChatContext from './context/chat-context';
import LoadingLayer from './component/loading-layer';
import { ChatClient, MediaStream, Participant } from './index-types';
import './App.css';
import { isAndroidBrowser } from './utils/platform';
import { useParticipantsChange } from './feature/video/hooks/useParticipantsChange';
import _ from 'lodash';

interface AppProps {
	meetingArgs: {
		sdkKey: string;
		topic: string;
		signature: string;
		name: string;
		password?: string;
		webEndpoint?: string;
		enforceGalleryView?: string;
	};
}
const mediaShape = {
	audio: {
		encode: false,
		decode: false,
	},
	video: {
		encode: false,
		decode: false,
	},
	share: {
		encode: false,
		decode: false,
	},
};
const mediaReducer = produce((draft, action) => {
	switch (action.type) {
		case 'audio-encode': {
			draft.audio.encode = action.payload;
			break;
		}
		case 'audio-decode': {
			draft.audio.decode = action.payload;
			break;
		}
		case 'video-encode': {
			draft.video.encode = action.payload;
			break;
		}
		case 'video-decode': {
			draft.video.decode = action.payload;
			break;
		}
		case 'share-encode': {
			draft.share.encode = action.payload;
			break;
		}
		case 'share-decode': {
			draft.share.decode = action.payload;
			break;
		}
		case 'reset-media': {
			Object.assign(draft, { ...mediaShape });
			break;
		}
		default:
			break;
	}
}, mediaShape);

declare global {
	interface Window {
		webEndpoint: string | undefined;
		zmClient: any | undefined;
		mediaStream: any | undefined;
		crossOriginIsolated: boolean;
		ltClient: any | undefined;
	}
}

function App(props: AppProps) {
	const {
		meetingArgs: {
			sdkKey,
			topic,
			signature,
			name,
			password,
			webEndpoint: webEndpointArg,
			enforceGalleryView,
		},
	} = props;
	const [audioEncode, setAudioEncode] = useState(false);
	const [audioDecode, setAudioDecode] = useState(false);
	const [loading, setIsLoading] = useState(true);
	const [loadingText, setLoadingText] = useState('');
	const [isFailover, setIsFailover] = useState<boolean>(false);
	const [status, setStatus] = useState<string>('closed');
	const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
	const [chatClient, setChatClient] = useState<ChatClient | null>(null);
	const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);
	const [participants, setParticipants] = useState<Participant[]>([]);
	// const [users, setUsers] = useState<any[]>([]);

	const { id } = useParams();

	const {
		state: { currentPortal: userType, user: userData },
	} = useAuth();

	const zmClient = useContext(ZoomContext);

	useParticipantsChange(zmClient, (payload) => {
		console.log('Participants: ', payload);
		setParticipants(payload);
	});
	let webEndpoint: any;
	if (webEndpointArg) {
		webEndpoint = webEndpointArg;
	} else {
		webEndpoint = window?.webEndpoint ?? 'zoom.us';
	}
	const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
	const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;

	useEffect(() => {
		if (userType === 'coachee') {
			const payload = {
				userId: userData?._id,
				msg: { meetingId: id },
			};
			socket.emit('disconnectMeeting', payload);
		}
	}, [userData?._id]);

	useEffect(() => {
		const init = async () => {
			await zmClient.init('en-US', `${window.location.origin}/lib`, {
				webEndpoint,
				enforceMultipleVideos: galleryViewWithoutSAB,
				stayAwake: true,
			});
			try {
				setLoadingText('Joining the session...');
				await zmClient.join(topic, signature, name, password).catch((e) => {
					console.log(e);
				});
				const stream = zmClient.getMediaStream();
				setMediaStream(stream);
				var isSafari = window['safari'] !== undefined;
				if (!isSafari) await stream.startAudio();
				setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
				const chatClient = zmClient.getChatClient();
				setChatClient(chatClient);
				setIsLoading(false);
			} catch (e: any) {
				console.log(e);
				setIsLoading(false);
				message.error(e.reason);
			}
		};
		init();
		return () => {
			ZoomVideo.destroyClient();
		};
	}, [sdkKey, signature, zmClient, topic, name, password, webEndpoint, galleryViewWithoutSAB]);
	const onConnectionChange = useCallback(
		(payload) => {
			if (payload.state === ConnectionState.Reconnecting) {
				setIsLoading(true);
				setIsFailover(true);
				setStatus('connecting');
				const { reason, subsessionName } = payload;
				console.log('Payload Status', payload);
				if (reason === ReconnectReason.Failover) {
					setLoadingText('Session Disconnected,Try to reconnect');
				} else if (
					reason === ReconnectReason.JoinSubsession ||
					reason === ReconnectReason.MoveToSubsession
				) {
					setLoadingText(`Joining ${subsessionName}...`);
				} else if (reason === ReconnectReason.BackToMainSession) {
					setLoadingText('Returning to Main Session...');
				}
			} else if (payload.state === ConnectionState.Connected) {
				setStatus('connected');
				if (isFailover) {
					setIsLoading(false);
				}
				window.zmClient = zmClient;
				window.mediaStream = zmClient.getMediaStream();
			} else if (payload.state === ConnectionState.Closed) {
				setStatus('closed');
				dispatch({ type: 'reset-media' });
				if (payload.reason === 'ended by host') {
					Modal.warning({
						title: 'Meeting ended',
						content: 'This meeting has been ended by host',
					});
				}
			}
		},
		[isFailover, zmClient]
	);
	const onMediaSDKChange = useCallback((payload) => {
		const { action, type, result } = payload;
		if (type === 'audio' && result === 'success') {
			if (action === 'encode') {
				setAudioEncode(true);
			} else if (action === 'decode') {
				setAudioDecode(true);
			}
		}

		dispatch({ type: `${type}-${action}`, payload: result === 'success' });
	}, []);

	useEffect(() => {
		if (audioDecode && audioEncode) {
			var isSafari = window['safari'] !== undefined;
			if (isSafari) {
				if (audioEncode && audioDecode) {
					mediaStream.startAudio();
				} else {
					console.log('Safari audio encoding and decoding have not finished');
				}
			} else {
				mediaStream.startAudio();
			}
		}
	}, [audioDecode, audioEncode]);

	const onDialoutChange = useCallback((payload) => {
		console.log('onDialoutChange', payload);
	}, []);

	const onAudioMerged = useCallback((payload) => {
		console.log('onAudioMerged', payload);
	}, []);

	// const onLeaveOrJoinSession = useCallback(async () => {
	//   if (status === 'closed') {
	//     setIsLoading(true);
	//     await zmClient.join(topic, signature, name, password);
	//     setIsLoading(false);
	//   } else if (status === 'connected') {
	//     await zmClient.leave();
	//     message.warn('You have left the session.');
	//   }
	// }, [zmClient, status, topic, signature, name, password]);
	useEffect(() => {
		zmClient.on('connection-change', onConnectionChange);
		zmClient.on('media-sdk-change', onMediaSDKChange);
		zmClient.on('dialout-state-change', onDialoutChange);
		zmClient.on('merged-audio', onAudioMerged);
		// zmClient.on('user-added', getUsers);

		return () => {
			zmClient.off('connection-change', onConnectionChange);
			zmClient.off('media-sdk-change', onMediaSDKChange);
			zmClient.off('dialout-state-change', onDialoutChange);
			zmClient.off('merged-audio', onAudioMerged);
			// zmClient.off('user-added', getUsers);
		};
	}, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

	// const getUsers = async (payload: any) => {
	//   const users = await zmClient.getAllUser();
	//   console.log("participants: ",participants)
	//     const fparticipants = _.reverse(_.uniqBy(_.reverse([...users]), 'userIdentity'));
	//     console.log("fparticipants: ",fparticipants)
	//   setUsers(fparticipants)
	// }
	return (
		<div className="App">
			{loading && <LoadingLayer content={loadingText} />}
			{!loading && (
				<ZoomMediaContext.Provider value={mediaContext}>
					<ChatContext.Provider value={chatClient}>
						<VideoSingle />
						{/* {participants.length <= 2 ? <VideoSingle/> : isSupportGalleryView ? <Video/> : galleryViewWithoutSAB ? <VideoNonSAB/> : <VideoSingle/>} */}
					</ChatContext.Provider>
				</ZoomMediaContext.Provider>
			)}
		</div>
	);
}

export default App;
