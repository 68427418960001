export const MALE_PLACEHOLDER = require(`assets/images/male.png`);
export const FEMALE_PLACEHOLDER = require(`assets/images/female.png`);
export const NEUTRAL_PLACEHOLDER = require(`assets/images/neutral.png`);
import { ASSET_ENDPOINT } from 'settings/constants';
import { CURRENCIES } from 'typings/enums/users';

//https://stackoverflow.com/questions/18681788/how-to-get-a-youtube-thumbnail-from-a-youtube-iframe
//https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
//http://jsfiddle.net/soboaz/rK8c4/
export const getYoutubeVideoId = (videoId: string) => {
	const rx =
		/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/; //eslint-disable-line

	let id = videoId?.match(rx);
	return id?.[1]
		? `//img.youtube.com/vi/${id?.[1]}/0.jpg`
		: "//img.youtube.com/vi/'+youtube_video_id+'/0.jpg";
};

export const AboutPageRequiredFields = [
	'firstName',
	'lastName',
	'email',
	'phone',
	'images',
	'dob',
	'pronoun',
	'school',
	'cohort',
	// 'anonymousOrganization',
	// 'clientRole',
];

export const MatchingPageRequiredFields = [
	'capacity',
	'supervision',
	'mentoring',
	'matchingInterested',
	'languages',
];

export const ExperiencePageRequiredFields = [
	'sectorExperience',
	'seniorExperience',
	'experienceInYears',
	'expectedAreas',
	//'bio',
	'timezone',
];

export const checkprofileCompleted = (currentPortal: string, user: any, returnTotal?: boolean) => {
	let AboutPageFields = AboutPageRequiredFields;

	let MatchingPageFields = MatchingPageRequiredFields;

	let ExperiencePageFields = ExperiencePageRequiredFields;

	if (currentPortal === 'coachee') {
		delete AboutPageFields[3];
		delete AboutPageFields[4];
		delete AboutPageFields[5];
		delete AboutPageFields[6];
		delete AboutPageFields[7];
		delete AboutPageFields[8];

		delete MatchingPageFields[0];
		delete MatchingPageFields[1];
		delete MatchingPageFields[2];

		//delete ExperiencePageRequiredFields[4];
	} else {
		delete AboutPageFields[3];
		delete AboutPageFields[4];
		delete AboutPageFields[5];
		delete AboutPageFields[6];

		delete MatchingPageFields[1];
		delete MatchingPageFields[2];
		delete MatchingPageFields[3];
	}

	let AboutPagePercentage: any = AboutPageFields?.filter(
		(x: any) => !(user[x] || user?.meta[x])
	)?.length;

	let MatchingPagePercentage: any = MatchingPageFields?.filter(
		(x: any) =>
			user?.preference?.[currentPortal]?.[x] === null ||
			user?.preference?.[currentPortal]?.[x] === undefined ||
			user?.preference?.[currentPortal]?.[x] === ''
	)?.length;

	let ExperiencePagePercentage = ExperiencePageFields?.filter(
		(x: any) =>
			user?.preference?.[currentPortal]?.[x] === null ||
			user?.preference?.[currentPortal]?.[x] === undefined ||
			user?.preference?.[currentPortal]?.[x] === ''
	)?.length;

	let total = AboutPagePercentage + MatchingPagePercentage + ExperiencePagePercentage;

	if (returnTotal) {
		return total;
	}
	if (currentPortal === 'coach') {
		// Coach
		if (user?.meta?.schoolCodeVerified && total === 0) {
			return true;
		}
		return false;
	} else {
		return total === 0;
	}
};

export const findPercentage = (
	currentPortal: string,
	user: any,
	fullProfileCompletionCalculation = false
) => {
	let AboutPageFields = AboutPageRequiredFields;

	let MatchingPageFields = MatchingPageRequiredFields;

	let ExperiencePageFields = ExperiencePageRequiredFields;

	// fullProfileCompletionCalculation is true calcuation for full profile completion progress bar else profile completed calculation
	if (fullProfileCompletionCalculation) {
		AboutPageFields = [
			'firstName',
			'lastName',
			'email',
			'phone',
			'images',
			'dob',
			'pronoun',
			'school',
			'cohort',
			// 'anonymousOrganization',
			// 'clientRole',
		];

		MatchingPageFields = [
			'capacity',
			'supervision',
			'mentoring',
			'matchingInterested',
			'languages',
		];

		ExperiencePageFields = [
			'sectorExperience',
			'seniorExperience',
			'experienceInYears',
			'expectedAreas',
			//'bio',
			'timezone',
		];

		if (currentPortal === 'coachee') {
			const AboutFieldsToRemove = ['school', 'cohort'];

			AboutPageFields = AboutPageRequiredFields.filter(
				(field) => !AboutFieldsToRemove.includes(field)
			);

			const MatchingFieldsToRemove = [
				'capacity',
				'supervision',
				'mentoring',
				'matchingInterested',
			];

			MatchingPageFields = MatchingPageFields.filter(
				(field) => !MatchingFieldsToRemove.includes(field)
			);
		}
	} else {
		if (currentPortal === 'coachee') {
			const AboutFieldsToRemove = ['phone', 'images', 'dob', 'pronoun', 'school', 'cohort'];

			AboutPageFields = AboutPageRequiredFields.filter(
				(field) => !AboutFieldsToRemove.includes(field)
			);

			const MatchingFieldsToRemove = [
				'capacity',
				'supervision',
				'mentoring',
				'matchingInterested',
			];

			MatchingPageFields = MatchingPageFields.filter(
				(field) => !MatchingFieldsToRemove.includes(field)
			);
		} else {
			const AboutFieldsToRemove = ['phone', 'images', 'dob', 'pronoun'];

			AboutPageFields = AboutPageRequiredFields.filter(
				(field) => !AboutFieldsToRemove.includes(field)
			);

			const MatchingFieldsToRemove = ['supervision', 'mentoring', 'matchingInterested'];

			MatchingPageFields = MatchingPageFields.filter(
				(field) => !MatchingFieldsToRemove.includes(field)
			);
		}
	}

	let AboutPagePercentage: any = AboutPageFields?.filter(
		(x: any) => user[x] || user?.meta[x]
	)?.length;

	AboutPagePercentage = (AboutPagePercentage / AboutPageFields?.length) * 100;

	if (
		(currentPortal === 'coachee' && user?.organizationVerticalCodes?.length > 0) ||
		(currentPortal === 'coach' && user?.meta?.schoolCodeVerified)
	) {
		let MatchingPagePercentage: any = MatchingPageFields?.filter(
			(x: any) =>
				user?.preference?.[currentPortal]?.[x]?.toString() === '0' ||
				user?.preference?.[currentPortal]?.[x]
		)?.filter((x: any) => x)?.length;

		MatchingPagePercentage = (MatchingPagePercentage / MatchingPageFields?.length) * 100;

		let ExperiencePagePercentage = ExperiencePageFields?.filter(
			(x: any) => user?.preference?.[currentPortal]?.[x]
		)?.filter((x: any) => x)?.length;

		ExperiencePagePercentage = (ExperiencePagePercentage / ExperiencePageFields?.length) * 100;

		let total = (AboutPagePercentage + MatchingPagePercentage + ExperiencePagePercentage) / 3;

		return total;
	}

	return AboutPagePercentage;
};

export const profilePlaceholder = (user: any, isMessage = false) => {
	if (isMessage) {
		return user?.profileImage
			? ASSET_ENDPOINT + user?.profileImage
			: user?.meta?.pronoun === 'Female'
			? FEMALE_PLACEHOLDER
			: user?.meta?.pronoun === 'They'
			? NEUTRAL_PLACEHOLDER
			: MALE_PLACEHOLDER;
	}

	return user?.images?.[0] || user?.images?.fileName
		? `${ASSET_ENDPOINT}${user?.images?.[0]}`
		: user?.meta?.pronoun === 'Female'
		? FEMALE_PLACEHOLDER
		: user?.meta?.pronoun === 'They'
		? NEUTRAL_PLACEHOLDER
		: MALE_PLACEHOLDER;
};

export const PriceFormat = (currentType: any, price?: any) => {
	let symbol = currentType === CURRENCIES?.DOLLAR ? '$' : '£';
	// TODO ugly solution
	let afterDecimal = String(price)?.split('.')?.[1];
	if (afterDecimal?.length == 1) {
		price = `${price}0`;
	}
	return `${symbol}${price ? price : 0}`;
};

export const findOriginalPrice = (percentage: any, discountedPrice?: any) => {
	const discountPercentage = percentage / 100;
	const originalPrice = discountedPrice / (1 - discountPercentage);
	return originalPrice;
};

export const findDiscountPrice = (percentage = 15, originalPrice?: any) => {
	const discountAmount = originalPrice * (percentage / 100);
	const discountedPrice = originalPrice - discountAmount;
	return discountedPrice;
};

export const capitalizeFirstLetter = (str: string) => {
	return str ? str?.charAt(0)?.toUpperCase() + str?.slice(1) : '';
};

export const phoneNumberFormat = (countryCode: string, phone: string) => {
	let phoneNumber = phone;

	if (countryCode) {
		if (phoneNumber?.indexOf('+') === 0) {
			phoneNumber = phoneNumber?.replace('+', '');
			let newphone = phoneNumber?.trim()?.replace(new RegExp(`^${countryCode}`), '');
			phoneNumber = `${countryCode} ${newphone}`;
		} else {
			let newphone =
				phoneNumber?.indexOf(countryCode) === -1
					? phone?.replace(new RegExp(`^\\${countryCode}`), '')
					: phone;

			phoneNumber = `${countryCode} ${newphone}`;
		}
		// let newphone =
		// 	phoneNumber?.indexOf(countryCode) === -1
		// 		? phone?.replace(new RegExp(`^\\${countryCode}`), '')
		// 		: phone;

		// phoneNumber = `${countryCode} ${newphone}`;
	}

	return phoneNumber?.indexOf('+') === -1 ? `+${phoneNumber}` : phoneNumber;
};
