import { encode } from "querystring";
import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { urlQueryToObj } from "utils/buildQuery";

export const useLocationQuery = () => {
  const { search } = useLocation();

  return urlQueryToObj(search.toString());
};

export const useLocationQueryValue = (key: string) => {
  return useLocationQuery()[key];
};

export const useLocationQuerySet = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useCallback(
    (query?: { [key: string]: any }) => {
      navigate(`${pathname}${query && `?${encode(query)}`}`);
    },
    [pathname, navigate]
  );
};

export const useLocationQueryAdd = () => {
  const obj = useLocationQuery();
  const setQuery = useLocationQuerySet();
  return useCallback(
    (key: string, value?: string | string[]) => {
      if (value) {
        obj[key] = value;
      } else {
        delete obj[key];
      }
      setQuery(obj);
    },
    [obj, setQuery]
  );
};
