import * as React from 'react';

function NotificationsIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99997 0C4.68626 0 1.99997 2.68629 1.99997 6C1.99997 7.88663 1.54624 9.6651 0.742604 11.2343C0.635913 11.4426 0.632601 11.6888 0.733648 11.9C0.834695 12.1111 1.02851 12.2629 1.25769 12.3105C2.32537 12.5322 3.41181 12.7023 4.51426 12.818C4.67494 14.602 6.17421 16 8 16C9.82579 16 11.3251 14.602 11.4857 12.818C12.5882 12.7023 13.6746 12.5322 14.7422 12.3105C14.9714 12.2629 15.1652 12.1111 15.2663 11.9C15.3673 11.6888 15.364 11.4426 15.2573 11.2343C14.4537 9.6651 14 7.88663 14 6C14 2.68629 11.3137 0 7.99997 0ZM6.0493 12.9433C6.69477 12.9809 7.34517 13 7.99997 13C8.65478 13 9.3052 12.9809 9.9507 12.9433C9.74903 13.8345 8.95223 14.5 8 14.5C7.04777 14.5 6.25097 13.8345 6.0493 12.9433Z"
				fill="#111827"
			/>
		</svg>
	);
}

export const MemoNotificationsIcon = React.memo(NotificationsIcon);
export default MemoNotificationsIcon;
