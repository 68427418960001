import { themeGet } from '@styled-system/theme-get';
import { Box, BoxProps } from 'atoms';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.input`
	z-index: -1;
	position: absolute;
	opacity: 0;
`;

export interface UploadAreaProps extends BoxProps {
	isMultiple?: boolean;
	onFileSelect?: (files: any) => void;
	accept?: string;
	label?: string;
	title?: string;
	Icon?: any;
	disabled?: boolean;
}

const StyledLabel = styled.label<UploadAreaProps>`
	cursor: pointer;
	${(props) => {
		return css`
			font-family: ${themeGet('fontFamily.regular', 'Inter-Regular')(props)};
			font-weight: '500';
			font-size: ${themeGet('fontSize.h2', '1.4rem')(props)};
			color: ${themeGet('colors.primary.50', 'black')(props)};
		`;
	}};
`;

export const UploadArea: React.FC<UploadAreaProps> = ({
	isMultiple = false,
	title = '+ Upload',
	onFileSelect,
	accept = 'image/*,.pdf',
	label = 'file-upload',
	disabled = false,
	Icon,
}: UploadAreaProps) => {
	return (
		<Box textAlign="center" p="10px">
			<StyledButton
				type="file"
				id={label}
				multiple={isMultiple}
				onChange={(e: any) => {
					onFileSelect && onFileSelect(e.target.files);
				}}
				accept={accept}
				disabled={disabled}
			/>
			<StyledLabel htmlFor={label}>{!!Icon ? Icon : title}</StyledLabel>
		</Box>
	);
};
