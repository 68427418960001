import * as React from 'react';

function ListIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M5.666 4.25h9.208M5.666 8.5h9.208M5.666 12.75h9.208M2.125 4.25h.007M2.125 8.5h.007M2.125 12.75h.007"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoListIcon = React.memo(ListIcon);
export default MemoListIcon;
