import MemoEyeCloseIcon from 'assets/icons/EyeCloseIcon';
import MemoEyeOpenIcon from 'assets/icons/EyeOpenIcon';
import { Flex } from 'atoms/Flex';
import { Text } from 'atoms/Text';
import {
	DetailedHTMLProps,
	forwardRef,
	InputHTMLAttributes,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import styled from 'styled-components';
import {
	border,
	BorderProps,
	color,
	ColorProps,
	layout,
	LayoutProps,
	position,
	PositionProps,
	shadow,
	ShadowProps,
	space,
	SpaceProps,
	typography,
	TypographyProps,
	variant,
} from 'styled-system';
import { Merge } from 'typings/utils';
import mergeRefs from 'utils/mergeRefs';

type Variant = 'primary' | 'secondary' | 'error';

interface InputBProps
	extends SpaceProps,
		ColorProps,
		LayoutProps,
		PositionProps,
		TypographyProps,
		BorderProps,
		ShadowProps {
	variant?: Variant;
	maxLength?: any;
	value?: any;
	autoFocus?: boolean;
	defaultValue?: string;
	height?: any;
	width?: any;
	size?: any;
	color?: string;
	placeholder?: string;
	rows?: string;
	cols?: string;
}

export type InputBaseProps = Merge<
	Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>,
	InputBProps
>;

export const InputBase = styled.input<Omit<InputBaseProps, 'placeholder'>>`
	width: 100%;
	${variant({
		variants: {
			primary: {
				p: '7.8px 8px',
				border: '1px solid',
				borderColor: 'grey.100',
				outline: 'none',
				color: 'primary.50',
				fontFamily: 'medium',
				borderRadius: '6px',
				'&:focus, &:active': {
					bg: 'white.0',
				},
				'&::placeholder': {
					color: 'grey.100',
				},
			},
			secondary: {
				display: 'block',
				border: 'none',
				borderBottom: '1px solid',
				borderColor: 'gray.500',
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
			},
			error: {
				display: 'block',
				color: 'red.300',
				border: 'none',
				borderBottom: '1px solid',
				borderColor: 'gray.500',
			},
		},
	})};

	${space}
	${layout}
  ${color}
  ${position}
  ${border}
  ${shadow}
  ${typography}
`;

export const Input = forwardRef<HTMLInputElement, InputBaseProps>(
	({ autoFocus, variant: v = 'primary', type, onChange, ...props }, ref) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const [isOpen, setOpen] = useState(false);
		const [toggleVisible, setToggleVisible] = useState(false);

		const toggle = useCallback(() => setOpen((prev) => !prev), []);

		const isPassword = useMemo(() => type === 'password', [type]);

		const onChangeHandler = useCallback(
			(e) => {
				if (typeof onChange === 'function') {
					onChange(e);
				}
				setToggleVisible(e?.currentTarget?.value?.length > 0);
			},
			[onChange]
		);
		return (
			<>
				{isPassword && toggleVisible && (
					<Flex
						onClick={toggle}
						position="absolute"
						top={'5%'}
						zIndex={10}
						right={0}
						cursor="pointer"
						alignItems="center"
						height="100%"
						width="4.5rem"
						justifyContent="center"
					>
						<Text as="span">{isOpen ? <MemoEyeCloseIcon /> : <MemoEyeOpenIcon />}</Text>
					</Flex>
				)}
				<InputBase
					type={isPassword && isOpen ? 'text' : type}
					variant={v}
					ref={mergeRefs(ref, inputRef)}
					fontFamily="regular"
					onChange={onChangeHandler}
					{...props}
					pr={isPassword ? '4.5rem' : undefined}
				/>
			</>
		);
	}
);
