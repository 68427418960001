import { Flex, StyledNavLink, Text } from "atoms";

export const Page403 = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text as="h1" fontWeight="100" fontSize="7rem" color="primary.600">
        403 :(
      </Text>
      <Text as="h2">
        Oops!!! The Page you are trying to access is not authorized to be
        accessed by you.
      </Text>
      <StyledNavLink to="/">
        <Text as="p" fontSize="h1" color="secondary.0">
          ← Go back to Home Page
        </Text>
      </StyledNavLink>
    </Flex>
  );
};
