import { FC } from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';

const NavLink = styled(Link)<NavLinkProps>(
	css({
		textDecoration: 'none',
		color: 'secondary.50',
		'&.active': {
			color: 'secondary.50',
		},
	})
);

export type StyledNavLinkProps = {} & NavLinkProps;

export const StyledNavLink: FC<StyledNavLinkProps> = ({
	children,
	...rest
}) => {
	return <NavLink {...rest}>{children}</NavLink>;
};
