import React from 'react';
import { Button, Icon} from 'atoms';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';

interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isHost } = props;

  // return isHost ? (
  //   <DropdownButton
  //     className="vc-dropdown-button"
  //     size="large"
  //     menu={getAntdDropdownMenu([getAntdItem('End session', 'end')], onEndClick)}
  //     trigger={['click']}
  //     type="ghost"
  //     onClick={onLeaveClick}
  //     icon={<UpOutlined />}
  //     placement="topRight"
  //   >
  //     <IconFont type="icon-leave" />
  //   </DropdownButton>
  // ) : (
  //   <Button
  //     className={classNames('vc-button')}
  //     icon={<IconFont type="icon-leave" />}
  //     // eslint-disable-next-line react/jsx-boolean-value
  //     ghost={true}
  //     shape="circle"
  //     size="large"
  //     onClick={onLeaveClick}
  //     title="Leave session"
  //   />
  // );
  return (
    <Tooltip title={`${isHost?'End Call':'Leave Call'}`} color="#ffffff55">
      <Button 
        mx="8px"
        display="flex"
        borderRadius="24px"
        alignItems="center"
        justifyContent="center"
        width="70px !important"
        height="50px !important"
        variant="danger"
        onClick={() => {
          if (isHost) onEndClick()
          else onLeaveClick()
        }}
        >
          <Icon 
          name="phone" 
          width="24px"
          height="24px"
          style={{transform: "rotate(135deg)" }}
          // type={isStartedVideo?'on':'off'} 
          fill="#ffffff" />
      </Button>
    </Tooltip>
  )
};

export { LeaveButton };
