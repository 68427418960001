import { Box, Button, StyledNavLink, Text } from 'atoms';
import { Form, Formik } from 'formik';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import { FormInput } from 'molecules/FormInput';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import * as yup from 'yup';
import useAuth from '../context/Authentication';

const VerifyAdminValidationSchema = yup.object().shape({
	otp: yup
		.string()
		.required()
		.matches(/^[-+]?\d+$/, 'Enter valid OTP'),
});

export type VerifyAdminProps = {};

export const VerifyAdmin: FC<VerifyAdminProps> = () => {
	const {
		actions: { verifyOTP, resendOTP, switchProtal },
	} = useAuth();

	const navigate = useNavigate();

	const onSubmit = useCallback(
		(values) => {
			verifyOTP({ otp: parseInt(values?.otp) }, () => {
				switchProtal('admin');
				navigate('/admin/coaches'); //Move to admin coupons.
				return false;
			});
		},
		[verifyOTP, navigate, switchProtal]
	);

	const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

	return (
		<Box px="16rem">
			<Text
				fontSize="h2"
				lineHeight="h2"
				color="primary.50"
				fontFamily="Archivo-Bold"
				my="0.7rem"
				mb="2.4rem"
			>
				Two-Step Verification
			</Text>

			<Text fontSize="p1" lineHeight="p1" mb="2rem">
				To get it into the the platform please enter the code sent to{' '}
				<Text as="span" color="secondary.50" fontFamily="semibold">
					adminmcp@example.com
				</Text>
			</Text>

			<Formik
				initialValues={{ otp: '' }}
				onSubmit={(formValues) => {
					onSubmitHandler({
						...formValues,
						otp: formValues.otp.trim().toLowerCase(),
					});
				}}
				validationSchema={VerifyAdminValidationSchema}
			>
				{() => (
					<Form>
						<Box mt="3rem">
							<FormInput
								label="Verification Code"
								placeholder={'Enter Code'}
								name="otp"
								mb="3rem"
							/>
							<Button
								width="100%"
								loading={loading}
								mt="2.5rem"
								mb="5.6rem"
								height="41px"
								fontSize="1.6rem"
							>
								Continue
							</Button>
						</Box>

						<Box textAlign="center">
							<Text fontSize="p1" fontFamily="medium">
								Didn’t get the code?{' '}
								<StyledNavLink
									to="#"
									onClick={() => {
										resendOTP();
									}}
								>
									<Text as="span" fontFamily="semibold">
										Click Here
									</Text>
								</StyledNavLink>
							</Text>
						</Box>
						<Box textAlign="center" mt="1.5rem" onClick={() => navigate('/login')}>
							<Text fontSize="p1" fontFamily="medium">
								Go back to{' '}
								<StyledNavLink to="/login">
									<Text as="span" fontFamily="semibold">
										Sign In
									</Text>
								</StyledNavLink>
							</Text>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};
