import { Checkbox as AntdCheckbox } from 'antd';
import { InputFieldBase, Text } from 'atoms';
import React from 'react';
import styled from 'styled-components';
// import { themeGet } from '@styled-system/theme-get';

const StyledAntdCheckbox = styled(AntdCheckbox)<RadioBtnGrpProps>`
	&.ant-checkbox-group {
		display: grid !important;
	}
	.ant-checkbox-group-item {
		width: 100%;
	}
`;

type ItemBtnType = {
	value: any;
	label: string;
};

export interface RadioBtnGrpProps {
	label?: string;
	labelHide?: boolean;
	selectedValue?: any;
	items?: Array<ItemBtnType>;
	onChange?: (value: any) => void;
	disabled?: boolean;
}

export const CheckboxGroup: React.FC<RadioBtnGrpProps> = ({
	label,
	items = [],
	selectedValue,
	onChange,
	disabled = false,
	...props
}: RadioBtnGrpProps) => {
	return (
		<InputFieldBase>
			{label && (
				<Text
					textTransform="uppercase"
					as="label"
					fontSize="h3"
					color="primary.50"
					fontFamily="medium"
				>
					{label}
				</Text>
			)}
			<StyledAntdCheckbox.Group
				value={selectedValue || undefined}
				onChange={({ target }: any) => onChange && onChange(target.value)}
				disabled={disabled}
				options={items}
			></StyledAntdCheckbox.Group>
		</InputFieldBase>
	);
};
