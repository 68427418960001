import css from "@styled-system/css";
import {
  AutoComplete as AntAutoComplete,
  AutoCompleteProps as AntAutoCompleteProps,
} from "antd";
import React from "react";
import styled from "styled-components";
import "./index.css";

export type OptionType = {
  label: string;
  value: string | number;
};

export interface AutoCompleteProps extends AntAutoCompleteProps {
  selectedOption?: (val: any, option: OptionType) => void;
  error?: any;
  label?: any;
}

const StyledAntAutoComplete = styled(AntAutoComplete)<AutoCompleteProps & any>`
  background-color: ${(props) => (props.error ? "#FFF1F1" : "#fcfcfc")};
  ${css({
    width: "100%",
    borderRadius: "5px !important",

    fontFamily: "Inter-Medium",
    ".ant-select-focused": {
      boxShadow: "none !important",
    },
    ".ant-select-auto-complete": {},
    "&:hover": {
      borderColor: "1px solid #C8C8C8  !important",
    },
    ".ant-select-selector": {
      border: "none !important",
      borderRadius: "14px",
      height: "46px !important",
      background: "transparent !important",
    },
    ".ant-select-selection-search": {
      height: "46px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    ".ant-select-selection-search-input": {
      height: "46px !important",
    },
    ".ant-select-selection-placeholder": {
      height: "46px !important",
      display: "flex !important",
      alignItems: "center !important",
      fontSize: "1.25rem",
    },
  })}
`;
export const AutoComplete = (props: AutoCompleteProps) => {
  const fieldRef: any = React.useRef();

  if (props?.defaultValue) {
    // let selected = props?.options?.find(
    // 	(item) => item?.key === props?.defaultValue
    // );
    // console.log('yes selected', selected);
    // console.log('fieldRef fieldRef', fieldRef?.current);
  }

  return (
    <StyledAntAutoComplete
      dropdownClassName="custom-autocomplete-dropdown"
      options={props.options}
      placeholder={props.placeholder}
      filterOption={(inputValue: string, option: any) =>
        option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      ref={fieldRef}
      onSelect={(val: string, option: OptionType) =>
        props.selectedOption && props.selectedOption(val, option)
      }
      {...props}
    />
  );
};
