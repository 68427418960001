import { themeGet } from '@styled-system/theme-get';
import { Box, BoxProps, Flex } from 'atoms';
import React from 'react';
import styled, { css } from 'styled-components';

import './Progress.css';

const StyledBox = styled(Box)<{ percentage: any }>`
	width: 100%;
	//border: 1px solid #dadada;
	padding: 0.25rem 0.8rem;
	&:after {
		width: ${(props) => props.percentage}%;
	}
	${(props) => {
		return css`
			font-family: ${themeGet('fontFamily.regular', 'Poppins-Regular')(props)};
			font-weight: 500;
			font-size: ${themeGet('fontSize.h2', '1.4rem')(props)};
			color: ${themeGet('colors.primary.50', 'black')(props)};
		`;
	}};
`;

interface UploadButtonProps extends BoxProps {
	text?: string;
	type?: number;
	percentage?: any;
	className?: string;
	height?: string;
}

export const ProgressBar: React.FC<UploadButtonProps> = ({
	text,
	type = 1,
	percentage = '0',
	className,
	height = '5px',
}: UploadButtonProps) => {
	return (
		<StyledBox
			className={`customprogress ${className}`}
			bg="#D1D5DB"
			px="1.6rem"
			py="1.4rem"
			borderRadius="10px"
			percentage={percentage}
			height={height}
		>
			<Flex
				zIndex="1"
				color="black.0"
				justifyContent={type === 1 ? 'space-between' : 'flex-end'}
			>
				{/* {type === 1 && <Text>{text}</Text>} */}
				{/* <Text fontFamily="semibold">{percentage}%</Text> */}
			</Flex>
		</StyledBox>
	);
};
