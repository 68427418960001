import css from '@styled-system/css';
import { Select as AntSelect, SelectProps as AntSelectProps, Tooltip } from 'antd';
import { Box, InputFieldBase, Text } from 'atoms';
import { Error } from 'atoms/Error';
import { Warning } from 'atoms/Warning';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
const { Option } = AntSelect;

export type OptionType = {
	label: string;
	value: string | number;
	toolTipText?: string;
};

export interface SelectProps extends AntSelectProps<string | number>, SpaceProps {
	options?: Array<OptionType>;
	label?: any;
	error?: string;
	warning?: string;
	labelHide?: boolean;
	customStyles?: string;
	minHeight?: string;
	showOptional?: boolean;
	showOptionalText?: string;
	wrapOptionalText?: boolean;
	bordercolor?: string;
	mb?: string;
	value?: any;
	sortRequired?: boolean;
}

function trimString(string: string) {
	const length = 80;
	return string?.length > length ? string?.substring(0, length - 3) + '...' : string;
}

const StyledSelect = styled(AntSelect)<SelectProps & any>`
	.ant-select-selector {
		background-color: ${(props) => (props.error ? '#FFF1F1 !important' : '#FFF !important')};
		border-color: ${(props) =>
			props.bordercolor
				? props.bordercolor
				: props.error
				? '#9B3634 !important'
				: '#9CA3AF !important'};
		box-shadow: none !important;
		font-family: 'Inter-Regular';
	}
	:where(.css-dev-only-do-not-override-diro6f).ant-select-single
		.ant-select-selector
		.ant-select-selection-placeholder {
		line-height: 39px;
	}
	.ant-select-selection-placeholder {
		line-height: 39px !important;
		color: #9ca3af !important;
	}

	&.ant-select-single .ant-select-selector .ant-select-selection-item {
		//font-weight: 600 !important;
		//font-family: Inter-Medium !important;
		//height: 30px !important;
		color: #374151 !important;
		line-height: 41px !important;
		font-size: 16px !important;
	}
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		//padding: 5px 13px !important;
	}

	&.ant-select-multiple .ant-select-selection-item {
		background: #4bb7ba !important;
		border-radius: 11px !important;
		font-size: 12px !important;
		color: #fff !important;
		font-family: Inter-SemiBold !important;
	}

	@media only screen and (max-width: 768px) and (min-width: 320px) {
		&.ant-select-single .ant-select-selector .ant-select-selection-item {
			line-height: 30px !important;
			padding-top: 5px;
		}
	}

	${css({
		width: '100%',
		'.ant-select-selector': {
			minHeight: '41px !important',
			borderRadius: '5px !important',
			border: '1px solid',
		},
		'&:hover': {
			borderColor: '#dee4e5 !important',
		},
		'.ant-select-item-option-active': {},
		'.ant-select-item-option-selected': {
			backgroundColor: `#FCFCFC !important`,
		},
		'.ant-select-selection-item-content': {
			fontSize: '12px',
		},
		'.ant-select-selection-placeholder': {
			fontSize: '16px',
			color: 'primary.150',
		},
		'.ant-select-selection-search-input': {
			height: '41px !important',
		},
	})}
`;

export const Select: React.FC<SelectProps> = ({
	options = [{ label: 'Name', value: 'value' }],
	label,
	labelHide = false,
	error,
	warning,
	customStyles,
	minHeight,
	showOptional,
	showOptionalText,
	wrapOptionalText = true,
	mb,
	sortRequired = true,
	...props
}: SelectProps) => {
	let sortedOptions = sortRequired
		? options?.sort((a, b) => a.label.localeCompare(b.label))
		: options;
	return (
		<InputFieldBase
			width="100%"
			position="relative"
			className={customStyles}
			mb={mb || '1.6rem'}
		>
			{!labelHide && (
				<Box
					display={{ xs: wrapOptionalText ? 'block' : 'flex', sm: 'flex' }}
					justifyContent={'space-between'}
				>
					<Text as="label" fontSize="16px" color="primary.50" fontFamily="semibold">
						{label}
					</Text>
					{showOptional && <Text>{showOptionalText || 'Optional'}</Text>}
				</Box>
			)}

			<StyledSelect
				getPopupContainer={(trigger: any) => trigger.parentNode}
				error={error}
				dropdownStyle={{ fontFamily: 'Inter-Regular' }}
				filterOption={(input: any, option: any) => {
					return (
						option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
					);
				}}
				{...props}
			>
				{sortedOptions.map((x: any, keyval: number) => (
					<Option
						value={`${x?.value}`}
						key={`${keyval}${x?.value}`}
						label={`${x?.label}`}
						disabled={x?.disabled}
					>
						{x?.toolTipText ? (
							<Tooltip title={x?.toolTipText}>
								<span>{trimString(x?.label)}</span>
							</Tooltip>
						) : (
							<span>{trimString(x?.label)}</span>
						)}
					</Option>
				))}
			</StyledSelect>
			{error && <Error text={error} />}
			{warning && <Warning text={warning} />}
		</InputFieldBase>
	);
};
