import { FC } from 'react';

import { Box } from 'atoms';
import RegisterPage from 'templates/RegisterForm/RegisterPage';

import { SchoolProvider } from 'pages/Schools/context/schools';
import { CohortProvider } from 'pages/Cohorts/context/cohorts';

type RegisterProps = {
	type: 'regular' | 'invite';
	userType?: any;
} & any;

export const Register: FC<RegisterProps> = ({ type, userType, ..._props }: RegisterProps) => {
	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<SchoolProvider>
				<CohortProvider>
					<RegisterPage userType={userType} type={type} />
				</CohortProvider>
			</SchoolProvider>
		</Box>
	);
};
