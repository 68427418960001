import { useNavigate } from "react-router-dom";

import "./terms.css";

import MemoLogoBeta from "assets/icons/LogoBeta";
import { Box, Text } from "atoms";

export const TC: React.FC = () => {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/");
  }

  return (
    <Box
      paddingY="50px"
      paddingX="20px"
      className="tc-container"
      color="primary.50"
    >
      <Box textAlign="center" mb="2rem" onClick={goToHome} cursor="pointer">
        {/* <MemoLogo width="15rem" height="2.5rem" /> */}
        <MemoLogoBeta width="15rem" height="2.5rem" />
      </Box>
      <Text
        fontFamily="semibold"
        fontSize="20px"
        color="primary.50"
        textAlign="center"
        marginBottom="30px"
      >
        Terms and Conditions
      </Text>
      <Box paddingX="20px">
        <p>
          Thanks for using our products and services (“Services”). The Services
          are provided by eHuddle Ltd (“Company Name”), registered at Unit 2
          City Limits, Danehill, Reading, Berkshire, England, RG6 4UP (Company
          Number 13239544).
        </p>
        <p>
          By using our Services, you are agreeing to these terms. Please read
          these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully
          before using the www.ehuddle.io website or any of the products or
          services operated by eHuddle (“us, “we”, or “our”).{" "}
        </p>
        <p>
          This agreement, along with our Privacy Policy, constitutes the entire
          agreement (“Agreement”) between the parties and supersedes all
          previous Agreements (written or oral) between each party.{" "}
        </p>
        <p>
          eHuddle is the provider of software, which allows coaches to manage
          their coaching business and client sessions.
        </p>

        <h4 className="tc-sub-title">1. Terminology</h4>
        <p>
          1.1 The following terminology applies to these Terms and Conditions,
          Privacy Statement and any or all Agreements created from time to time:{" "}
        </p>
        <ol className="tc-ol" type="i">
          <li>
            “User”, “You” and “Your” refers to you, the person accessing our
            website and services, and accepting our terms and conditions;{" "}
          </li>
          <li>
            “The Company”, “eHuddle”, “Ourselves”, “We” and “Us” refer to our
            Company;
          </li>
          <li>
            “Party”, “Parties” or “Us” refer to both you and us, or either the
            User or ourselves.
          </li>
        </ol>
        <p>
          1.2 All terms refer to the offer, acceptance and consideration of
          payment necessary to undertake the process of Service Provision to the
          User in the most appropriate manner, with the express purpose of
          meeting user needs in terms of providing the eHuddle declared
          Services, in accordance with and subject to all applicable Laws. Any
          use of the above terminology or other words in the singular, plural,
          non-capital letters are considered interchangeable and therefore a
          reference to them.{" "}
        </p>

        <h4 className="tc-sub-title">2. Using our Services</h4>
        <p>
          2.1 You must follow any policies or guidelines made available to you
          within the Services. You must not misuse our Services. For example,
          don’t interfere with our Services or try to access them using a method
          other than the interface and the instructions we provide. You may use
          our Services only as permitted by law, including regulations. We may
          suspend or stop providing Services to you if you do not comply with
          the Agreement or if we are investigating suspected misconduct.
        </p>
        <p>
          2.2 Using our Services does not give you ownership or any intellectual
          property rights in our Services or the content offered to you by
          eHuddle. You may not use content from our Services unless you obtain
          permission from us as the owner or are otherwise permitted by law, or
          indeed you own the content directly. These terms do not grant you the
          right to use any branding, coaching materials or tools, or logos used
          in our Services. Do not remove, obscure, or alter any legal notices
          displayed in or along with our Services.
        </p>

        <p>
          2.3 In connection with your use of the Services, we may send you
          service messages and other information relating to our system. You may
          opt out of receiving any promotional communications from us, but we
          are legally required to provide you with messages in relation to our
          Services availability and functionality, and any material changes we
          make.{" "}
        </p>
        <p>
          2.4 All Users of the Service must be over 16 years old. If Coaches
          wish to offer sessions to individuals under the age of 16, You are
          solely responsible for obtaining parental/guardian explicit permission
          To Do so. You must retain a copy of this consent, and provide eHuddle
          with a copy of the consent without delay. Please contact the eHuddle
          team before agreeing to coach any persons under 16.{" "}
        </p>

        <h4 className="tc-sub-title">3. Privacy Statement</h4>
        <p>
          3.1 We are committed to protecting your privacy. eHuddle’s privacy
          policy explains how we handle your personal data and protect your
          privacy when you use our Services. By using our Services, you agree
          that eHuddle can use such data in accordance with our privacy policy,
          which you have read and understood.{" "}
        </p>
        <p>
          3.2 We provide the option of using Alias in place of names when
          storing client data on the system. We recommend using Alias to protect
          the identity of the Client against accidental disclosure. The name of
          the client will be available to you in the user management page
        </p>
        <p>
          3.2.1 Only authorised employees within eHuddle who, in the course of
          their duties, can access and process information collected from
          individual Users and are under a duty of confidentiality within their
          terms of employment to handle data securely and confidentially. All
          coaches agree to be held to a duty of confidentiality to the same
          degree and are expected to handle personal data with the utmost
          sensitivity and uphold all data protection regulations.
        </p>
        <p>
          3.3 We are constantly reviewing our system and data to ensure the best
          possible service to our Users. We will investigate any actual or
          suspected specific offences for unauthorised actions, with a view to
          bringing legal action for damages against those responsible.{" "}
        </p>
        <p>
          3.4 Please see our <a href="privacy-policy.html">Privacy Policy</a>for
          more information.{" "}
        </p>

        <h4 className="tc-sub-title">4. Subscriptions</h4>
        <p>
          4.1 Subject to payment by user of the Charges, eHuddle shall, during
          the subscription term (“Subscription Term”), provide you, the Coach,
          with a non-exclusive, non-transferable, non-outsourceable, revocable,
          discretionary, royalty-free licence to permit the authorised use and
          access to the Service for your business requirements; and agree to
          perform support and customer services during designated business hours
          (as determined by us).
        </p>
        <p>
          4.2 Subscription fees and charges are non-cancellable and
          non-refundable. Claims and requests for subscription fee refunds will
          be reviewed on a case by case basis and will depend entirely on the
          specific circumstances
        </p>
        <p>
          4.3 The Service is billed on a subscription basis (“Subscription(s)”).
          You have the option to enter into an Annual Subscription, for which
          you will pay your annual Subscription Charge upfront. You also have
          the option to choose a Monthly subscription, which is a 30 day rolling
          contract. In the event you choose to pay for your subscription
          monthly, you will be billed once on a monthly basis, on or around the
          date of service commencement anniversary each month, and on each
          subsequent month thereafter for the duration of the agreement. For
          more information on our pricing and subscriptions, please visit{" "}
          <a href="privacy-policy.html">pricing</a>
        </p>
        <p>
          4.4 The Charges are exclusive of value added, sales, use or
          withholding, or equivalent taxes in any jurisdiction (“Taxes”), which
          if payable, will be additionally payable by the User at the
          corresponding rate. Users shall be responsible for, and will not
          withhold or deduct, any applicable Taxes on the Charges.
        </p>
        <p>
          4.5.1 In the event that eHuddle is not able to obtain payment of any
          payment from a User, eHuddle will inform the User without delay upon
          becoming aware of the missed payment. eHuddle reserves the right to
          suspend system access until a suitable payment option is made
          available, through which eHuddle can secure Subscription Payments from
          successfully.{" "}
        </p>
        <p>
          4.5.2 Monthly Subscriptions: In the event that eHuddle is not able to
          obtain payment of any monthly subscription charge from a User, eHuddle
          will inform you upon becoming aware of the missed payment, and may
          suspend your access to the system until a suitable payment option is
          made available, through which eHuddle can secure Subscription Payments
          from.{" "}
        </p>
        <p>
          4.6 eHuddle has the right to increase the amount of Charges payable
          for the Service at the start of each Subscription renewal period,
          giving 30 day’s prior written notice to the User.
        </p>

        <h4 className="tc-sub-title">5. Provision of Service</h4>
        <h4 className="tc-sub-title">5.1 Service Availability</h4>
        <p>
          eHuddle shall make all reasonable efforts to make the Services
          available to the expected and acceptable level, except for where:{" "}
        </p>
        <ol className="tc-ol" type="i">
          <li>
            Planned downtime is scheduled for system repair or maintenance;
          </li>
          <li>
            Where downtime is planned, eHuddle have given Users a reasonable
            period of at least 5 day’s notice in advance of system outage;
          </li>
          <li>
            Unscheduled maintenance in the case of actual or anticipated
            emergency;{" "}
          </li>
          <li>
            Unavailability for reasons out of eHuddle’s reasonable and expected
            control.
          </li>
        </ol>
        <p>
          5.2 eHuddle shall, to the extent required for the provision of
          Services under this agreement:{" "}
        </p>
        <ol className="tc-ol" type="i">
          <li>
            {" "}
            Perform the Services substantially in accordance with this agreement
            and with reasonable care and skill;
          </li>
          <li>Comply with all applicable laws, and</li>
          <li>
            Maintain any licences and consents that are needed to Provide the
            Service
          </li>
        </ol>
        <p>
          5.2.1 eHuddle shall make all reasonable efforts to promptly correct
          any material non-conformance of the System. Users are responsible for
          ensuring that the eHuddle Service meet its individual requirements and
          is appropriate for their desired purpose.{" "}
        </p>
        <p>
          5.2.2 eHuddle, in its discretion, may modify the system and Service if
          it does not materially reduce the functionality of the system and may
          provide alternative features so long as they have materially the same
          benefits as previous features.{" "}
        </p>

        <h4 className="tc-sub-title">6. User’s Obligations</h4>

        <p>6.1 Through the use of the Service, You, the User, shall:</p>

        <ol className="tc-ol" type="i">
          <li>
            Cooperate with eHuddle and provide any necessary information, as
            required, to provide the Services to you. This may include the
            creation of your account, the delivery of your Service and to
            communicate with you for any reason relating to your membership to
            the Service;
          </li>
          <li>
            Comply with all laws applicable to this agreement and maintain the
            necessary consents to allow the use of User Data in accordance with
            this agreement, and applicable Data Protection Law and Regulations;
          </li>
          <li>
            Coaches are responsible for ensuring that you keep your passwords
            and username (“Account Details”) safe and secure, and do not share
            them with any unauthorised third parties;
          </li>
          <li>
            Use reasonable efforts to prevent unauthorised access or use of the
            Services
          </li>
        </ol>

        <p>6.2 User shall not:</p>

        <ol className="tc-ol" type="i">
          <li>
            Copy, modify, duplicate, creative derivative works from, frame,
            mirror, republish, download, record, display, transmit or otherwise
            distribute any portion of the system or Service that they do not
            own;
          </li>
          <li>
            Circumvent or disregard any security restrictions imposed by eHuddle
            in an attempt to obtain, assist others in obtaining access to the
            system with unauthorised use or purposes;
          </li>
          <li>
            Use the Service in a way that could reasonable adversely affects the
            system or other users of the Service;
          </li>
          <li>Make the system available to unauthorised third parties;</li>
          <li>
            Conduct what is considered inappropriate, excessive or abusive use
            of the Service, including elements of the Service provided by
            sub-processors on our behalf;
          </li>
          <li>
            Access, store, distribute or transmit any viruses or infectious
            files, or any process or publish material that could be considered
            unlawful, harmful, threatening, defamatory, obscene, infringing,
            harassing, discriminatory or offensive.
          </li>
        </ol>

        <h4 className="tc-sub-title">7. Intellectual Property</h4>

        <p>
          7.1 Nothing in this agreement shall constitute the transfer of any
          intellectual property rights between eHuddle and the User. Users may
          use the system as determined by their membership and the nature of
          their eHuddle account as an end user or coaching personnel, and print
          out any applicable works for coaching purposes, but eHuddle reserves
          all other proprietary rights.
        </p>
        <p>
          7.2 eHuddle shall grant you a non-exclusive, worldwide,
          non-transferable, sub-licensable, fully revocable licence to use the
          system and eHuddles Intellectual Property for the duration of your
          subscription period. You may not otherwise use eHuddles IP, including
          images, coaching material for your own commercial gain.
        </p>

        <p>
          7.3 You, the User, grant eHuddle a non-exclusive licence to use your
          Personal Data for the purposes of fulfilling our contract for services
          with you, as determined by this Agreement.
        </p>

        <h4 className="tc-sub-title">8. Indemnification and Liabilities</h4>

        <p>
          8.1 You agree to defend, indemnify, and hold harmless eHuddle, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents and
          suppliers, from and against any claims, liabilities, damages,
          judgments, awards, losses, costs, expenses, or fees (including
          reasonable legal fees) arising out of or relating to your violation of
          these Terms of Services or your use of the Services, including, but
          not limited to, your User Contributions, any use of the Services’
          content, services, and products other than as expressly authorised in
          these Terms of Services.
        </p>

        <p>
          8.2 IN NO EVENT WILL eHuddle BE LIABLE UNDER OR IN CONNECTION WITH THE
          SERVICES OR THESE TERMS OF SERVICES UNDER ANY LEGAL OR EQUITABLE
          THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY, AND OTHERWISE, FOR ANY: (i) CONSEQUENTIAL,
          INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
          DAMAGES; (ii) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS,
          PRODUCTION, REVENUES, OR PROFITS; (iii) LOSS OF GOODWILL OR
          REPUTATION; (iv) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR
          RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (v)
          COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF
          WHETHER eHuddle WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
          DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO
          EVENT WILL e-Huddle’s AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO
          THE SERVICES OR THESE TERMS OF SERVICES UNDER ANY LEGAL OR EQUITABLE
          THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY, AND OTHERWISE EXCEED THE GREATER OF $1,000 OR THE
          TOTAL AMOUNTS PAID OR PAYABLE TO eHuddle UNDER THESE TERMS OF SERVICES
          DURING THE TWELVE MONTHS PRECEDING THE DATE WHEN THE CLAIM FOR SUCH
          DAMAGES AROSE.
        </p>

        <h4 className="tc-sub-title">9. Agreement Term and Termination</h4>
        <p>
          9.1 This agreement shall become enforceable on the Effective Date and
          continue for an initial 12 month period (annual subscriptions), or 30
          days rolling period (monthly subscription), and for all consecutive
          periods thereafter, until terminated.
        </p>
        <p>
          9.2 If you have subscripted to our Service on an annual basis, and
          have paid all subscription charges upfront upon commencing with
          eHuddle, and you express your wish to or actually terminate this
          Agreement, your access to the system will remain valid until the
          subscription expiry.
        </p>

        <p>
          9.3 Either party may terminate this agreement by giving 30 days notice
          to the other party. Any notice under this agreement shall be in
          writing and shall be delivered by email to eHuddle using the email
          address in section 11. eHuddle retains the right to terminate your
          Subscription at any time, by providing to you written notice to and an
          alert that your Subscription will expire at the end of the relevant
          billing cycle.
        </p>

        <h4 className="tc-sub-title">10. Jurisdiction</h4>

        <p>
          This agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with English law and subject to the exclusive
          jurisdiction of the English courts.{" "}
        </p>

        <h4 className="tc-sub-title">11. Contact Details</h4>

        <p>
          For questions relating to these Terms and Conditions, or any other
          element of our Service of business, please contact us by email at{" "}
          <a href="mailto:info@ehuddle.com">info@ehuddle.com</a> where we will
          be glad to assist you!
        </p>
      </Box>
    </Box>
  );
};
