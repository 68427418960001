import {
  DetailedHTMLProps,
  forwardRef,
  TextareaHTMLAttributes,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { Merge } from "typings/utils";
import mergeRefs from "utils/mergeRefs";

interface InputBProps
  extends SpaceProps,
    LayoutProps,
    PositionProps,
    TypographyProps,
    BorderProps,
    ShadowProps {
  maxLength?: any;
  value?: any;
  autoFocus?: boolean;
  defaultValue?: string;
  height?: any;
  width?: any;
  size?: any;
  placeholder?: string;
}

export type InputBaseProps = Merge<
  Omit<
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "ref"
  >,
  InputBProps
>;

export const TextAreaBase = styled.textarea<
  Omit<InputBaseProps, "placeholder"> & ColorProps
>`
  width: 100%;
  font-family: Inter-Regular;
  padding: 5px;
  color: #0c0442 !important;
  font-size: 14px;
  ${space}
  ${layout}
  	${color}
 	${position}
  	${border}
  	${shadow}
  	${typography}
	&:focus {
    border: 1px solid #ccc !important;
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #b3b3b3;
    font-size: 1.25rem;
  }
  :-ms-input-placeholder {
    color: #b3b3b3;
    font-size: 1.25rem;
  }
`;

export const TextArea = forwardRef<HTMLTextAreaElement, InputBaseProps>(
  ({ onChange, ...props }, ref) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const onChangeHandler = useCallback(
      (e) => {
        if (typeof onChange === "function") {
          onChange(e);
        }
      },
      [onChange]
    );
    return (
      <TextAreaBase
        ref={mergeRefs(ref, inputRef)}
        onChange={onChangeHandler}
        {...props}
      />
    );
  }
);
