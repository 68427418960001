import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import ErrorBackground from 'assets/icons/ToastErrorIcon.svg';
import WarnBackground from 'assets/icons/ToastWarnIcon.svg';
import InfoBackground from 'assets/icons/ToastInfoIcon.svg';
import SuccessBackground from 'assets/icons/ToastSuccessIcon.svg';

export const StyledContainer = styled(ToastContainer).attrs({
	// custom props
})`
	.Toastify__toast {
		background-color: white;
		border-left: 6px solid #18aacc;
		border-radius: 7px;
		background-size: 25px;
		background-position: 15px;
		background-repeat: no-repeat;
		//padding-left: 5.5rem;
		box-shadow: 0px 0px 22px rgba(226, 226, 226, 0.3);
		font-family: 'Inter-Semibold';
		font-size: 14px;
		min-height: auto;
	}
	@media only screen and (max-width: 600px) {
		min-width: 320px;
	}
	@media only screen and (min-width: 600px) {
		min-width: fit-content;
		max-width: 50%;
	}
	.Toastify__toast--info {
		border-left: 6px solid #18aacc;
		background: #18aacc;
		//background-image: url(${InfoBackground});
	}
	.Toastify__toast--error {
		border-left: 6px solid #dc2626;
		background: #dc2626;
		//background-image: url(${ErrorBackground});
	}
	.Toastify__toast--warning {
		border-left: 6px solid #f1b75f;
		background: #f1b75f;
		//background-image: url(${WarnBackground});
	}
	.Toastify__toast--success {
		border-left: 6px solid #059669;
		background: #059669;
		//background-image: url(${SuccessBackground});
	}
	.Toastify__toast-body {
		color: #fff;
	}
	button[aria-label='close'] {
		color: #fff;
		align-self: center;
	}
`;
