import { FC } from 'react';
import ReactModal from 'react-modal';
import './modal.style.css';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		minWidth: '20%',
		maxHeight: '90%',
		maxWidth: '100vw',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0px',
		transform: 'translate(-50%, -50%)',
		border: 0,
		boxShadow: '0px 0px 15px rgba(196, 196, 196, 0.3)',
		borderRadius: '5px',
	},
	overlay: {
		background: 'rgba(0,0,0, 0.5)',
		zIndex: 100,
	},
};

ReactModal.setAppElement('#root');

export type ModalProps = {
	minWidthPct?: number;
	maxWidthPct?: number;
	maxHeightPct?: number;
	zIndex?: number;
	onClose?: () => void;
} & ReactModal.Props;

export const Modal: FC<ModalProps> = ({
	isOpen,
	minWidthPct = 20,
	maxWidthPct,
	maxHeightPct = 90,
	zIndex = 100,
	onClose,
	...rest
}) => {
	customStyles.content.minWidth = minWidthPct + '%';

	if (maxWidthPct) customStyles.content.maxWidth = maxWidthPct + '%';
	customStyles.content.maxHeight = maxHeightPct + '%';
	customStyles.overlay.zIndex = zIndex;

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			style={customStyles}
			shouldCloseOnOverlayClick={false}
			{...rest}
		/>
	);
};
