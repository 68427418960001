import { Text } from 'atoms/Text';
import { ErrorMessage } from 'formik';

interface ErrorProps {
	text: string;
	bottom?: string;
	mb?: string;
}

export const Error = ({ text, mb = '0', bottom = '5%' }: ErrorProps) => {
	return (
		<Text
			as="span"
			color="danger.300"
			//position="absolute"
			//left="5px"
			bottom={bottom}
			pb={mb}
			//minHeight="25px"
			//height="35px"
			fontFamily="semibold"
		>
			{text}
		</Text>
	);
};
export const FormikError = ({ name }: { name: string }) => {
	return <ErrorMessage name={name}>{(error) => <Error text={error} />}</ErrorMessage>;
};
