import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { Box, Grid, Text, Flex, Image, Button, Icon } from 'atoms';
import { Modal } from 'antd';
import CodeVerifyForm from 'templates/CodeVerifyForm';
import { useLocationQuery } from 'hooks/useLocationQuery';
import SubscriptionGroup from 'assets/images/SubscriptionGroup.png';
import { DEFAULT_FREE_CREDIT } from 'typings/enums/users';

import useAuth from '../context/Authentication';
import useSchools from 'pages/Schools/context/schools';

export type CodeVerifyProps = {};

export const CodeVerify: FC<CodeVerifyProps> = () => {
	const navigate = useNavigate();
	const searchParams: any = useLocationQuery();
	const url: any = searchParams.continue;

	const [showSubscriptionModal, setShowSubscriptonModal] = useState<boolean>(false);

	const [schoolData, setSchoolData] = useState<any>(null);

	const {
		state: { currentPortal, user },
		actions: { updateProfile, switchProtal, schoolCodeVerify, getProfileDataUpdateStorage },
	} = useAuth();

	const {
		actions: { getOne },
	} = useSchools();

	// useEffect(() => {
	// 	if (user?.meta?.schoolCodeVerified) {
	// 		navigate(`/${currentPortal}/home`);
	// 	}
	// }, [user, currentPortal, navigate]);

	const redirection = () => {
		const coach = currentPortal === 'coach';
		// Check the highest profile users higher profile is coach.
		if (coach) {
			switchProtal('coach');
			navigate('/coach/home');
		} else {
			switchProtal('coachee');
			navigate('/coachee/home');
		}
	};

	const completeProfile = useCallback(
		(values) => {
			if (values?.skip) {
				// getOne(user?.school, (data) => {
				// 	if (data?.school) {
				// 		setSchoolData(data?.school);
				// 		setTimeout(() => {
				// 			setShowSubscriptonModal(true);
				// 		});
				// 	}
				// });
				setShowSubscriptonModal(true);
				// const coach = currentPortal === 'coach';
				// // Check the highest profile users higher profile is coach.
				// if (coach) {
				// 	switchProtal('coach');
				// 	navigate('/coach/home');
				// } else {
				// 	switchProtal('coachee');
				// 	navigate('/coachee/home');
				// }
			} else {
				schoolCodeVerify(
					{
						...values,
						userType: currentPortal,
						userId: user?._id,
					},
					(data: any) => {
						setSchoolData(data);
						getProfileDataUpdateStorage(() => {
							setShowSubscriptonModal(true);
						});
						// updateProfile({ meta: { ...user.meta, schoolCodeVerified: true } }, () => {
						// 	setShowSubscriptonModal(true);
						// });
					}
				);
			}
		},
		// eslint-disable-next-line
		[updateProfile, navigate, searchParams.continue, switchProtal, url]
	);

	return (
		<Box>
			<Flex mb="2rem" justifyContent={'start'}>
				<Icon name="logo" height="7rem" type={'text'} />
			</Flex>
			<Text
				fontSize="23px"
				color="primary.50"
				my="0.7rem"
				fontFamily="Archivo-Bold"
				mb="2rem"
				mt="3rem"
			>
				Enter your privilege code
			</Text>
			<Text fontSize="1.6rem">
				Please enter the unique privilege code provided by your coach training provider.
			</Text>

			<Text fontSize="1.6rem" mt="1.2rem">
				Haven’t got a code? Check with your training provider or click{' '}
				<a href="mailto:support@mycoachingplace.com">
					<Text as="span" color="secondary.50" fontFamily="semibold">
						here
					</Text>
				</a>{' '}
				to contact My Coaching Place support.
				{/* Haven't got a code? Check the information
				received from your training provider Or{' '}
				<a href="mailto:support@mycoachingplace.com">
					<Text as="span" color="secondary.50" fontFamily="semibold">
						contact admin here
					</Text>
				</a>
				. */}
			</Text>

			<CodeVerifyForm onSubmit={completeProfile} userType={currentPortal} />
			<Modal
				open={showSubscriptionModal}
				onCancel={() => {
					setShowSubscriptonModal(false);
				}}
				footer={false}
				width={650}
			>
				<Box p={'12px'}>
					<Flex justifyContent={'center'}>
						<Image src={SubscriptionGroup} alt="Subscription group" width="308px" />
					</Flex>
					<Text fontFamily="Archivo-Bold" fontSize="h4" my="1rem">
						Free Credits - Our gift to you
					</Text>

					{/* <Text fontSize={'p1'} mb="1.5rem">
						As a special welcome gift, we are giving you{' '}
						<Text as="span" fontFamily={'bold'} color="secondary.50">
							{schoolData?.meta?.numberOfFreeCredits || DEFAULT_FREE_CREDIT || 0}{' '}
							session credits
						</Text>{' '}
						so you can use My Coaching Place with your own clients and really get to
						know the features and benefits.
					</Text> */}

					{user?.meta?.schoolCodeVerified && schoolData?.matchingEligiblity ? (
						<>
							{/* Code verified and school code eligible for matching*/}
							<Text fontSize={'p1'} mb="1.5rem">
								As a special welcome gift, we're giving you{' '}
								<Text as="span" fontFamily={'bold'} color="secondary.50">
									{schoolData?.meta?.numberOfFreeCredits || 0}*
								</Text>{' '}
								session credits to get you started with My Coaching Place. This
								means you'll be able to use My Coaching Place with your own clients
								and really get to experience the benefits first-hand.
							</Text>
							<Text fontSize={'p1'} mb="1.5rem">
								Your privilege code also opens access for you to be matched with
								coachees sourced by My Coaching Place. You can complete your profile
								for matching as part of next steps and then you will be available to
								receive connection requests from coachees matched with your profile.
								For each coaching session you have with a matched coachee, you will
								receive 1 session credit. This means that these sessions will be
								logged as ‘paid’ for ICF accreditation purposes.
							</Text>
						</>
					) : user?.meta?.schoolCodeVerified && !schoolData?.matchingEligiblity ? (
						<>
							{/* Code verified but school code not eligible for matching*/}
							<br />
							<Text fontSize={'p1'} mb="1.5rem">
								As a special welcome gift, we're giving you{' '}
								<Text as="span" fontFamily={'bold'} color="secondary.50">
									{schoolData?.meta?.numberOfFreeCredits || 0}*
								</Text>{' '}
								session credits to get you started with My Coaching Place. This
								means you'll be able to use My Coaching Place with your own clients
								and really get to experience the benefits first-hand.
							</Text>
						</>
					) : (
						<>
							{' '}
							{/* Code not verified */}
							<Text fontSize={'p1'} mb="1.5rem">
								As a special welcome gift, we're giving you{' '}
								<Text as="span" fontFamily={'bold'} color="secondary.50">
									{DEFAULT_FREE_CREDIT || 0}*
								</Text>{' '}
								session credits to get you started with My Coaching Place. This
								means you'll be able to use My Coaching Place with your own clients
								and really get to experience the benefits first-hand.
							</Text>
						</>
					)}

					<Text fontSize={'p1'}>Here's how the session credits work:</Text>
					<Grid
						mt="1.5rem"
						border="1px solid #D1D5DB"
						p="2rem"
						gridTemplateColumns={{ xs: '100%', sm: ' 1fr 1fr' }}
						fontSize="1.6rem"
					>
						<Box>
							<Text fontFamily={'semibold'}>Feature</Text>
							<Text my="1.6rem">Onboarding a client +1 session *</Text>
							<Text>Scheduling a session *</Text>
						</Box>
						<Box>
							<Text fontFamily={'semibold'}>Session Credits Needed</Text>
							<Text my="1.6rem">1 session credit</Text>
							<Text>1 session credit</Text>
						</Box>
					</Grid>

					<Text my="1.5rem" fontSize="1.6rem">
						* Includes: Integrated video call, session booking, session summary and
						feedback, capturing reflections, on platform messaging, file sharing,
						sharing templates (inc coaching agreement), automated session log.
						<br />
						<br />* Free session credits are valid for 6 months
					</Text>
					<Button
						onClick={() => {
							redirection();
						}}
					>
						Continue
					</Button>
				</Box>
			</Modal>
		</Box>
	);
};
