import { themeGet } from "@styled-system/theme-get";
import { Box, BoxProps } from "atoms";
import React from "react";
import styled, { css } from "styled-components";

const StyledButton = styled.input`
  z-index: -1;
  position: absolute;
  opacity: 0;
`;

const StyledLabel = styled.label<UploadButtonProps>`
  cursor: pointer;
  ${(props) => {
    return css`
      font-family: ${themeGet("fontFamily.regular", "Inter-Regular")(props)};
      font-weight: "500";
      font-size: ${themeGet("fontSize.h2", "1.4rem")(props)};
      color: ${themeGet("colors.primary.50", "black")(props)};
    `;
  }};
`;

export interface UploadButtonProps extends BoxProps {
  isMultiple?: boolean;
  onFileSelect?: (files: any) => void;
  accept?: string;
  label?: string;
  title?: string;
  Icon?: any;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  isMultiple = false,
  title = "+ Upload",
  onFileSelect,
  accept = "image/*,.pdf",
  label = "file-upload",
  Icon,
}: UploadButtonProps) => {
  return (
    <Box textAlign="center" p="10px">
      <StyledButton
        type="file"
        id={label}
        multiple={isMultiple}
        onChange={(e: any) => {
          onFileSelect && onFileSelect(e.target.files);
        }}
        accept={accept}
      />
      <StyledLabel htmlFor={label}>{!!Icon ? Icon : title}</StyledLabel>
    </Box>
  );
};
