import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { Box, Button, Text, Flex } from 'atoms';
import { FormInput } from 'molecules/FormInput';

import useAuth from 'pages/Auth/context/Authentication';

type formValuesType = {
	school?: string;
	cohort?: any;
	code?: string;
};

export type SignupFormProps = {
	onSubmit?: (
		values?: formValuesType,
		formikHelpers?: FormikHelpers<formValuesType>
	) => void | Promise<void>;
	userType?: string;
};

const Welcome: FC<SignupFormProps> = ({ onSubmit, userType = 'coach' }) => {
	const [registrationComplete, setRegistrationComplete] = useState(false);

	const {
		state: { outsetaRef },
	} = useAuth();

	useEffect(() => {
		outsetaRef?.current?.on('signup', () => {
			setRegistrationComplete(true);
		});
	}, []);

	return (
		<Box mt="3rem">
			{!registrationComplete && (
				<>
					<Text
						fontSize="23px"
						color="primary.50"
						my="0.7rem"
						fontFamily="Archivo-Bold"
						mb="2rem"
					>
						Welcome
					</Text>

					{userType === 'coach' ? (
						<>
							<Text fontSize="16px" mb="1.2rem">
								Welcome to My Coaching Place: the platform for organising your
								practice and connecting with new clients.
							</Text>
						</>
					) : (
						<>
							{' '}
							<Text fontSize="16px" lineHeight="145.9%" color="#374151" mb="1.2rem">
								To get started please enter the signup code you recieved from your
								organisation.
							</Text>
							<Text fontSize="16px" lineHeight="145.9%" color="#374151" mb="2rem">
								If you don’t have a signup code yet please email{' '}
								<Text as="span" color="#4BB7BA" fontFamily="semibold">
									info@mycoachingplace.com
								</Text>{' '}
								to set up your organisation, or ask your coach to invite you.
							</Text>
							<FormInput
								name="code"
								placeholder="code"
								label="Code"
								autoComplete="off"
							/>
						</>
					)}
				</>
			)}

			<Box mt="3rem">
				<div id="coach-signup-embed"></div>
			</Box>

			{!registrationComplete && (
				<Flex justifyContent="center">
					<Link to="/login">
						<Button variant="transparent">Already have an account. Go to Login</Button>
					</Link>
				</Flex>
			)}
		</Box>
	);
};
export default Welcome;
