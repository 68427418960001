import React, { useEffect } from 'react';

import { useLocation } from 'react-router';

import { Link, useNavigate } from 'react-router-dom';

import MemoNotificationsIcon from 'assets/icons/NotificationsIcon';
import {
	Box,
	Button,
	Flex,
	Grid,
	Image,
	//StyledNavLink,
	Icon,
	Text,
} from 'atoms';
import useVisibleState from 'hooks/useVisibleStates';
//import ProfileSwitchItem from 'molecules/ProfileSwitchItem';
import useAuth from 'pages/Auth/context/Authentication';

//import ProfilePlaceHolderImage from 'assets/images/profile.png';
//import { UserType } from 'typings/enums/users';
//import { FaqContact } from './FaqContact';
import { NotificationDrawer } from './NotificationDrawer';
import { userBlock } from 'helpers/user-block';
import { profilePlaceholder } from 'helpers/common';
//import { SelectableDropdown } from 'molecules/SelectableDropdown';
import { CustomTabs } from 'organisms/CustomTabs';
import { useLocationQuery } from 'hooks/useLocationQuery';
//import dummy from 'assets/images/dummy-image.png';
import { Badge } from 'antd';
interface NavbarProps {
	userType?: string;
}

export const Navbar: React.FC<NavbarProps> = ({ userType }: NavbarProps) => {
	const { visible, toggle } = useVisibleState();
	const { visible: notificationVisible, toggle: toggleNotification } = useVisibleState();
	const navigate = useNavigate();
	const location = useLocation();
	let searchParams = useLocationQuery();

	const {
		state: { user, currentPortal, notifications },
		actions: {
			//switchProtal,
			updateNotifications,
			updateNotificationCount,
		},
	} = useAuth();

	let isUserBlocked: any = userBlock(user);

	useEffect(() => {
		//fetchAllCoaches({ limit: 1000, status: "2,3" });
	}, []);

	const markAllRead = () => {
		updateNotifications({ readAll: true });
	};
	const markOneRead = (id: string) => {
		updateNotifications({ read: [id] });
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	// const getLastHistoryUrl = () => {
	// 	const historyLength = window.history.length;
	// 	console.log('historyLength', historyLength);
	// 	if (historyLength > 2) {
	// 		return window.history[historyLength - 1];
	// 	} else {
	// 		return null;
	// 	}
	// };

	// const renderProfiles = () => {
	// 	const { roles } = user;
	// 	if (roles) {
	// 		const { coach } = roles;
	// 		let profilePic = PROFILE_PLACEHOLDER;
	// 		let brandPic = PROFILE_PLACEHOLDER;
	// 		if (coach) {
	// 			profilePic = ASSET_ENDPOINT + user?.profiles?.coach?.profileImage;
	// 			brandPic =
	// 				ASSET_ENDPOINT +
	// 				(user.profiles.coach.organizationImage
	// 					? user.profiles.coach.organizationImage
	// 					: 'defaults/profile.png');
	// 			return (
	// 				<Box>
	// 					<Text as="p" fontSize="h2" fontFamily="medium" color="primary.50">
	// 						Switch Profile
	// 					</Text>
	// 					<ProfileSwitchItem
	// 						name="Coach Profile"
	// 						description=""
	// 						profileImage={brandPic}
	// 						isselected={currentPortal === 'coach'}
	// 						onClick={() => {
	// 							if (currentPortal !== 'coach') {
	// 								switchProtal('coach');
	// 								navigate('/coach');
	// 							}
	// 						}}
	// 					/>
	// 					{/* {users?.length > 0 ? (
	//           <ProfileSwitchItem
	//             name="Development Profile"
	//             description=""
	//             profileImage={profilePic}
	//             isselected={currentPortal === "client"}
	//             onClick={() => {
	//               if (currentPortal !== "client") {
	//                 switchProtal("client");
	//                 navigate("/coachee/home");
	//               }
	//             }}
	//           />
	//         ) : (
	//           <ProfileSwitchItem
	//             name="Add Supervisor"
	//             description=""
	//             isNew
	//             showIcon={false}
	//             onClick={() => {
	//               if (currentPortal === "coach") {
	//                 navigate("/coach/users?showSupervisorAddForm=true");
	//               }
	//             }}
	//           />
	//         )} */}
	// 				</Box>
	// 			);
	// 		}
	// 		return null;
	// 	}
	// 	return null;
	// };

	// let optionsList =
	// 	currentPortal === 'admin'
	// 		? [{ value: '2', label: 'Admin' }]
	// 		: [
	// 				{ value: '0', label: 'Coach' },
	// 				{ value: '1', label: 'Coachee' },
	// 		  ];

	if (
		!searchParams?.previousPage &&
		location?.pathname?.includes('/admin/organizations/all-codes/view/')
	) {
		searchParams = {
			...searchParams,
			previousPage: '/admin/organizations/all-codes',
			previousText: 'Codes',
		};
	} else if (
		searchParams?.clientInitials &&
		(location?.pathname?.includes('/coach/reports/coaching/edit') ||
			location?.pathname?.includes('/coach/reports/coaching/view'))
	) {
		searchParams = {
			...searchParams,
			previousPage: `${searchParams.previousPage}?clientInitials=${searchParams.clientInitials}&type=${searchParams.type}&accreditation=${searchParams.accreditation}`,
			previousText: 'Reports',
		};
	} else if (
		searchParams?.clientInitials &&
		(location?.pathname?.includes('/coach/reports/coaching/details') ||
			location?.pathname?.includes('/coach/reports/supervision/details'))
	) {
		searchParams = {
			...searchParams,
			previousPage: `/coach/reports/${searchParams.type}?accreditation=${searchParams.accreditation}`,
			previousText: 'Reports',
		};
	} else if (
		!searchParams?.previousPage &&
		!searchParams?.previousText &&
		location?.pathname?.includes('/coach/connections/view/')
	) {
		searchParams = {
			...searchParams,
			previousPage: `/coach/connections`,
			previousText: 'Connections',
		};
	} else if (
		!searchParams?.previousPage &&
		!searchParams?.previousText &&
		location?.pathname?.includes('/admin/organizations/view/')
	) {
		//const previousUrl = getLastHistoryUrl();
		searchParams = {
			...searchParams,
			previousPage: `/admin/organizations`,
			previousText: 'Organisations',
		};
	} else if (
		!searchParams?.previousPage &&
		!searchParams?.previousText &&
		location?.pathname?.includes('/other-profile/')
	) {
		searchParams = {
			...searchParams,
			previousText: 'Back',
		};
	}

	return (
		<Grid
			gridAutoFlow={{ xs: 'unset', md: 'column' }}
			justifyContent={{ xs: 'space-end', md: 'space-end' }}
			px={{ xs: '2rem', md: '4.8rem' }}
			alignItems="center"
			zIndex={99}
			height={{ xs: 'unset', md: '64px' }}
			minHeight={{ xs: '95px', md: '64px' }}
			boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
			borderBottom="1px solid #E5E7EB"
			bg="white.0"
			position="sticky"
			top={0}
		>
			<Grid
				gridAutoFlow="column"
				gridGap="0.8rem"
				justifyContent="space-between"
				alignItems="center"
				//mt={{ xs: '1rem', md: '0' }}
			>
				<Box display={{ xs: 'none', md: 'block' }}>
					{searchParams?.previousPage && window.history ? (
						// <Link to={`${searchParams?.previousPage}`}>
						<Button onClick={handleGoBack} variant="transparent">
							<Flex alignItems="center">
								<Icon name="headedArrow" height="1.2rem" width="25px" />{' '}
								<Text
									fontSize={'body1'}
									fontFamily={'semibold'}
									color="secondary.50"
									ml="1rem"
								>
									{searchParams?.previousText || 'Back'}
								</Text>
							</Flex>
						</Button>
					) : // </Link>
					searchParams?.previousText && window.history ? (
						<Flex alignItems="center" onClick={() => navigate(-1)}>
							<Icon name="headedArrow" height="1.2rem" width="25px" />{' '}
							<Text
								fontSize={'body1'}
								fontFamily={'semibold'}
								color="secondary.50"
								ml="1rem"
							>
								{searchParams?.previousText || 'Back'}
							</Text>
						</Flex>
					) : (
						<CustomTabs user={user} />
					)}
				</Box>

				<Flex
					display={{ md: 'none!important' }}
					justifyContent={'start'}
					mt="0.7rem"
					mb="1rem"
				>
					<Link to={`/${currentPortal}/home`}>
						<Icon name="logo" height="3.7rem" type={'text'} />
					</Link>
				</Flex>

				<Flex style={{ gap: '0.8rem' }} alignItems="center">
					<Box
						border="1px solid #E5E7EB"
						borderRadius="16px"
						p="6px 13px"
						textTransform="capitalize"
					>
						{currentPortal}
					</Box>

					{/* Notification Drawer */}
					<Flex position="relative" alignItems="center" mx="12px">
						{/* {notifications.unReadCount > 0 && (
							<Text
								as="span"
								fontSize="10px"
								margin="0px"
								paddingX="5px"
								borderRadius="50%"
								color="white.0"
								fontFamily="medium"
								backgroundColor="red.300"
								position="absolute"
								top="-5px"
								right="-5px"
							>
								{notifications.unReadCount}
							</Text>
						)} */}

						{currentPortal !== 'admin' && !isUserBlocked && (
							<Badge count={notifications.unReadCount} overflowCount={10}>
								<Button
									variant="transparent"
									display="flex"
									border="1px solid #E5E7EB"
									borderRadius="50%"
									alignItems="center"
									justifyContent="center"
									height="32px !important"
									width="32px !important"
									cursor="pointer"
									onClick={() => {
										toggleNotification();
										updateNotificationCount(0, 'unReadCount');
									}}
								>
									<MemoNotificationsIcon />
								</Button>
							</Badge>
						)}
						{currentPortal !== 'admin' && notificationVisible && (
							<NotificationDrawer
								onClose={() => {
									toggleNotification();
									// updateNotifiaction();
								}}
								onMarkAllRead={markAllRead}
								onMarkOneRead={markOneRead}
								list={notifications.list}
							/>
						)}
					</Flex>

					{/* User Account Dropdown */}
					<Box>
						<Grid
							bg="white"
							gridAutoFlow="column"
							gridGap="1rem"
							justifyContent="space-between"
							alignItems="center"
							cursor="pointer"
							onClick={toggle}
						>
							<Image
								src={profilePlaceholder(user)}
								alt="profile image"
								height="3.2rem"
								width="3.2rem"
								objectfit="cover"
								m="auto"
								borderRadius="24px"
								overflow="hidden"
								border="1px solid #E5E7EB"
								//style={{ border: '1px solid #E5E7EB' }}
							/>
							{/* <Text fontSize="h2" fontFamily="medium" mx="0.5rem">
							{user?.firstName || ''}
						</Text> */}
							{/* {currentPortal !== 'admin' && (
							<MemoArrowDown style={{ marginTop: '0.2rem' }} />
						)} */}
						</Grid>
						{visible && (
							<Box
								position="absolute"
								background="white.0"
								right="-10px"
								top="95%"
								p="2rem"
								minWidth="25rem"
								boxShadow="0 0 20px rgba(179,179,179,0.19)"
								borderRadius="5px"
								onMouseLeave={toggle}
								zIndex={`99999!important`}
								className={'profile-dropdowns'}
							>
								<Link to={`/${currentPortal}/profile/about`} onClick={toggle}>
									<Text fontFamily="semibold" my="1rem" color="primary.150">
										Profile and preferences
									</Text>
								</Link>
								<Box my="1rem" height="1px" bg="grey.200"></Box>
								<Text
									fontFamily="semibold"
									cursor="pointer"
									onClick={() => {
										localStorage.clear();
										window.location.href = `/login?redirectUrl=${window.location.pathname}`;
									}}
								>
									Sign out
								</Text>
							</Box>
						)}
					</Box>
				</Flex>
			</Grid>
			<Box display={{ xs: 'block', md: 'none' }}>
				{searchParams?.previousPage ? (
					<Link to={`${searchParams?.previousPage}`}>
						<Flex alignItems="center" ml="3rem" mb="1rem">
							<Icon name="headedArrow" height="1.2rem" width="25px" />{' '}
							<Text
								fontSize={'body1'}
								fontFamily={'semibold'}
								color="secondary.50"
								ml="1rem"
							>
								{searchParams?.previousText || 'Back'}
							</Text>
						</Flex>
					</Link>
				) : (
					<CustomTabs user={user} />
				)}
			</Box>
		</Grid>
	);
};
