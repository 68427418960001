import { Progress } from 'antd';
import { BoxProps } from 'atoms';
import React from 'react';

import './Progress.css';

interface UploadButtonProps extends BoxProps {
	data?: any;
}

export const ProgressCircle: React.FC<UploadButtonProps> = ({ data }: UploadButtonProps) => {
	return (
		<Progress
			type="circle"
			percent={parseInt(data)}
			className="progress-circle"
			strokeWidth={12}
			status="normal"
		/>
	);
};
