import { useReducer } from 'react';
import { toast as message } from 'react-toastify';

import { api } from 'utils/api/api';
import { buildQuery } from 'utils/buildQuery';
import * as APIRoutes from './APIRoutes';
import { createAction, createContainer, createReducer } from 'utils/context';

export type SchoolsState = {
	list: any;
	schools: any;
	loading: boolean;
	currentSchool: any;
};

const initialState: SchoolsState = {
	list: {
		schools: [],
		meta: {},
	},
	schools: [],
	currentSchool: null,
	loading: true,
};

const actions = {
	fetchSchools: createAction('SCHOOLS_LIST'),
	createOne: createAction('SCHOOL_CREATE'),
	getOne: createAction('GET_ONE_SCHOOL'),
};

const userReducer = createReducer<SchoolsState>({
	[`${actions.fetchSchools}`]: (state, { payload }) => ({
		...state,
		list: {
			schools: payload?.schools || payload?.data,
			meta: payload?.meta,
		},
	}),
	[`${actions.getOne}`]: (state, { payload }) => ({
		...state,
		currentSchool: payload?.school,
	}),
});

export const {
	useContext: useSchools,
	Context: SchoolContext,
	Provider: SchoolProvider,
} = createContainer(() => {
	const [state, dispatch] = useReducer(userReducer, {
		...initialState,
	});

	const fetchAllSchools = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(`${APIRoutes.schools}?${buildQuery(queryParams)}`, {
				method: 'get',
			});

			dispatch(actions.fetchSchools(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createOne = async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.schools, {
				method: 'post',
				data: { ...values },
			});
			message.success('Training Provider created successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getOne = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.schools}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateOne = async (id: string, values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.schools}/${id}`, {
				method: 'put',
				data: { ...values },
			});
			message.success('Updated successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const deleteSchool = async (ids: any, callback?: (data: any) => void) => {
		try {
			let url = `${APIRoutes.schools}`;
			const { data } = await api(url, {
				method: 'delete',
				data: {
					schools: ids,
				},
			});
			message.success('Training Provider deactivated successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	return {
		state,
		actions: {
			fetchAllSchools,
			createOne,
			updateOne,
			getOne,
			deleteSchool,
		},
	};
});

export default useSchools;
