import { useState, useEffect, useRef } from 'react';
declare const window: any;
let autoComplete: any;

const loadScript = (url: any, callback: any) => {
	let script: any = document.createElement('script');
	script.type = 'text/javascript';

	if (script.readyState) {
		script.onreadystatechange = function () {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {
		script.onload = () => callback();
	}

	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad(
	updateQuery: any,
	autoCompleteRef: any,
	returnCallBack?: (res: any) => void
) {
	autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
		types: ['(cities)'],
		//componentRestrictions: { country: 'us' },
	});
	autoComplete.setFields(['address_components', 'formatted_address']);
	autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery, returnCallBack));
}

function convertAddressComponents(addrComp: any) {
	let newAddr: any = {};
	addrComp.forEach((el: any) => {
		if (el.types.includes('locality')) {
			newAddr.city = el.long_name;
		} else if (el.types.includes('administrative_area_level_1')) {
			newAddr.state = el.long_name;
		} else if (el.types.includes('country')) {
			newAddr.country = el.long_name;
		}
	});
	return newAddr;
}

async function handlePlaceSelect(updateQuery: any, returnCallBack?: (res: any) => void) {
	const addressObject = autoComplete.getPlace();
	//console.log('addressObject', addressObject);
	let data = convertAddressComponents(addressObject?.address_components);
	//updateQuery(data);

	if (typeof returnCallBack === 'function') {
		returnCallBack(data);
	}
}

interface SearchProps {
	value?: any;
	returnCallBack?: any;
}

export const GooglePlaceSearch: React.FC<SearchProps> = ({
	value,
	returnCallBack = 'Type any keyword',
}) => {
	const [query, setQuery] = useState<any>('');
	const [cleared, setSetCleared] = useState<any>(true);
	const autoCompleteRef = useRef(null);
	console.log(query);

	useEffect(
		() => {
			//let mapKey = 'AIzaSyBNBaQGfTKbFRRbajWOr7-IFoIL7Pf-VMo';
			let mapKey = 'AIzaSyC1noDZmMoYdvfAs47peuLb7rgr46UHryQ';
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`,
				() => handleScriptLoad(setQuery, autoCompleteRef, returnCallBack)
			);
		},
		// eslint-disable-next-line
		[]
	);

	// useEffect(() => {
	// 	if (value) setQuery(value);
	// }, [value]);

	// useEffect(() => {
	// 	if (query && typeof callback === 'function') {
	// 		callback(value);
	// 	}
	// }, [query]);

	//let val = `${query?.city || ''} ${query?.state || ''} ${query?.country || ''}`;

	return (
		<div className="search-location-input">
			{cleared && value?.city !== '' && value?.city !== undefined ? (
				<div>
					<input
						value={
							`${value?.city || ''} ${value?.state || ''} ${value?.country || ''}` ||
							''
						}
					/>
					<span
						style={{
							position: 'absolute',
							right: '10px',
							//top: '5px',
							zIndex: 9,
							padding: '10px',
							cursor: 'pointer',
						}}
						onClick={() => {
							setSetCleared(true);
							returnCallBack({ city: '', state: '', country: '' });
						}}
					>
						X
					</span>
				</div>
			) : (
				<input
					ref={autoCompleteRef}
					onChange={(event) => {
						setQuery(event.target.value);
					}}
					placeholder="City, State, Country"
					//value={query}
				/>
			)}
		</div>
	);
};

//export default GooglePlaceSearch;
