import * as React from 'react';

function Exercise(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="29"
			height="29"
			viewBox="0 0 29 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.9163 2.41666H7.24967C6.60873 2.41666 5.99405 2.67127 5.54083 3.12448C5.08762 3.57769 4.83301 4.19238 4.83301 4.83332V24.1667C4.83301 24.8076 5.08762 25.4223 5.54083 25.8755C5.99405 26.3287 6.60873 26.5833 7.24967 26.5833H21.7497C22.3906 26.5833 23.0053 26.3287 23.4585 25.8755C23.9117 25.4223 24.1663 24.8076 24.1663 24.1667V9.66666L16.9163 2.41666Z"
				stroke={props.fill || 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="white"
			/>
			<path
				d="M16.917 2.41666V9.66666H24.167"
				stroke={props.fill || 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="white"
			/>
			<path
				d="M10.875 18.125H18.125"
				stroke={props.fill || 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="white"
			/>
		</svg>
	);
}

const MemoExercise = React.memo(Exercise);
export default MemoExercise;
