import { toast as message } from 'react-toastify';
import { useReducer } from 'react';
import { api } from 'utils/api/api';
import { buildQuery } from 'utils/buildQuery';
import { createAction, createContainer, createReducer } from 'utils/context';
import * as APIRoutes from 'utils/APIRoutes';

export type OrganizationsState = {
	list: any;
	loading: boolean;
	currentOrganization: any;
	currentGroup: any;
	contacts: any;
	organizationGroups: any;
	codes: any;
	allCodes: any;
	sessionPackages: any;
	currentCodeInfo: any;
	admins: any;
};

const initialState: OrganizationsState = {
	list: {
		organizations: [],
		meta: {},
	},
	currentOrganization: null,
	currentGroup: null,
	contacts: [],
	loading: true,
	organizationGroups: {
		organizationGroups: [],
		meta: {},
	},
	codes: {
		codes: [],
		meta: {},
	},
	allCodes: {
		codes: [],
		meta: {},
	},
	sessionPackages: [],
	currentCodeInfo: null,
	admins: [],
};

const actions = {
	fetchOrganizations: createAction('ORGANIZATIONS_LIST'),
	createOne: createAction('ORGANIZATION_CREATE'),
	getOne: createAction('GET_ONE_ORGANIZATION'),
	fetchOrganizationContacts: createAction('GET_CONTACTS_LIST'),
	createGroup: createAction('ORGANIZATION_GROUP_CREATE'),
	getGroup: createAction('GET_ONE_ORGANIZATION_GROUP'),
	getAllGroups: createAction('GET_ALL_GROUP'),
	clearAllLocalGroups: createAction('CLEAR_ALL_GROUPS'),
	fetchOrganizationGroups: createAction('GET_GROUPS_LIST'),
	fetchCodes: createAction('GET_CODES_LIST'),
	updateGroupCode: createAction('UPDATE_GROUP_CODE'),
	deactivateGroupCode: createAction('DEACTIVATE_GROUP_CODE'),
	resetCodes: createAction('RESET_CODES_LIST'),
	fetchSessionPackages: createAction('GET_SESSION_PACKAGE_TYPES'),
	setSelectedCard: createAction('SET_SELECTED_CARD'),
	setSelectCodeAll: createAction('SET_SELECT_CODE_ALL'),
	updateCodeCoacheeDetails: createAction('SET_UPDATE_CODE_COACHEE_DETAILS'),
	fetchAllCodes: createAction('GET_ALL_CODES_LIST'),
	getCodeInformation: createAction('GET_ONE_CODE_INFORMATION'),
	fetchAdmins: createAction('GET_ADMINS'),
	//removeCodeSelected: createAction('REMOVE_CODE_SELECTED'),
};

const userReducer = createReducer<OrganizationsState>({
	[`${actions.fetchOrganizations}`]: (state, { payload }) => ({
		...state,
		list: {
			organizations: payload.organizations,
			meta: payload?.meta,
		},
	}),
	[`${actions.getOne}`]: (state, { payload }) => ({
		...state,
		currentOrganization: payload?.organization,
	}),
	[`${actions.fetchOrganizationContacts}`]: (state, { payload }) => ({
		...state,
		contacts: payload?.data?.documents,
	}),
	[`${actions.fetchOrganizationGroups}`]: (state, { payload }) => ({
		...state,
		organizationGroups: {
			organizationGroups: payload.organizationVerticals,
			meta: payload?.meta,
		},
	}),
	[`${actions.getGroup}`]: (state, { payload }) => ({
		...state,
		currentGroup: payload.organizationVertical,
	}),
	[`${actions.getAllGroups}`]: (state, { payload }) => ({
		...state,
		currentGroup: payload.organizationVerticals,
	}),
	[`${actions.clearAllLocalGroups}`]: (state, {}) => ({
		...state,
		currentGroup: [],
	}),
	[`${actions.fetchCodes}`]: (state, { payload }) => ({
		...state,
		codes: {
			codes: payload?.organizationVerticalCodes || [],
			meta: payload?.meta,
		},
	}),
	[`${actions.resetCodes}`]: (state, { payload }) => ({
		...state,
		codes: {
			codes: [] || [],
			meta: {},
		},
	}),
	[`${actions.updateGroupCode}`]: (state, { payload }) => {
		let datas = state?.codes?.codes?.map((x: any) => {
			return payload?._id === x?._id
				? (x = {
						...x,
						...payload,
						coach: payload?.connection?.coach?._id
							? payload?.connection?.coach
							: payload?.coach,
						coachee: payload?.connection?.coachee?._id
							? payload?.connection?.coachee
							: payload?.coachee,
				  })
				: x;
		});

		return {
			...state,
			codes: {
				codes: datas,
				meta: state?.codes?.meta,
			},
		};
	},
	[`${actions.deactivateGroupCode}`]: (state, { payload }) => {
		let datas = state?.codes?.codes?.filter((x: any) => payload?._id !== x?._id);

		return {
			...state,
			codes: {
				codes: datas,
				meta: { ...state?.codes?.meta, total: state?.codes?.meta?.total - 1 },
			},
		};
	},
	[`${actions.fetchSessionPackages}`]: (state, { payload }) => ({
		...state,
		sessionPackages: payload.sessionPackages || [],
	}),
	[`${actions.setSelectCodeAll}`]: (state, { payload }) => {
		let datas = state?.codes?.codes?.map((x: any, key: any) => {
			if (payload?.type === 'coachee_assigned') {
				return payload?.checked
					? (x = {
							...x,
							selected: x?.coachee,
					  })
					: (x = {
							...x,
							selected: false,
					  });
			} else {
				return payload?.checked && !x?.coachee?._id
					? (x = {
							...x,
							selected: true,
					  })
					: (x = {
							...x,
							selected: false,
					  });
			}
		});

		return {
			...state,
			codes: {
				codes: datas,
				meta: state?.codes?.meta,
			},
		};
	},
	[`${actions.setSelectedCard}`]: (state, { payload }) => {
		let datas = state?.codes?.codes?.map((x: any, key: any) => {
			return payload === key
				? (x = {
						...x,
						selected: !x?.selected,
				  })
				: x;
		});

		return {
			...state,
			codes: {
				codes: datas,
				meta: state?.codes?.meta,
			},
		};
	},
	/*All codes*/
	[`${actions.fetchAllCodes}`]: (state, { payload }) => ({
		...state,
		allCodes: {
			codes: payload?.organizationVerticalCodes || [],
			meta: payload?.meta,
		},
	}),
	[`${actions.getCodeInformation}`]: (state, { payload }) => ({
		...state,
		currentCodeInfo: payload?.organizationVerticalCode,
	}),
	[`${actions.fetchAdmins}`]: (state, { payload }) => ({
		...state,
		admins: payload,
	}),
});

export const {
	useContext: useOrganizations,
	Context: OrganizationContext,
	Provider: OrganizationProvider,
} = createContainer(() => {
	const [state, dispatch] = useReducer(userReducer, {
		...initialState,
	});

	const fetchOrganizations = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(`${APIRoutes.organizations}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			dispatch(actions.fetchOrganizations(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createOne = async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.organizations, {
				method: 'post',
				data: { ...values },
			});
			message.success('Organization Created successfully');
			dispatch(actions.createOne(data));
			dispatch(actions.resetCodes());

			//createOrganizationContacts(data?.data?.organization?._id, values, callback);

			if (typeof callback === 'function') {
				callback(data?.organization?._id);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	/* Need to remove*/
	const createOrganizationContacts = async (
		organizationId: string,
		values: any,
		callback?: (data: any) => void
	) => {
		let findExists: any = values?.organizationContacts?.filter((x: any) => x?._id);
		let url =
			findExists?.length === 0
				? `${APIRoutes.organizationContacts}/insert-many/${organizationId}`
				: `${APIRoutes.organizationContacts}/${organizationId}`;
		try {
			await api(url, {
				method: findExists?.length === 0 ? 'post' : 'patch',
				data: { organizationContacts: values.organizationContacts },
			});

			if (typeof callback === 'function') {
				callback(organizationId);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getOne = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.organizations}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateOne = async (id: string, values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.organizations}/${id}`, {
				method: 'put',
				data: { ...values },
			});
			message.success('Updated successfully');

			//createOrganizationContacts(id, values);
			dispatch(actions.createOne(data));

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const deleteOrganization = async (
		ids: any,
		isSingleMode: boolean,
		callback?: (data: any) => void
	) => {
		try {
			let url = isSingleMode
				? `${APIRoutes.organizations}/${ids}`
				: `${APIRoutes.organizations}`;

			const { data } = await api(url, {
				method: 'delete',
				data: {
					ids,
				},
			});
			message.success('Organizations deleted successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const deleteOrganizationGroups = async (
		organizationId: string,
		id: any,
		isSingleMode = true,
		callback?: (data: any) => void
	) => {
		try {
			let url = isSingleMode
				? `${APIRoutes.organizationGroups}/${id}`
				: `${APIRoutes.organizationGroups}/${id}`;

			const { data } = await api(url, {
				method: 'delete',
			});
			message.success('Organization group deleted successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createGroup = async (
		//organizationId: string,
		values: any,
		callback?: (data: any) => void
	) => {
		delete values?._id;
		try {
			const { data } = await api(`${APIRoutes.organizationGroups}`, {
				method: 'post',
				data: { ...values },
			});
			message.success('Organization Group Created successfully');

			if (typeof callback === 'function') {
				callback(data?.organizationVertical);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getGroup = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.organizationGroups}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getGroup(data));
			if (typeof callback === 'function') {
				callback(data.organizationVertical);
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const getAllGroups = async (queryParams = {}, callback?: (data: any) => void) => {
		try {
			// const queryParams = {
			// 	all: true,
			// 	statuses: 1,
			// };

			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					all: true,
					statuses: 1,
				};
			} else {
				queryParams = {
					...queryParams,
					all: true,
					statuses: 1,
				};
			}

			const { data } = await api(
				`${APIRoutes.organizationGroups}?${buildQuery(queryParams)}`,
				{
					method: 'get',
				}
			);
			dispatch(actions.getAllGroups(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateGroup = async (groupId: any, values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.organizationGroups}/${groupId}`, {
				method: 'put',
				data: { ...values },
			});
			message.success('Group is updated successfully');

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createGroupCodes = async (
		organizationId: string,
		groupId: string,
		values: any,
		callback?: (data: any) => void
	) => {
		try {
			let findExists: any = values?.organizationGroupCodes?.filter((x: any) => x?._id);
			let url =
				findExists?.length === 0
					? `${APIRoutes.addOrganizationGroupCodes}/${organizationId}/${groupId}`
					: `${APIRoutes.organizationGroupCodes}/${organizationId}/${groupId}`;

			await api(url, {
				method: findExists?.length === 0 ? 'post' : 'patch',
				data: { organizationGroupCodes: values?.organizationGroupCodes },
			});

			if (typeof callback === 'function') {
				callback({ groupId });
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getOrganizationContacts = async (
		organizationId: string,
		queryParams?: any,
		callback?: (data: any) => void
	) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(`${APIRoutes.organizations}/${organizationId}/contacts`, {
				method: 'get',
			});
			dispatch(actions.fetchOrganizationContacts(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const fetchOrganizationGroups = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(
				`${APIRoutes.organizationGroups}?${buildQuery(queryParams)}`,
				{
					method: 'get',
				}
			);
			dispatch(actions.fetchOrganizationGroups(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getOrganizationGroupCodes = async (queryParams?: any, callback?: (data: any) => void) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			const { data } = await api(
				`${APIRoutes.organizationGroupCodes}?${buildQuery(queryParams)}`,
				{
					method: 'get',
				}
			);
			dispatch(actions.fetchCodes(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getAllCodes = async (queryParams?: any, callback?: (data: any) => void) => {
		try {
			if (Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 100,
				};
			} else if (!queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 100,
				};
			}

			const { data } = await api(`${APIRoutes.allCodes}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			dispatch(actions.fetchAllCodes(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getSingleCodeDetails = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.allCodes}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getCodeInformation(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const userAssignCode = async (
		codeId: string,
		values: any,
		userType: string,
		callback?: (data: any) => void
	) => {
		delete values?._id;
		try {
			const { data } = await api(
				`${APIRoutes.organizationGroupCodes}/${codeId}/${userType}`,
				{
					method: 'patch',
					data: { ...values },
				}
			);
			message.success(`${userType === 'coach' ? 'Coach' : 'Coachee'} assigned successfully`);

			//dispatch(actions.updateCodeCoacheeDetails(data));
			dispatch(actions.updateGroupCode(data?.organizationVerticalCode));
			if (typeof callback === 'function') {
				callback(data?.organizationVerticalCode);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const generateExtraCodes = async (
		groupId: string,
		values: any,
		callback?: (data: any) => void
	) => {
		delete values?._id;
		try {
			const { data } = await api(`${APIRoutes.organizationGroups}/${groupId}/codes`, {
				method: 'patch',
				data: { ...values },
			});
			message.success('Extra code added successfully');

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getCodeDetails = async (
		organizationId: string,
		groupId: string,
		codeId: string,
		callback?: (data: any) => void
	) => {
		try {
			const { data } = await api(
				`${APIRoutes.organizationGroupCodes}/${organizationId}/${groupId}/${codeId}`,
				{
					method: 'get',
				}
			);
			//dispatch(actions.fetchCodes(data));
			if (typeof callback === 'function') {
				callback(data?.data?.organizationGroupCode);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getCodeHistory = async (codeId: string, callback?: (data: any) => void) => {
		try {
			const { data } = await api(
				`${APIRoutes.organizationGroupCodesHistory}/${codeId}/history`,
				{
					method: 'get',
				}
			);
			//dispatch(actions.fetchCodes(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getSessionPackages = async (callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.organizationSessionPackages}?all=true`, {
				method: 'get',
			});
			dispatch(actions.fetchSessionPackages(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getAdmins = async (callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.admins}`, {
				method: 'get',
			});
			dispatch(actions.fetchAdmins(data));
		} catch (error) {
			message.error(error.message);
		}
	};

	const codeSendEmail = async (values: any, callback?: () => void) => {
		try {
			await api(`${APIRoutes.organizationGroupCodes}/send-invites`, {
				method: 'post',
				data: { ...values },
			});
			dispatch(actions.setSelectCodeAll(false));
			if (typeof callback === 'function') {
				callback();
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const sendReportContactEmail = async (values: any, callback?: () => void) => {
		try {
			await api(`${APIRoutes.reportSendContacts}`, {
				method: 'post',
				data: { ...values },
			});
			if (typeof callback === 'function') {
				callback();
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const previewAttachement = async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.addOrganizationGroupCodes}}`, {
				method: 'post',
				data: { ...values },
			});
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const verifyCode = async (code: string, coacheeId: string, callback?: (data: any) => void) => {
		try {
			const { data } = await api(
				`${APIRoutes.organizationGroupCodesHistory}/${code}/coachee/${coacheeId}`,
				{
					method: 'get',
					//data: { ...newVal },
				}
			);

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateGroupCode = async (id: string, values: any, callback?: (data: any) => void) => {
		try {
			const Id = values?._id;
			const newVal = values;

			if (newVal?.codeVerify) {
				delete newVal?._id;
			}

			const { data } = await api(`${APIRoutes.organizationGroupCodes}/${id}`, {
				method: 'put',
				data: { ...newVal },
			});

			if (values?.deactivate) {
				dispatch(actions.deactivateGroupCode(data?.organizationVerticalCode));
			} else {
				dispatch(actions.updateGroupCode(data?.organizationVerticalCode));
			}

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateCodeSessionPackage = async (
		id: string,
		values: any,
		callback?: (data: any) => void
	) => {
		try {
			const newVal = values;

			if (newVal?.codeVerify) {
				delete newVal?._id;
			}

			const { data } = await api(
				`${APIRoutes.organizationGroupCodes}/${id}/session-package`,
				{
					method: 'patch',
					data: { ...newVal },
				}
			);

			dispatch(actions.updateGroupCode(data?.organizationVerticalCode));

			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const resetGroupCodes = () => {
		dispatch(actions.resetCodes());
	};

	const setSelectedCard = (index: any) => {
		dispatch(actions.setSelectedCard(index));
	};

	const setSelectCodeAll = (checked: boolean, type: string) => {
		dispatch(actions.setSelectCodeAll({ checked, type }));
	};

	const clearAllLocalGroups = () => {
		dispatch(actions.clearAllLocalGroups());
	};

	// const removeCodeSelected = () => {
	// 	dispatch(actions.removeCodeSelected());
	// };

	return {
		state,
		actions: {
			fetchOrganizations,
			createOne,
			updateOne,
			getOne,
			deleteOrganization,
			getOrganizationContacts,
			createOrganizationContacts,
			createGroup,
			getGroup,
			updateGroup,
			fetchOrganizationGroups,
			deleteOrganizationGroups,
			getOrganizationGroupCodes,
			codeSendEmail,
			previewAttachement,
			updateGroupCode,
			verifyCode,
			updateCodeSessionPackage,
			createGroupCodes,
			resetGroupCodes,
			getSessionPackages,
			getCodeDetails,
			getCodeHistory,
			generateExtraCodes,
			userAssignCode,
			setSelectCodeAll,
			setSelectedCard,
			getAllGroups,
			getAllCodes,
			getSingleCodeDetails,
			clearAllLocalGroups,
			sendReportContactEmail,
			getAdmins,
			//removeCodeSelected,
		},
	};
});

export default useOrganizations;
