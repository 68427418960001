import * as React from 'react';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13 1L1 13"
				stroke={props?.stroke || 'white.0'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 1L13 13"
				stroke={props?.stroke || 'white.0'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const MemoCloseIcon = React.memo(CloseIcon);
export default MemoCloseIcon;
