import { useReducer } from 'react';
import { toast as message } from 'react-toastify';
import { api } from 'utils/api/api';
import { buildQuery } from 'utils/buildQuery';
import { createAction, createContainer, createReducer } from 'utils/context';
import * as APIRoutes from './APIRoutes';

export type CohortsState = {
	list: any;
	loading: boolean;
	currentCohort: any;
};

const initialState: CohortsState = {
	list: {
		cohorts: [],
		meta: {},
	},
	currentCohort: null,
	loading: true,
};

const actions = {
	fetchCohorts: createAction('COHORTS_LIST'),
	resetCohorts: createAction('RESET_COHORTS_LIST'),
	createOne: createAction('COHORT_CREATE'),
	getOne: createAction('GET_ONE_COHORT'),
};

const userReducer = createReducer<CohortsState>({
	[`${actions.fetchCohorts}`]: (state, { payload }) => ({
		...state,
		list: {
			cohorts: payload?.cohorts || payload?.data || [],
			//cohorts: [],
			meta: payload?.meta,
		},
	}),
	[`${actions.resetCohorts}`]: (state, { payload }) => ({
		...state,
		list: {
			cohorts: [],
			meta: {},
		},
	}),
	[`${actions.getOne}`]: (state, { payload }) => ({
		...state,
		currentCohort: payload.cohort,
	}),
});

export const {
	useContext: useCohorts,
	Context: CohortContext,
	Provider: CohortProvider,
} = createContainer(() => {
	const [state, dispatch] = useReducer(userReducer, {
		...initialState,
	});

	const fetchAllCohorts = async (queryParams: any, callback?: (data: any) => void) => {
		try {
			if (!queryParams?.all && Object.entries(queryParams).length === 0) {
				queryParams = {
					page: 1,
					limit: 10,
				};
			} else if (!queryParams?.all && !queryParams.limit) {
				queryParams = {
					...queryParams,
					limit: 10,
				};
			}

			if (queryParams?.all) {
				dispatch(actions.resetCohorts());
			}

			const { data } = await api(`${APIRoutes.cohorts}?${buildQuery(queryParams)}`, {
				method: 'get',
			});
			dispatch(actions.fetchCohorts(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const createOne = async (values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(APIRoutes.cohorts, {
				method: 'post',
				data: { ...values },
			});
			message.success('Cohort Created successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const getOne = async (id: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.cohorts}/${id}`, {
				method: 'get',
			});
			dispatch(actions.getOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const updateOne = async (id: string, values: any, callback?: (data: any) => void) => {
		try {
			const { data } = await api(`${APIRoutes.cohorts}/${id}`, {
				method: 'put',
				data: { ...values },
			});
			message.success('Updated successfully');
			dispatch(actions.createOne(data));
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const deleteCohort = async (ids: any, callback?: (data: any) => void) => {
		try {
			let url = `${APIRoutes.cohorts}`;

			const { data } = await api(url, {
				method: 'delete',
				data: {
					cohorts: ids,
				},
			});
			message.success('Cohort deactivated successfully');
			if (typeof callback === 'function') {
				callback(data);
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	return {
		state,
		actions: {
			fetchAllCohorts,
			createOne,
			updateOne,
			getOne,
			deleteCohort,
		},
	};
});

export default useCohorts;
