import { Text, Box } from 'atoms';

interface ViewFieldProps {
	label: string;
	value: string;
	pb?: string;
	textType?: any;
}

export const ViewField: React.FC<ViewFieldProps> = ({
	label,
	value,
	textType = 'capitalize',
	pb = '1.6rem',
}: ViewFieldProps) => {
	return (
		<Box pb={pb}>
			<Box>
				<Text pb="0px" mb="0px">
					{label}
				</Text>
			</Box>
			<Box>
				<Text fontWeight="semibold" pb="0px" mb="0px" textTransform={textType}>
					{value}
				</Text>
			</Box>
		</Box>
	);
};
