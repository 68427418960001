import * as React from 'react';

function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="9"
			viewBox="0 0 16 9"
			fill="none"
			{...props}
		>
			<path
				d="M15 1L8 8L1 1"
				stroke={props.stroke || '#4BB7BA'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const MemoArrowDown = React.memo(ArrowDown);
export default MemoArrowDown;
