import { Box, Flex, Text } from 'atoms';
import React from 'react';

interface PageHeaderProps {
	title: any;
	bordered?: boolean;
	children?: React.ReactNode;
	mb?: string;
	pl?: string;
	py?: string;
	fontSize?: string;
	variant?: 'primary' | 'secondary';
	buttonsAlignment?: 'end' | 'start';
	display?: any;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
	title,
	children,
	bordered = false,
	mb = '4rem',
	pl = '2rem',
	py = '0rem',
	variant = 'primary',
	fontSize = 'h3',
	buttonsAlignment = 'end',
	display,
}: any) => {
	return (
		<Box width="100%" py={py} mb={mb} borderBottom={bordered && '1px solid #efefef'}>
			<Flex
				display={{ xs: display || 'block !important', md: 'flex !important' }}
				justifyContent="space-between"
				flexDirection="row"
				alignItems="center"
				position="relative"
			>
				{variant === 'primary' ? (
					<Text
						fontFamily="Archivo-Bold"
						fontSize={fontSize || 'h3'}
						lineHeight={fontSize || 'h3'}
						color="primary.50"
						wordBreak="break-word"
					>
						{title}
					</Text>
				) : (
					<Text
						fontFamily="Archivo-Bold"
						fontSize={fontSize || 'h3'}
						lineHeight={fontSize || 'h3'}
						color="primary.50"
						wordBreak="break-word"
					>
						{title}
					</Text>
				)}
				<Flex
					position="static"
					mt={{ xs: '1rem', md: 0 }}
					minWidth={'25%'}
					justifyContent={{ xs: 'start', sm: 'end' }}
				>
					{children}
				</Flex>
			</Flex>
		</Box>
	);
};
