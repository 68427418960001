import * as React from 'react';

function ShareScreen(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="800px" height="800px" viewBox="0 0 28 28" version="1.1" fill="#ffffff" stroke="#ffffff">

<g id="SVGRepo_bgCarrier" strokeWidth="0"/>

<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>

			<g id="SVGRepo_iconCarrier">
				<title>ic_fluent_share_screen_28_regular</title>
				<desc>Created with Sketch.</desc>
				<g id="🔍-System-Icons"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd">
					<g id="ic_fluent_share_screen_28_regular"
						fill="#ffffff"
						fillRule="nonzero">
						<path d="M23.75,4.99939 C24.9926,4.99939 26,6.00675 26,7.24939 L26,20.75 C26,21.9926 24.9926,23 23.75,23 L4.25,23 C3.00736,23 2,21.9927 2,20.75 L2,7.24939 C2,6.00675 3.00736,4.99939 4.25,4.99939 L23.75,4.99939 Z M23.75,6.49939 L4.25,6.49939 C3.83579,6.49939 3.5,6.83518 3.5,7.24939 L3.5,20.75 C3.5,21.1642 3.83579,21.5 4.25,21.5 L23.75,21.5 C24.1642,21.5 24.5,21.1642 24.5,20.75 L24.5,7.24939 C24.5,6.83518 24.1642,6.49939 23.75,6.49939 Z M13.9975,8.62102995 C14.1965,8.62102995 14.3874,8.69998 14.5281,8.8407 L17.7826,12.0952 C18.0755,12.3881 18.0755,12.863 17.7826,13.1559 C17.4897,13.4488 17.0148,13.4488 16.7219,13.1559 L14.7477,11.1817 L14.7477,18.6284 C14.7477,19.0426 14.412,19.3784 13.9977,19.3784 C13.5835,19.3784 13.2477,19.0426 13.2477,18.6284 L13.2477,11.1835 L11.2784,13.1555 C10.9858,13.4486 10.5109,13.4489 10.2178,13.1562 C9.92469,12.8636 9.92436,12.3887 10.217,12.0956 L13.467,8.84107 C13.6077,8.70025 13.7985,8.62102995 13.9975,8.62102995 Z" id="🎨-Color"> </path> </g> </g> </g>

</svg>
	);
}

const ShareScreenIcon = React.memo(ShareScreen);
export default ShareScreenIcon;
