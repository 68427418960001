/* eslint-disable import/no-anonymous-default-export */
export default {
	User: {
		password: {
			placeholder: 'Enter your Password',
			resetPlaceholder: 'Enter your New Password',
			retypePlaceholer: 'Retype the New Password',
			required: 'Please enter password',
			notMatch: 'Should be the same as in Password',
			// strength: "Min 8 Char's, One Uppercase, One Lowercase, One Number and one special Char",
			strength:
				'Min 8 Max 20 char’s length with at least 1 lowercase, 1 upper case, 1 special character, 1 number.',
			resetSuccess: 'Password has beed reset successfully. Try login.',
			forgotSuccess:
				'A link to reset your password has been sent to the registered email address',
		},
		confirmpassword: {
			required: 'Should be the same as in Password',
			notMatch: 'Enter password and confirm new password does not match',
		},
		email: {
			placeholder: 'Please enter the email address',
			required: 'Please enter the email address',
			invalid: 'Please enter valid email address',
		},
		otp: {
			placeholder: 'Enter your OTP',
			required: 'OTP is required',
			invalid: 'OTP is invalid',
		},
		billing: {
			placeholder: 'Enter your Name',
			required: 'Name is required',
			invalid: 'Name is invalid',
		},
		firstName: {
			placeholder: 'Enter your First Name',
			required: 'First name is required',
			invalid: 'First name is invalid',
			min: 'Minimum 3 characters',
			max: 'Max 25 characters',
		},
		lastName: {
			placeholder: 'Enter your Last Name',
			required: 'Last name is required',
			invalid: 'Last name is invalid',
			min: 'Minimum 1 characters',
			max: 'Max 25 characters',
		},
		phone: {
			placeholder: 'Enter your Phone Number',
			required: 'Phone number is required',
			invalid: 'Phone number is invalid',
		},
		alias: {
			placeholder: 'Enter Alias',
			required: 'Alias is required',
			invalid: 'Entered alias is invalid',
		},
	},
	Supervisor: {
		accrediation: {
			required: 'Accredited is required',
		},
		supervisionType: {
			required: 'Supervision Type is required',
		},
		supervisionHours: {
			required: 'Supervision Hours is required',
			numbers: 'Numbers only allowed',
			min: 'Minimum 1 digits required',
			max: 'Maximum 4 digits 2 decimal values allowed',
			valid: 'Enter valid number',
		},
		timezone: {
			required: 'Timezone is required',
		},
		priority: {
			required: 'Time To Start is required',
		},
	},
};
