import * as React from 'react';
import IconProps from './IconProps';

function TagIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="14"
				viewBox="0 0 18 14"
				fill="none"
			>
				<path
					d="M2 14C1.45 14 0.979 13.8043 0.587 13.413C0.195667 13.021 0 12.55 0 12V2C0 1.45 0.195667 0.979333 0.587 0.588C0.979 0.196 1.45 0 2 0H12C12.3333 0 12.646 0.0749999 12.938 0.225C13.2293 0.375 13.4667 0.583333 13.65 0.85L17.175 5.85C17.425 6.2 17.55 6.58333 17.55 7C17.55 7.41667 17.425 7.8 17.175 8.15L13.65 13.15C13.4667 13.4167 13.2293 13.625 12.938 13.775C12.646 13.925 12.3333 14 12 14H2Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="14"
			viewBox="0 0 18 14"
			fill="none"
		>
			<path
				d="M2 14C1.45 14 0.979 13.8043 0.587 13.413C0.195667 13.021 0 12.55 0 12V2C0 1.45 0.195667 0.979333 0.587 0.588C0.979 0.196 1.45 0 2 0H12C12.3333 0 12.646 0.0749999 12.938 0.225C13.2293 0.375 13.4667 0.583333 13.65 0.85L17.175 5.85C17.425 6.2 17.55 6.58333 17.55 7C17.55 7.41667 17.425 7.8 17.175 8.15L13.65 13.15C13.4667 13.4167 13.2293 13.625 12.938 13.775C12.646 13.925 12.3333 14 12 14H2Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoTagIcon = React.memo(TagIcon);
export default MemoTagIcon;
