import MemoCloseIcon from 'assets/icons/CloseIcon';
import { Flex, Text } from 'atoms';

interface FilterBadgeProps {
	text: string;
	onClick?: () => void;
}

const noNeededBadges = ['clientId'];

function giveSpace(key: any) {
	if (key === 'lastcontacted') {
		return 'Last Contacted';
	} else if (key === 'usertype') {
		return 'User Type';
	} else if (key === 'sessionDate') {
		return 'Session Date';
	} else if (key === 'clientId') {
		return 'Client Id';
	} else if (key === 'assignedTo') {
		return 'Assigned To';
	} else if (key === 'CouponId') {
		return 'Coupon Name';
	}
	return key;
}

function dontShowBadge(text: string) {
	/**
	 * Some of the badges we don't to show on the badges list, so based on condition remove it from the list
	 */
	return noNeededBadges.includes(text);
}

export const FilterBadge = ({ text, onClick }: FilterBadgeProps) => {
	if (dontShowBadge(text)) {
		return null;
	}

	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			cursor="pointer"
			my="auto"
			border="1px solid #4BB7BA"
			color="#4BB7BA"
			padding="5px 6px"
			borderRadius="5rem"
			fontSize="12px"
			ml="2rem"
			onClick={() => onClick && onClick()}
		>
			<Text textAlign="center" mr="0.4rem" px="5px" textTransform="capitalize">
				{giveSpace(text)}
			</Text>
			<MemoCloseIcon
				fill="#4BB7BA"
				stroke="#4BB7BA"
				style={{ cursor: 'pointer', width: 8, height: 8 }}
			/>
		</Flex>
	);
};
