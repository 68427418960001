import { DatePicker, DatePickerProps, InputFieldBase, Text, Flex } from 'atoms';
import { Error } from 'atoms/Error';
import { Warning } from 'atoms/Warning';
import React from 'react';
import { Merge } from 'typings/utils';

interface FieldProps {
	label?: string;
	error?: string;
	warning?: string;
	marginBottom?: string;
	labelSpace?: string;
	labelHide?: boolean;
	disabled?: boolean;
	showOptional?: boolean;
}

export type DatePickerFieldProps = Merge<FieldProps, DatePickerProps>;

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
	label,
	error,
	warning,
	marginBottom,
	labelSpace,
	labelHide = false,
	disabled = false,
	showOptional = false,
	...props
}: DatePickerFieldProps) => {
	let bottom = marginBottom ? marginBottom : '30px';

	return (
		<InputFieldBase>
			{!labelHide && (
				<Flex justifyContent={'space-between'}>
					<Text as="label" fontSize="h5" color="primary.50" fontFamily="semibold">
						{label}
					</Text>
					{showOptional && <Text>{'Optional'}</Text>}
				</Flex>
			)}
			<DatePicker
				style={{
					border: `1px solid ${error ? '#9B3634' : '#9CA3AF'}`,
					width: '100%',
					marginBottom: bottom,
					backgroundColor: error ? '#FFF1F1' : '#fcfcfc',
					...(labelSpace && { marginTop: labelSpace }),
				}}
				disabled={disabled}
				{...props}
			/>
			{error && <Error text={error} bottom="-10%" />}
			{warning && <Warning text={warning} />}
		</InputFieldBase>
	);
};
