import * as React from 'react';

function UserIcon(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'bordered') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="17"
				viewBox="0 0 16 17"
				fill="none"
			>
				<path
					d="M8 8.5C6.9 8.5 5.95833 8.10833 5.175 7.325C4.39167 6.54167 4 5.6 4 4.5C4 3.4 4.39167 2.45833 5.175 1.675C5.95833 0.891667 6.9 0.5 8 0.5C9.1 0.5 10.0417 0.891667 10.825 1.675C11.6083 2.45833 12 3.4 12 4.5C12 5.6 11.6083 6.54167 10.825 7.325C10.0417 8.10833 9.1 8.5 8 8.5ZM0 16.5V13.7C0 13.1333 0.145833 12.6125 0.4375 12.1375C0.729167 11.6625 1.11667 11.3 1.6 11.05C2.63333 10.5333 3.68333 10.1458 4.75 9.8875C5.81667 9.62917 6.9 9.5 8 9.5C9.1 9.5 10.1833 9.62917 11.25 9.8875C12.3167 10.1458 13.3667 10.5333 14.4 11.05C14.8833 11.3 15.2708 11.6625 15.5625 12.1375C15.8542 12.6125 16 13.1333 16 13.7V16.5H0ZM2 14.5H14V13.7C14 13.5167 13.9542 13.35 13.8625 13.2C13.7708 13.05 13.65 12.9333 13.5 12.85C12.6 12.4 11.6917 12.0625 10.775 11.8375C9.85833 11.6125 8.93333 11.5 8 11.5C7.06667 11.5 6.14167 11.6125 5.225 11.8375C4.30833 12.0625 3.4 12.4 2.5 12.85C2.35 12.9333 2.22917 13.05 2.1375 13.2C2.04583 13.35 2 13.5167 2 13.7V14.5ZM8 6.5C8.55 6.5 9.02083 6.30417 9.4125 5.9125C9.80417 5.52083 10 5.05 10 4.5C10 3.95 9.80417 3.47917 9.4125 3.0875C9.02083 2.69583 8.55 2.5 8 2.5C7.45 2.5 6.97917 2.69583 6.5875 3.0875C6.19583 3.47917 6 3.95 6 4.5C6 5.05 6.19583 5.52083 6.5875 5.9125C6.97917 6.30417 7.45 6.5 8 6.5Z"
					fill="#6B7280"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="16"
			viewBox="0 0 14 16"
			fill="none"
		>
			<path
				d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z"
				fill={props?.fill || '#4B5563'}
			/>
			<path
				d="M0.465171 12.4935C0.270287 13.0016 0.444349 13.571 0.874199 13.9046C2.56656 15.218 4.69202 16 7.00012 16C9.31057 16 11.438 15.2164 13.1312 13.9006C13.5608 13.5667 13.7345 12.9971 13.5393 12.4892C12.5301 9.86354 9.98419 8 7.00307 8C4.02032 8 1.47329 9.86557 0.465171 12.4935Z"
				fill={props?.fill || '#4B5563'}
			/>
		</svg>
	);
}

const MemoUserIcon = React.memo(UserIcon);
export default MemoUserIcon;
