import * as React from 'react';

function ReplyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
<svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
<path fillRule="evenodd" clipRule="evenodd" d="M5.9364 0.963703C6.10512 1.13248 6.19991 1.36136 6.19991 1.6C6.19991 1.83865 6.10512 2.06753 5.9364 2.2363L3.8727 4.3H8.9001C10.571 4.3 12.1734 4.96375 13.3549 6.14523C14.5364 7.32671 15.2001 8.92914 15.2001 10.6V12.4C15.2001 12.6387 15.1053 12.8676 14.9365 13.0364C14.7677 13.2052 14.5388 13.3 14.3001 13.3C14.0614 13.3 13.8325 13.2052 13.6637 13.0364C13.4949 12.8676 13.4001 12.6387 13.4001 12.4V10.6C13.4001 9.40653 12.926 8.26194 12.0821 7.41802C11.2382 6.57411 10.0936 6.1 8.9001 6.1H3.8727L5.9364 8.1637C6.02236 8.24672 6.09092 8.34604 6.13809 8.45584C6.18526 8.56564 6.21009 8.68374 6.21113 8.80324C6.21217 8.92274 6.18939 9.04125 6.14414 9.15186C6.09889 9.26247 6.03206 9.36295 5.94756 9.44746C5.86305 9.53196 5.76257 9.59879 5.65196 9.64404C5.54135 9.68929 5.42284 9.71207 5.30334 9.71103C5.18384 9.70999 5.06574 9.68516 4.95594 9.63799C4.84613 9.59083 4.74682 9.52226 4.6638 9.4363L1.0638 5.8363C0.895077 5.66753 0.800293 5.43865 0.800293 5.2C0.800293 4.96135 0.895077 4.73248 1.0638 4.5637L4.6638 0.963703C4.83258 0.794979 5.06145 0.700195 5.3001 0.700195C5.53875 0.700195 5.76763 0.794979 5.9364 0.963703Z" fill="#1582A4"/>
</svg>
  );
}

const MemoReplyIcon = React.memo(ReplyIcon);
export default MemoReplyIcon;
