import * as React from 'react';

function MatchingIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="101"
			viewBox="0 0 100 101"
			fill="none"
		>
			<circle cx="50" cy="50.5" r="48" stroke="#D1D5DB" strokeWidth="4" />
			<path
				d="M50 2.5C60.9271 2.5 71.5272 6.22832 80.0484 13.0688C88.5695 19.9093 94.5017 29.4524 96.8644 40.121C99.2272 50.7897 97.8791 61.9452 93.0429 71.7438C88.2068 81.5425 80.1721 89.3978 70.2668 94.0115C60.3614 98.6252 49.1784 99.7212 38.5658 97.1182C27.9532 94.5152 18.5464 88.3691 11.9 79.6957C5.25366 71.0223 1.76557 60.3408 2.01224 49.4164"
				stroke="#4BB7BA"
				strokeWidth="4"
				strokeLinecap="round"
			/>
			<path
				d="M49.9999 46.0992C53.9764 46.0992 57.1999 42.8757 57.1999 38.8992C57.1999 34.9228 53.9764 31.6992 49.9999 31.6992C46.0235 31.6992 42.7999 34.9228 42.7999 38.8992C42.7999 42.8757 46.0235 46.0992 49.9999 46.0992Z"
				fill="#4BB7BA"
			/>
			<path
				d="M40.3999 43.6992C40.3999 46.3502 38.2509 48.4992 35.5999 48.4992C32.9489 48.4992 30.7999 46.3502 30.7999 43.6992C30.7999 41.0483 32.9489 38.8992 35.5999 38.8992C38.2509 38.8992 40.3999 41.0483 40.3999 43.6992Z"
				fill="#4BB7BA"
			/>
			<path
				d="M29.5774 61.2808C29.1705 61.0442 28.8564 60.6715 28.7178 60.2216C28.5112 59.5507 28.3999 58.838 28.3999 58.0992C28.3999 54.1228 31.6235 50.8992 35.5999 50.8992C36.8527 50.8992 38.0308 51.2192 39.0569 51.7819C36.5438 54.2565 34.8658 57.5773 34.4834 61.2833C34.4282 61.8178 34.4512 62.3452 34.5438 62.8534C32.7454 62.6966 31.0606 62.143 29.5774 61.2808Z"
				fill="#4BB7BA"
			/>
			<path
				d="M65.4571 62.8533C67.2551 62.6964 68.9395 62.1429 70.4224 61.2808C70.8293 61.0442 71.1434 60.6715 71.282 60.2216C71.4886 59.5507 71.5999 58.838 71.5999 58.0992C71.5999 54.1228 68.3764 50.8992 64.3999 50.8992C63.1474 50.8992 61.9695 51.2191 60.9436 51.7815C63.4569 54.2561 65.135 57.5771 65.5175 61.2833C65.5726 61.8178 65.5496 62.3452 65.4571 62.8533Z"
				fill="#4BB7BA"
			/>
			<path
				d="M69.1999 43.6992C69.1999 46.3502 67.0509 48.4992 64.3999 48.4992C61.7489 48.4992 59.5999 46.3502 59.5999 43.6992C59.5999 41.0483 61.7489 38.8992 64.3999 38.8992C67.0509 38.8992 69.1999 41.0483 69.1999 43.6992Z"
				fill="#4BB7BA"
			/>
			<path
				d="M38.7291 63.3574C38.2513 62.9248 37.998 62.294 38.0642 61.6529C38.6877 55.6111 43.7937 50.8992 50.0003 50.8992C56.2068 50.8992 61.3128 55.6111 61.9363 61.6529C62.0025 62.2941 61.7492 62.9248 61.2714 63.3574C58.2908 66.0557 54.3375 67.6992 50.0003 67.6992C45.663 67.6992 41.7097 66.0557 38.7291 63.3574Z"
				fill="#4BB7BA"
			/>
		</svg>
	);
}

const MemoMatchingIcon = React.memo(MatchingIcon);
export default MemoMatchingIcon;
