import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router';

import { Loader } from 'atoms';
import { AuthLayout } from 'templates';
import { PP } from 'pages/Auth/PP';
import { TC } from 'pages/Auth/TC';
import { Page403 as UnAuthorized, Page404 as PageNotFound } from 'pages/Errors';
import { Meeting } from 'pages/Meeting';
import CompleteProfile from 'pages/Auth/CompleteProfile';
import CodeVerify from 'pages/Auth/CodeVerify';
import { RedirectPage } from 'pages/Errors/RedirectPage';
import { AccountDeletedPage } from 'pages/Errors/AccountDeletedPage';
import { ConfirmationTokenExpiredPage } from 'pages/Errors/ConfirmationTokenExpiredPage';
import { AuthContainer } from 'pages/Auth/AuthContainer/AuthContainer';

import { SchoolProvider } from 'pages/Schools/context/schools';

const CoachDashboard = React.lazy(
	() => import(/* webpackChunkName: 'CoachDashboard' */ 'pages/Coach')
);

const ClientDashboard = React.lazy(
	() => import(/* webpackChunkName: 'ClientDashboard' */ 'pages/Client')
);

const AdminDashboard = React.lazy(
	() => import(/* webpackChunkName: 'CoachDashboard' */ 'pages/Admin')
);

export const UnauthenticatedRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path="/pagenotfound" element={<PageNotFound />} />
				<Route path="/account-deleted" element={<AccountDeletedPage />} />
				<Route path="/link-expired" element={<ConfirmationTokenExpiredPage />} />
				<Route path="/terms" element={<TC />} />
				<Route path="/privacy-policy" element={<PP />} />
				<Route path="/meeting/*" element={<Meeting />} />
				<Route path="/*" element={<AuthContainer />} />
			</Routes>
		</Suspense>
	);
};

export const AuthenticatedRoutes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route
					path={`/coach/complete-profile`}
					element={
						<AuthLayout>
							<CompleteProfile />
						</AuthLayout>
					}
				/>
				<Route
					path={`/coachee/complete-profile`}
					element={
						<AuthLayout>
							<CompleteProfile />
						</AuthLayout>
					}
				/>

				<Route
					path={`/coach/code-verify`}
					element={
						<SchoolProvider>
							<AuthLayout>
								<CodeVerify />
							</AuthLayout>
						</SchoolProvider>
					}
				/>

				<Route
					path={`/coachee/code-verify`}
					element={
						<AuthLayout>
							<CodeVerify />
						</AuthLayout>
					}
				/>

				<Route path="/coach/*" element={<CoachDashboard />} />
				<Route path="/coachee/*" element={<ClientDashboard />} />
				<Route path="/admin/*" element={<AdminDashboard />} />

				<Route path="/terms" element={<TC />} />
				<Route path="/privacy-policy" element={<PP />} />

				<Route path="/unauthorized" element={<UnAuthorized />} />
				<Route path="/pagenotfound" element={<PageNotFound />} />
				<Route path="/meeting/*" element={<Meeting />} />

				<Route path="*" element={<RedirectPage />} />
			</Routes>{' '}
		</Suspense>
	);
};
