import { FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';

// let isMounted = true;
export const useFormSubmitWithLoading = <T>(
  onSubmit?: (values: T, formikHelpers?: FormikHelpers<T>) => void,
): {
  onSubmitHandler: (values: T, formikHelpers?: FormikHelpers<T>) => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   isMounted = true;
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const onSubmitHandler = useCallback(
    async (values, formikHelpers) => {
      setLoading(true);
      if (typeof onSubmit === 'function') {
        await onSubmit(values, formikHelpers);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return { onSubmitHandler, loading };
};
