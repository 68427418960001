import React from 'react';
import styled from 'styled-components';

import { Box, BoxProps } from '../Box';

const LoaderStyle = styled(Box)<BoxProps>`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  children?: React.ReactNode;
  loading?: boolean;
}

export const Loader = ({ loading, children }: LoaderProps) => {
  if (loading) {
    return (
      <LoaderStyle
        border={1}
        borderColor="white.0"
        borderTop="2px solid"
        borderTopColor="white.0"
        borderRadius="50%"
        width="25px"
        height="25px"
        maxWidth="100%"
        maxHeight="100%"
        overflow="auto"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        margin="auto"
      />
    );
  }
  return <>{children || null} </>;
};

Loader.defaultProps = {
  loading: true,
};
