import React from 'react';
import { Switch as AntdSwitch } from 'antd';

import { Box, Flex, Text } from 'atoms';

interface SwitchProps {
	label?: string;
	labelHide?: boolean;
	showOptional?: boolean;
	onChange?: (value: boolean) => void;
	isHorizontal?: boolean;
	defaultChecked?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
	label,
	labelHide,
	showOptional,
	onChange,
	isHorizontal = false,
	defaultChecked,
}: any) => {
	return (
		<Box width="100%">
			{!isHorizontal && !labelHide && (
				<Flex justifyContent={'space-between'}>
					<Text as="label" fontSize="1.6rem">
						{label}
					</Text>
					{showOptional && <Text>Optional</Text>}
				</Flex>
			)}

			{isHorizontal ? (
				<Flex>
					<AntdSwitch
						defaultChecked={defaultChecked}
						onChange={(checked: boolean) => {
							// console.log(`switch to ${checked}`);
							//setFieldValue('isLocked', checked);
							if (typeof onChange === 'function') {
								onChange(!checked);
							}
						}}
					/>
					<Text as="label" fontSize="1.6rem" ml="1.2rem">
						{label}
					</Text>
				</Flex>
			) : (
				<AntdSwitch
					defaultChecked={defaultChecked}
					onChange={(checked: boolean) => {
						// console.log(`switch to ${checked}`);
						//setFieldValue('isLocked', checked);
						if (typeof onChange === 'function') {
							onChange(!checked);
						}
					}}
				/>
			)}
		</Box>
	);
};
