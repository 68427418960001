import * as React from 'react';
import IconProps from './IconProps';

function CouponIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="16"
				viewBox="0 0 20 16"
				fill="none"
			>
				<path
					d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V10.625C0 10.4417 0.0583333 10.2833 0.175 10.15C0.291667 10.0167 0.441667 9.93333 0.625 9.9C1.025 9.76667 1.35417 9.525 1.6125 9.175C1.87083 8.825 2 8.43333 2 8C2 7.56667 1.87083 7.175 1.6125 6.825C1.35417 6.475 1.025 6.23333 0.625 6.1C0.441667 6.06667 0.291667 5.98333 0.175 5.85C0.0583333 5.71667 0 5.55833 0 5.375V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V5.375C20 5.55833 19.9417 5.71667 19.825 5.85C19.7083 5.98333 19.5583 6.06667 19.375 6.1C18.975 6.23333 18.6458 6.475 18.3875 6.825C18.1292 7.175 18 7.56667 18 8C18 8.43333 18.1292 8.825 18.3875 9.175C18.6458 9.525 18.975 9.76667 19.375 9.9C19.5583 9.93333 19.7083 10.0167 19.825 10.15C19.9417 10.2833 20 10.4417 20 10.625V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM10 13C10.2833 13 10.5208 12.9042 10.7125 12.7125C10.9042 12.5208 11 12.2833 11 12C11 11.7167 10.9042 11.4792 10.7125 11.2875C10.5208 11.0958 10.2833 11 10 11C9.71667 11 9.47917 11.0958 9.2875 11.2875C9.09583 11.4792 9 11.7167 9 12C9 12.2833 9.09583 12.5208 9.2875 12.7125C9.47917 12.9042 9.71667 13 10 13ZM10 9C10.2833 9 10.5208 8.90417 10.7125 8.7125C10.9042 8.52083 11 8.28333 11 8C11 7.71667 10.9042 7.47917 10.7125 7.2875C10.5208 7.09583 10.2833 7 10 7C9.71667 7 9.47917 7.09583 9.2875 7.2875C9.09583 7.47917 9 7.71667 9 8C9 8.28333 9.09583 8.52083 9.2875 8.7125C9.47917 8.90417 9.71667 9 10 9ZM10 5C10.2833 5 10.5208 4.90417 10.7125 4.7125C10.9042 4.52083 11 4.28333 11 4C11 3.71667 10.9042 3.47917 10.7125 3.2875C10.5208 3.09583 10.2833 3 10 3C9.71667 3 9.47917 3.09583 9.2875 3.2875C9.09583 3.47917 9 3.71667 9 4C9 4.28333 9.09583 4.52083 9.2875 4.7125C9.47917 4.90417 9.71667 5 10 5Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
		>
			<path
				d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V10.625C0 10.4417 0.0583333 10.2833 0.175 10.15C0.291667 10.0167 0.441667 9.93333 0.625 9.9C1.025 9.76667 1.35417 9.525 1.6125 9.175C1.87083 8.825 2 8.43333 2 8C2 7.56667 1.87083 7.175 1.6125 6.825C1.35417 6.475 1.025 6.23333 0.625 6.1C0.441667 6.06667 0.291667 5.98333 0.175 5.85C0.0583333 5.71667 0 5.55833 0 5.375V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V5.375C20 5.55833 19.9417 5.71667 19.825 5.85C19.7083 5.98333 19.5583 6.06667 19.375 6.1C18.975 6.23333 18.6458 6.475 18.3875 6.825C18.1292 7.175 18 7.56667 18 8C18 8.43333 18.1292 8.825 18.3875 9.175C18.6458 9.525 18.975 9.76667 19.375 9.9C19.5583 9.93333 19.7083 10.0167 19.825 10.15C19.9417 10.2833 20 10.4417 20 10.625V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM10 13C10.2833 13 10.5208 12.9042 10.7125 12.7125C10.9042 12.5208 11 12.2833 11 12C11 11.7167 10.9042 11.4792 10.7125 11.2875C10.5208 11.0958 10.2833 11 10 11C9.71667 11 9.47917 11.0958 9.2875 11.2875C9.09583 11.4792 9 11.7167 9 12C9 12.2833 9.09583 12.5208 9.2875 12.7125C9.47917 12.9042 9.71667 13 10 13ZM10 9C10.2833 9 10.5208 8.90417 10.7125 8.7125C10.9042 8.52083 11 8.28333 11 8C11 7.71667 10.9042 7.47917 10.7125 7.2875C10.5208 7.09583 10.2833 7 10 7C9.71667 7 9.47917 7.09583 9.2875 7.2875C9.09583 7.47917 9 7.71667 9 8C9 8.28333 9.09583 8.52083 9.2875 8.7125C9.47917 8.90417 9.71667 9 10 9ZM10 5C10.2833 5 10.5208 4.90417 10.7125 4.7125C10.9042 4.52083 11 4.28333 11 4C11 3.71667 10.9042 3.47917 10.7125 3.2875C10.5208 3.09583 10.2833 3 10 3C9.71667 3 9.47917 3.09583 9.2875 3.2875C9.09583 3.47917 9 3.71667 9 4C9 4.28333 9.09583 4.52083 9.2875 4.7125C9.47917 4.90417 9.71667 5 10 5Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoCouponIcon = React.memo(CouponIcon);
export default MemoCouponIcon;
