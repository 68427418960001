import { Box, BoxProps } from 'atoms/Box';
import { forwardRef, ReactElement } from 'react';
import { Tooltip } from 'antd';

export interface TextProps extends BoxProps {
	/**
	 * The dom element type of the text
	 */
	as?: any;
	/**
	 *
	 */
	children?: any;
	id?: string;
	ref?: any;
	title?: any;
	className?: any;
	onClick?: (e: any) => void;
	tooltip?: boolean;
}

export const Text = forwardRef<ReactElement | HTMLElement, TextProps>(
	({ as, tooltip, children, ...rest }, ref) => {
		const textContent = (
			<Box as={as} ref={ref} {...rest}>
				{children}
			</Box>
		);

		return tooltip ? <Tooltip title={children}>{textContent}</Tooltip> : textContent;
	}
);

Text.defaultProps = {
	as: 'div',
	children: '',
};
