import { FC } from 'react';
import { useField } from 'formik';
import { toast as message } from 'react-toastify';

import { Select, SelectProps } from 'atoms/Select';

export interface FormSelectProps extends SelectProps {
	name: string;
	onChangeCustom?: (data?: any) => void;
	minHeight?: string;
	tooltipPosition?: any;
	maxSelectedLength?: number;
}

export const FormSelect: FC<FormSelectProps> = ({
	name,
	onChangeCustom,
	defaultValue,
	tooltipPosition,
	maxSelectedLength,
	...props
}) => {
	const [field, meta] = useField(name);
	const error = (meta.touched && meta.error) || '';
	return (
		<Select
			{...field}
			{...props}
			error={error}
			onChange={(e: any) => {
				if (maxSelectedLength) {
					if (e?.length <= maxSelectedLength) {
						field.onChange({ target: { name, value: e } });
						onChangeCustom && onChangeCustom(e);
						return;
					} else if (field?.value?.length > e?.length) {
						field.onChange({ target: { name, value: e } });
						onChangeCustom && onChangeCustom(e);
						return;
					} else {
						message.error(`Maximum ${maxSelectedLength} is allowed`);
						return;
					}
				}
				field.onChange({ target: { name, value: e } });
				onChangeCustom && onChangeCustom(e);
			}}
		/>
	);
};
