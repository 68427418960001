import * as React from 'react';

function DashboardIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="16"
			viewBox="0 0 22 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.5 2.125C0.5 1.08947 1.33947 0.25 2.375 0.25H8.375C9.41053 0.25 10.25 1.08947 10.25 2.125V5.875C10.25 6.91053 9.41053 7.75 8.375 7.75H2.375C1.33947 7.75 0.5 6.91053 0.5 5.875V2.125ZM12.5 3.625C12.5 2.58947 13.3395 1.75 14.375 1.75H19.625C20.6605 1.75 21.5 2.58947 21.5 3.625V11.875C21.5 12.9105 20.6605 13.75 19.625 13.75H14.375C13.3395 13.75 12.5 12.9105 12.5 11.875V3.625ZM2 11.125C2 10.0895 2.83947 9.25 3.875 9.25H9.125C10.1605 9.25 11 10.0895 11 11.125V13.375C11 14.4105 10.1605 15.25 9.125 15.25H3.875C2.83947 15.25 2 14.4105 2 13.375V11.125Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoDashboardIcon = React.memo(DashboardIcon);
export default MemoDashboardIcon;
