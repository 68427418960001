import MemoTickIcon from 'assets/icons/TickIcon';
import { Box } from 'atoms/Box';
import { Flex } from 'atoms/Flex';
import { FC, useCallback, useEffect, useState } from 'react';

export type CheckboxProps = {
	size?: number;
	onChange?: (value: boolean) => void;
	checked?: boolean;
	activeColor?: string;
	borderRadius?: string;
};

export const Checkbox: FC<CheckboxProps> = ({
	children,
	size = 18,
	onChange,
	checked: value = false,
	activeColor = 'primary.50',
	borderRadius = '0',
}) => {
	const [checked, setChecked] = useState<boolean>(value);

	useEffect(() => {
		setChecked(value);
	}, [value]);

	const onToggle = useCallback(() => {
		setChecked((prev) => !prev);
	}, []);

	const onChangeHandler = useCallback(() => {
		if (typeof onChange === 'function') {
			onChange(!checked);
		}
	}, [onChange, checked]);

	return (
		<Flex
			flexWrap="nowrap"
			alignItems="center"
			onClick={onChange ? onChangeHandler : onToggle}
			cursor="pointer"
		>
			<Box
				border="1px solid"
				borderColor={checked ? activeColor : 'grey.100'}
				borderRadius={borderRadius ? borderRadius : '3px'}
				width={size}
				height={size}
				mr={4}
				bg={checked ? activeColor : undefined}
			>
				<Box
					position="absolute"
					top={32 + size + '%'}
					left={70 - size + '%'}
					style={{ transform: 'translate(-50%, -50%)' }}
				>
					<input checked={checked} type="checkbox" hidden disabled />
					{checked && (
						<MemoTickIcon
							fill="red"
							stroke="white"
							width={size / 1.6}
							height={size / 1.6}
						/>
					)}
				</Box>
			</Box>
			{children}
		</Flex>
	);
};
