import { FC } from 'react';
import { Box } from 'atoms';
import ClientRegisterPage from 'templates/RegisterForm/ClientRegisterPage';

import { SchoolProvider } from 'pages/Schools/context/schools';
import { CohortProvider } from 'pages/Cohorts/context/cohorts';
import { OrganizationProvider } from 'pages/Organizations/context/organizations';

type RegisterProps = {
	type: 'regular' | 'invite';
	inviteInfo?: any;
	rawCode?: any;
	userType?: any;
} & any;

export const ClientRegister: FC<RegisterProps> = ({
	type,
	inviteInfo = null,
	rawCode = null,
	userType,
	..._props
}: RegisterProps) => {
	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<SchoolProvider>
				<CohortProvider>
					<OrganizationProvider>
						<ClientRegisterPage userType={userType} type={type} />
					</OrganizationProvider>
				</CohortProvider>
			</SchoolProvider>
		</Box>
	);
};
