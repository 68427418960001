import { Text } from 'atoms/Text';

interface WarningProps {
	text: string;
}

export const Warning = ({ text }: WarningProps) => {
	return (
		<Text as="span" fontSize="h3" color="red.300" position="absolute" left="5px" bottom="0%">
			{text}
		</Text>
	);
};
