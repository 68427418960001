export const TabsList = (pathname: string, params?: any, user?: any) => {
	let menuvalue;

	let userType = user?.roles?.[0]?.name?.toLowerCase();

	if (pathname?.includes('/coach/profile/')) {
		menuvalue = [
			{
				value: 'about',
				label: 'About You',
				url: `/coach/profile/about`,
			},
			{
				value: 'matching',
				label: 'Matching',
				url: `/coach/profile/matching`,
			},
			{
				value: 'experience',
				label: 'Experience',
				url: `/coach/profile/experience`,
			},
			{
				value: 'security',
				label: 'Security',
				url: `/coach/profile/security`,
			},
			{
				value: 'plans-and-payments',
				label: 'Plans & Payments',
				url: `/coach/profile/plans-and-payments`,
			},
		];

		if (
			!(
				user?.preference?.coach?.isSchoolEligibleForMatching &&
				user?.meta?.schoolCodeVerified
			)
		) {
			menuvalue.splice(1, 2);
		}
	} else if (pathname?.includes('/coachee/profile/')) {
		menuvalue = [
			{
				value: 'about',
				label: 'About You',
				url: `/coachee/profile/about`,
			},
			{
				value: 'matching',
				label: 'Matching',
				url: `/coachee/profile/matching`,
			},
			{
				value: 'experience',
				label: 'Experience',
				url: `/coachee/profile/experience`,
			},
			{
				value: 'security',
				label: 'Security',
				url: `/coachee/profile/security`,
			},
		];
		if (user?.organizationVerticalCodes?.length === 0) {
			menuvalue.splice(1, 2);
		}
	} else if (pathname?.includes('/admin/profile/')) {
		menuvalue = [
			{
				value: 'about',
				label: 'About You',
				url: `/admin/profile/about`,
			},
			{
				value: 'security',
				label: 'Security',
				url: `/admin/profile/security`,
			},
		];
	} else if (
		pathname?.includes('/coach/connections') ||
		pathname?.includes('/coachee/connections')
	) {
		menuvalue = [
			{
				value: 'coaching',
				//label: userType === 'coachee' ? 'Connections' : 'Coaching',
				label: 'Connections',
				url: `/${userType}/connections`,
			},
			{
				value: 'requests',
				label: 'Requests',
				url: `/${userType}/connections/requests`,
			},
		];
		if (
			(userType === 'coachee' && user?.organizationVerticalCodes?.length === 0) ||
			(userType === 'coach' &&
				!user?.meta?.schoolCodeVerified &&
				!user?.school?.matchingEligiblity)
		) {
			menuvalue.splice(1, 2);
		}
	} else if (
		pathname === '/admin/organizations' ||
		pathname === '/admin/organizations/all-codes'
	) {
		menuvalue = [
			{
				value: 'organizations',
				label: 'Organizations',
				url: `/admin/organizations`,
			},
			{
				value: 'codes',
				label: 'Codes',
				url: `/admin/organizations/all-codes`,
			},
		];
	} else if (
		pathname?.includes('/admin/organizations/') &&
		!pathname?.includes('edit') &&
		!pathname?.includes('/admin/organizations/view') &&
		!pathname?.includes('/admin/organizations/all-codes') &&
		!pathname?.includes('/admin/organizations/preview')
	) {
		let url = pathname !== '/admin/organizations/basic/add' ? pathname : '';
		menuvalue = [
			{
				value: 'coaching',
				label: 'Organization Information',
				url: `/admin/organizations/basic/add`,
			},
			{
				value: 'codes',
				label: 'Codes',
				url: url,
				disabled: true,
			},
		];
	} else if (pathname === '/coach/tasks' || pathname === '/coach/tasks/assigned-tasks') {
		menuvalue = [
			{
				value: 'assigned-tasks',
				label: 'Assigned tasks',
				url: `/coach/tasks/assigned-tasks`,
			},
			{
				value: 'your-tasks',
				label: 'Your tasks',
				url: `/coach/tasks`,
			},
		];
	} else if (pathname?.includes('/coach/reports') || pathname === '/coach/reports/supervision') {
		menuvalue = [
			{
				value: 'coaching',
				label: 'Coaching',
				url: `/coach/reports`,
			},
			{
				value: 'supervision',
				label: 'Supervision',
				url: `/coach/reports/supervision`,
			},
		];
	} else if (pathname?.includes('/admin/users')) {
		menuvalue = [
			{
				value: 'coaches',
				label: 'Eligible for matching Coaches',
				url: `/admin/users`,
			},
			{
				value: 'not-matching-coaches',
				label: 'Not matching Coaches',
				url: `/admin/users/not-matching-coaches`,
			},
			{
				value: 'coachees',
				label: 'Coachees',
				url: `/admin/users/coachees`,
			},
			{
				value: 'pool-of-coaches',
				label: 'Pool of coaches',
				url: `/admin/users/pool-of-coaches`,
			},
		];
	} else if (pathname?.includes('/admin/home')) {
		menuvalue = [
			{
				value: 'organization',
				label: 'Organisation',
				url: `/admin/home`,
			},
			{
				value: 'coach',
				label: 'Coach',
				url: `/admin/home/coach`,
			},
			{
				value: 'subscription',
				label: 'Subscription',
				url: `/admin/home/subscription`,
			},
			{
				value: 'tgc',
				label: 'Matching Features',
				url: `/admin/home/tgc`,
			},
		];
	}

	return menuvalue;
};
