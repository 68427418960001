import * as React from 'react';
import IconProps from './IconProps';
function SessionIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.75 0.25C5.16421 0.25 5.5 0.585786 5.5 1V2.5H14.5V1C14.5 0.585786 14.8358 0.25 15.25 0.25C15.6642 0.25 16 0.585786 16 1V2.5H16.75C18.4069 2.5 19.75 3.84315 19.75 5.5V16.75C19.75 18.4069 18.4069 19.75 16.75 19.75H3.25C1.59315 19.75 0.25 18.4069 0.25 16.75V5.5C0.25 3.84315 1.59315 2.5 3.25 2.5H4V1C4 0.585786 4.33579 0.25 4.75 0.25ZM18.25 9.25C18.25 8.42157 17.5784 7.75 16.75 7.75H3.25C2.42157 7.75 1.75 8.42157 1.75 9.25V16.75C1.75 17.5784 2.42157 18.25 3.25 18.25H16.75C17.5784 18.25 18.25 17.5784 18.25 16.75V9.25Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.75 0.25C5.16421 0.25 5.5 0.585786 5.5 1V2.5H14.5V1C14.5 0.585786 14.8358 0.25 15.25 0.25C15.6642 0.25 16 0.585786 16 1V2.5H16.75C18.4069 2.5 19.75 3.84315 19.75 5.5V16.75C19.75 18.4069 18.4069 19.75 16.75 19.75H3.25C1.59315 19.75 0.25 18.4069 0.25 16.75V5.5C0.25 3.84315 1.59315 2.5 3.25 2.5H4V1C4 0.585786 4.33579 0.25 4.75 0.25ZM18.25 9.25C18.25 8.42157 17.5784 7.75 16.75 7.75H3.25C2.42157 7.75 1.75 8.42157 1.75 9.25V16.75C1.75 17.5784 2.42157 18.25 3.25 18.25H16.75C17.5784 18.25 18.25 17.5784 18.25 16.75V9.25Z"
				fill={props?.fill || 'white'}
			/>
		</svg>
	);
}

export const MemoSessionIcon = React.memo(SessionIcon);
export default MemoSessionIcon;
