import { FC } from 'react';
import { useField } from 'formik';
import { InputField, InputFieldProps } from 'atoms/InputField';

export interface FormInputProps extends InputFieldProps {
	name: string;
	mb?: string;
	showOptional?: boolean;
}

export const FormInput: FC<FormInputProps> = ({ name, mb, ...props }) => {
	const [{ value, ...field }, meta] = useField(name);
	const error = (meta.touched && meta.error) || '';
	return (
		<InputField
			value={value}
			{...field}
			{...props}
			error={error}
			//mb={mb ? mb : '1.6rem'}
		/>
	);
};
