import { Flex, Text } from 'atoms';

export const ConfirmationTokenExpiredPage = () => {
	return (
		<Flex
			width="100vw"
			height="100vh"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
		>
			<Text as="h1" fontFamily="medium" fontSize="18px" color="primary.50">
				Confirmation Token
			</Text>
			<Text fontFamily="regular" fontSize="16px" color="primary.50">
				This Link Expired or Already Registered
			</Text>
			<a href="/">
				<Text as="p" fontSize="h1" color="secondary.0">
					← Go back to Home Page
				</Text>
			</a>
		</Flex>
	);
};
