import * as React from "react";

function MaximizeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M10 2H14V6"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 14H2V10"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 2L9.3335 6.66667"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 14.0002L6.66667 9.3335"
        stroke={props.stroke || "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMaximizeIcon = React.memo(MaximizeIcon);
export default MemoMaximizeIcon;
