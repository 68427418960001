import { useCallback, FC, useMemo } from 'react';

import {
	Flex,
	//Text
} from 'atoms';
import { useLocationQuery, useLocationQuerySet } from 'hooks/useLocationQuery';

import { Pagination as AntPagination, PaginationProps as AntPaginationProps } from 'antd';
import css from '@styled-system/css';
import styled from 'styled-components';
import { usePageNumber } from 'hooks/usePageNumber';

export const StyledPagination = styled(AntPagination)<AntPaginationProps>`
	${css({})}
`;

const getInt = (arg: string | number): number => {
	if (arg) {
		if (typeof arg === 'string') {
			return parseInt(arg, 10);
		}
		return arg;
	}
	return 0;
};

export interface PaginationProps extends AntPaginationProps {
	fetchData?: () => void;
}

export const Pagination: FC<PaginationProps> = ({
	pageSizeOptions,
	total,
	defaultPageSize,
	fetchData,
}) => {
	const setQuery = useLocationQuerySet();
	const searchParams = useLocationQuery();
	const currentPage = usePageNumber();
	const currentPageInt = useMemo(() => getInt(currentPage), [currentPage]);

	const onPageChangeHandler = useCallback(
		(page: number, pageSize: number | undefined = 10) => {
			if (page) {
				searchParams.page = `${page}`;
				searchParams.limit = `${pageSize}`;
			} else {
				delete searchParams.page;
				delete searchParams.limit;
			}
			setQuery(searchParams);
			setTimeout(() => {
				!!fetchData && fetchData();
			}, 500);
		},
		[setQuery, searchParams, fetchData]
	);

	const onChange = useCallback(
		(current: number, pageSize: any) => {
			onPageChangeHandler(current, pageSize);
		},
		[onPageChangeHandler]
	);

	// let page = currentPageInt;
	// let limit = 10;
	// let data: any = [];

	// let firstValue = page === 1 ? (data?.length > 0 ? page : 0) : (page - 1) * limit + 1;
	// let secondValue = (page - 1) * limit + data?.length;

	return (
		<Flex justifyContent="flex-end" alignItems="center" bg="white" px="1.6rem">
			<StyledPagination
				current={currentPageInt}
				total={total}
				onChange={onChange}
				pageSizeOptions={pageSizeOptions}
				showSizeChanger={true}
				defaultPageSize={defaultPageSize}
				className="ant-table-pagination ant-table-pagination-right mcp-pagination"
				locale={{ items_per_page: '' }}
				showTotal={(total, range) =>
					total > 0 ? `Showing ${range[0]} to ${range[1]} of ${total} results` : ''
				}
				simple
			/>
		</Flex>
	);
};
