import * as React from 'react';

function Audio(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type=='on') {
		return (
			<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 15.5C14.21 15.5 16 13.71 16 11.5V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V11.5C8 13.71 9.79 15.5 12 15.5Z"
					stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4.3501 9.6499V11.3499C4.3501 15.5699 7.7801 18.9999 12.0001 18.9999C16.2201 18.9999 19.6501 15.5699 19.6501 11.3499V9.6499"
					stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M10.6101 6.43012C11.5101 6.10012 12.4901 6.10012 13.3901 6.43012"
					stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M11.2 8.55007C11.73 8.41007 12.28 8.41007 12.81 8.55007"
					stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M12 19V22"
					stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		);
	}
	return (
		<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16 6.3V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V11" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.04004 14.19C9.77004 15 10.83 15.5 12 15.5C14.21 15.5 16 13.71 16 11.5V11" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M6.78003 16.9499C8.15003 18.2199 9.98003 18.9999 12 18.9999C16.22 18.9999 19.65 15.5699 19.65 11.3499V9.6499" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4.34998 9.6499V11.3499C4.34998 12.4099 4.55998 13.4099 4.94998 14.3299" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20.0701 2.84009L3.93005 18.9901"
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11 3V6" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 19V22" 
			stroke={props.stroke || "#292D32"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		
	);
}

const AudioIcon = React.memo(Audio);
export default AudioIcon;
