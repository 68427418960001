import * as React from 'react';
import breakImg from 'assets/icons/png/break.png';

function EyeCloseIcon(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'img') return <img id="break" src={breakImg} alt="break" />;
	return (
		<svg
			aria-hidden="true"
			fill="currentColor"
			width="1em"
			height="1em"
			viewBox="0 0 24 24"
			{...props}
		>
			<path d="M10.58 7.25l1.56 1.56c1.38.07 2.47 1.17 2.54 2.54l1.56 1.56c.16-.44.26-.91.26-1.41C16.5 9.02 14.48 7 12 7c-.5 0-.97.1-1.42.25z" />
			<path d="M12 6a9.77 9.77 0 018.82 5.5 9.762 9.762 0 01-2.66 3.33l1.42 1.42c1.51-1.26 2.7-2.89 3.43-4.74C21.27 7.11 17 4 12 4c-1.4 0-2.73.25-3.98.7l1.61 1.6C10.4 6.12 11.19 6 12 6zM16.43 15.93l-1.25-1.25-1.27-1.27-3.82-3.82-1.27-1.27-1.25-1.25-1.48-1.48-2.78-2.78-1.42 1.41 2.53 2.53A11.96 11.96 0 001 11.5C2.73 15.89 7 19 12 19c1.4 0 2.73-.25 3.98-.7l4.3 4.3 1.41-1.41-3.78-3.78-1.48-1.48zm-4.57-1.74a2.677 2.677 0 01-2.54-2.54l2.54 2.54zM12 17a9.77 9.77 0 01-8.82-5.5 9.762 9.762 0 012.66-3.33l1.91 1.91c-.15.45-.25.92-.25 1.42 0 2.48 2.02 4.5 4.5 4.5.5 0 .97-.1 1.42-.25l.95.95c-.77.18-1.56.3-2.37.3z" />
		</svg>
	);
}

const MemoEyeCloseIcon = React.memo(EyeCloseIcon);
export default MemoEyeCloseIcon;
