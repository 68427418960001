import * as React from 'react';
import IconProps from './IconProps';

function SchoolIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="21"
				height="18"
				viewBox="0 0 21 18"
				fill="none"
			>
				<path
					d="M20.0002 13.9999C19.7169 13.9999 19.4795 13.9039 19.2882 13.7119C19.0962 13.5206 19.0002 13.2832 19.0002 12.9999V7.0999L10.9752 11.4749C10.8252 11.5582 10.6709 11.6206 10.5122 11.6619C10.3542 11.7039 10.1835 11.7249 10.0002 11.7249C9.81686 11.7249 9.6462 11.7039 9.4882 11.6619C9.32953 11.6206 9.17519 11.5582 9.0252 11.4749L0.600195 6.8749C0.433528 6.79157 0.304529 6.67057 0.213195 6.5119C0.121195 6.3539 0.0751953 6.18324 0.0751953 5.9999C0.0751953 5.81657 0.121195 5.6459 0.213195 5.4879C0.304529 5.32924 0.433528 5.20824 0.600195 5.1249L9.0502 0.524902C9.2002 0.441569 9.3542 0.378902 9.5122 0.336902C9.67086 0.295569 9.83353 0.274902 10.0002 0.274902C10.1669 0.274902 10.3295 0.295569 10.4882 0.336902C10.6462 0.378902 10.8002 0.441569 10.9502 0.524902L20.4752 5.7249C20.6419 5.80824 20.7712 5.9289 20.8632 6.0869C20.9545 6.24557 21.0002 6.41657 21.0002 6.5999V12.9999C21.0002 13.2832 20.9042 13.5206 20.7122 13.7119C20.5209 13.9039 20.2835 13.9999 20.0002 13.9999ZM10.0002 17.7249C9.83353 17.7249 9.67086 17.7042 9.5122 17.6629C9.3542 17.6209 9.2002 17.5582 9.0502 17.4749L4.0502 14.7749C3.71686 14.5916 3.45853 14.3459 3.2752 14.0379C3.09186 13.7292 3.0002 13.3832 3.0002 12.9999V9.1999L8.6752 12.2749C8.89186 12.3916 9.10853 12.4956 9.3252 12.5869C9.54186 12.6789 9.76686 12.7249 10.0002 12.7249C10.2335 12.7249 10.4629 12.6749 10.6882 12.5749C10.9129 12.4749 11.1335 12.3666 11.3502 12.2499L17.0002 9.1999V12.9999C17.0002 13.3832 16.9085 13.7292 16.7252 14.0379C16.5419 14.3459 16.2835 14.5916 15.9502 14.7749L10.9502 17.4749C10.8002 17.5582 10.6462 17.6209 10.4882 17.6629C10.3295 17.7042 10.1669 17.7249 10.0002 17.7249Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="18"
			viewBox="0 0 21 18"
			fill="none"
		>
			<path
				d="M20.0002 13.9999C19.7169 13.9999 19.4795 13.9039 19.2882 13.7119C19.0962 13.5206 19.0002 13.2832 19.0002 12.9999V7.0999L10.9752 11.4749C10.8252 11.5582 10.6709 11.6206 10.5122 11.6619C10.3542 11.7039 10.1835 11.7249 10.0002 11.7249C9.81686 11.7249 9.6462 11.7039 9.4882 11.6619C9.32953 11.6206 9.17519 11.5582 9.0252 11.4749L0.600195 6.8749C0.433528 6.79157 0.304529 6.67057 0.213195 6.5119C0.121195 6.3539 0.0751953 6.18324 0.0751953 5.9999C0.0751953 5.81657 0.121195 5.6459 0.213195 5.4879C0.304529 5.32924 0.433528 5.20824 0.600195 5.1249L9.0502 0.524902C9.2002 0.441569 9.3542 0.378902 9.5122 0.336902C9.67086 0.295569 9.83353 0.274902 10.0002 0.274902C10.1669 0.274902 10.3295 0.295569 10.4882 0.336902C10.6462 0.378902 10.8002 0.441569 10.9502 0.524902L20.4752 5.7249C20.6419 5.80824 20.7712 5.9289 20.8632 6.0869C20.9545 6.24557 21.0002 6.41657 21.0002 6.5999V12.9999C21.0002 13.2832 20.9042 13.5206 20.7122 13.7119C20.5209 13.9039 20.2835 13.9999 20.0002 13.9999ZM10.0002 17.7249C9.83353 17.7249 9.67086 17.7042 9.5122 17.6629C9.3542 17.6209 9.2002 17.5582 9.0502 17.4749L4.0502 14.7749C3.71686 14.5916 3.45853 14.3459 3.2752 14.0379C3.09186 13.7292 3.0002 13.3832 3.0002 12.9999V9.1999L8.6752 12.2749C8.89186 12.3916 9.10853 12.4956 9.3252 12.5869C9.54186 12.6789 9.76686 12.7249 10.0002 12.7249C10.2335 12.7249 10.4629 12.6749 10.6882 12.5749C10.9129 12.4749 11.1335 12.3666 11.3502 12.2499L17.0002 9.1999V12.9999C17.0002 13.3832 16.9085 13.7292 16.7252 14.0379C16.5419 14.3459 16.2835 14.5916 15.9502 14.7749L10.9502 17.4749C10.8002 17.5582 10.6462 17.6209 10.4882 17.6629C10.3295 17.7042 10.1669 17.7249 10.0002 17.7249Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoSchoolIcon = React.memo(SchoolIcon);
export default MemoSchoolIcon;
