import { TimePicker as AntTimePicker, TimePickerProps } from 'antd';
import { Box, Text } from 'atoms';
import moment from 'moment';

export interface TimeProps extends TimePickerProps {
	onChangeTime?: (time: any, timeString: any) => void;
	label?: any;
	minuteStep?: number;
	onSelect?: (time: any) => void;
	changeOnBlur?: boolean;
}

const format = 'h:mm a';

export const TimePicker = ({ onChangeTime, label, defaultValue, ...props }: TimeProps) => {
	return (
		<Box width="100%">
			<Text
				textTransform="uppercase"
				as="label"
				fontSize="h3"
				color="primary.50"
				fontFamily="medium"
			>
				{label}
			</Text>
			<Box>
				<AntTimePicker
					onChange={(time: any, timeString: any) =>
						onChangeTime && onChangeTime(time, timeString)
					}
					onSelect={(value) => {
						const timeString = moment(value).format('HH:mm');
						console.log(timeString);
					}}
					defaultValue={
						defaultValue?.isValid() === true ? defaultValue : moment('00:00', format)
					}
					format={format}
					changeOnBlur={false}
					popupClassName={'timepicker'}
					style={{
						width: '100%',
						borderRadius: '5px',
						padding: '10px',
						fontFamily: 'Inter-Regular',
					}}
					{...props}
				/>
			</Box>
		</Box>
	);
};

// to remove ok button reference
// https://codesandbox.io/s/4x47oznvvx?file=/components/CreateAntFields/CreateAntFields.js:700-715
// https://medium.com/wesionary-team/customizing-antd-timepicker-3d49ccc8db16
