import { message } from 'antd';
import { useEffect, useReducer } from 'react';
import S3 from 'utils/aws.config';
import { createAction, createReducer } from 'utils/context';
import { getExtension } from 'utils/helpers';
type CurrentStatus = 'init' | 'checking' | 'uploading' | 'success' | 'error';
export type UploadState = {
	status: CurrentStatus;
	progress: number;
};

const initialState: UploadState = {
	status: 'init',
	progress: 0,
};

const actions = {
	updateProgress: createAction('SET_PROGRESS'),
	updateStatus: createAction('SET_STATUS'),
};

const uploadReducer = createReducer<UploadState>({
	[`${actions.updateProgress}`]: (state, { payload }) => ({
		...state,
		progress: payload,
	}),
	[`${actions.updateStatus}`]: (state, { payload }) => ({
		...state,
		status: payload,
	}),
});

type FileOptions = {
	maxSize?: number;
	fileName: string;
	relativePath: string;
};

type UseUploadResult = UploadState;

export const useUploader = (
	file: File | null,
	options: FileOptions,
	callback?: (err: any, res: any) => void
): UseUploadResult => {
	const [state, dispatch] = useReducer(uploadReducer, { ...initialState });

	const handleUpload = async (file: any, fileOptions: FileOptions) => {
		dispatch(actions.updateStatus('uploading'));
		S3.upload(
			{
				Key: `${fileOptions.relativePath}${fileOptions.fileName}`,
				Bucket: process.env.REACT_APP_S3_BUCKET || '',
				Body: file,
				ACL: 'public-read',
				ContentType: file.type,
			},
			function (err: any, data: any) {
				if (err) {
					message.error(err.message);
					dispatch(actions.updateStatus('error'));
					if (typeof callback === 'function') {
						callback(err, null);
					}
					setTimeout(() => {
						dispatch(actions.updateStatus('init'));
					}, 1000);
				} else {
					// message.success("Uploaded successfully");
					dispatch(actions.updateStatus('success'));

					if (typeof callback === 'function') {
						callback(err, data);
					}
				}
			}
		);
	};

	useEffect(() => {
		if (!file) return;
		dispatch(actions.updateStatus('checking'));
		const { size, name }: any = file;
		let maxSize = options.maxSize ? options.maxSize : 10;
		if (size / 1024 / 1024 < maxSize) {
			//Check size here
			handleUpload(file, {
				...options,
				fileName: `${options.fileName}.${getExtension(name)}`,
			});
		} else {
			dispatch(actions.updateStatus('error'));
			message.error(`File cant be bigger than ${maxSize}mb.`);
			if (typeof callback === 'function') {
				callback(new Error(`File cant be bigger than ${maxSize}mb.`), null);
			}
			setTimeout(() => {
				dispatch(actions.updateStatus('init'));
			}, 1000);
		}
	}, [file]);
	return {
		...state,
	};
};

export const handleRegularUpload = async (
	file: any,
	fileOptions: FileOptions,
	callback?: (err: any, res: any) => void
) => {
	return new Promise((resolve, reject) => {
		if (!file) {
			if (typeof callback === 'function') {
				callback(new Error(`File not found.`), null);
				reject(new Error(`File not found.`));
			}
			return;
		}

		const { size, name }: any = file;
		let maxSize = fileOptions.maxSize ? fileOptions.maxSize : 10;
		if (size / 1024 / 1024 < maxSize) {
			S3.upload(
				{
					Key: `${fileOptions.relativePath}${fileOptions.fileName}.${getExtension(name)}`,
					Bucket: process.env.REACT_APP_S3_BUCKET || '',
					Body: file,
					ACL: 'public-read',
					ContentType: file.type,
				},
				function (err: any, data: any) {
					if (err) {
						message.error(err.message);
						reject(err.message);
					} else {
						resolve(data);
					}

					if (typeof callback === 'function') {
						callback(err, data);
						resolve(data);
					}
				}
			).on('httpUploadProgress', (evt) => {
				let percent = (evt.loaded / evt.total) * 100;
				if (percent < 5) {
					message.loading('Uploading');
				}
				if (percent < 100) {
					//message.loading('Uploading');
				} else {
					message.destroy();
				}

				//setProgress(Math.round((evt.loaded / evt.total) * 100));
			});
		} else {
			message.error(`File cant be bigger than ${maxSize}mb.`);
			if (typeof callback === 'function') {
				callback(new Error(`File cant be bigger than ${maxSize}mb.`), null);
			}
			reject(`File cant be bigger than ${maxSize}mb.`);
		}
	});
};
