import { useEffect } from 'react';
//import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AuthGate from 'pages/Auth';
import { AuthProvider } from 'pages/Auth/context/Authentication';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'utils/styles';
import { theme } from 'utils/styles/theme';
import { StyledContainer } from 'utils/styles/ToastContainer';
import packageInfo from './../package.json';
import { ScrollToTop } from './components/ScrollToTop';

//import { ScrollRestoration } from './components/ScrollRestoration';
//import { FreshdeskWidgetIntegration } from './FreshDesk';

// if (process.env.REACT_APP_ENV === 'production') {
// 	console.log(`%cLog Rocket enabled`, 'color: #25f916; background: #0C0442; font-size: 12px');
// 	let name =
// 		process.env.REACT_APP_ENV === 'production' ? 'latofl/my-coaching-place' : 'imgnmx/mcp';
// 	LogRocket.init(name);
// }

console.log(
	`%cWelcome to MCP v${packageInfo?.version + ' - ' + process.env.REACT_APP_ENV}`,
	'color: white; background: #0C0442; font-size: 16px'
);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<GlobalStyle />
				<StyledContainer hideProgressBar autoClose={2500} position="bottom-center" />
				<Router>
					{/* <ScrollRestoration /> */}
					<ScrollToTop />
					<Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
						<AuthGate />
					</Sentry.ErrorBoundary>
					{/* <FreshdeskWidgetIntegration /> */}
					{/* </ScrollToTop> */}
				</Router>
			</AuthProvider>
		</ThemeProvider>
	);
}

export default App;
