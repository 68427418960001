import styled, { css } from 'styled-components';
import {
	background,
	BackgroundProps,
	border,
	BorderProps,
	color,
	ColorProps,
	flexbox,
	FlexboxProps,
	grid,
	GridProps,
	layout,
	LayoutProps,
	position,
	PositionProps,
	shadow,
	ShadowProps,
	space,
	SpaceProps,
	system,
	typography,
	TypographyProps,
	variant,
} from 'styled-system';
import { fontSets } from 'utils/styles/theme';

const whiteSpace = system({
	whiteSpace: {
		property: 'whiteSpace',
	},
	wordBreak: {
		property: 'wordBreak',
	},
	fontFamily: {
		property: 'fontFamily',
		scale: 'fontFamily',
	},
});

const variants = fontSets.reduce((fontMap, { name }) => {
	const fm = fontMap;
	fm[name] = {
		fontSize: name,
		lineHeight: name,
		fontWeight: name,
	};
	return fm;
}, {});

const fontNames = fontSets.map((f) => f.name);

type Variant = typeof fontNames[number];

export interface BoxProps
	extends ColorProps,
		LayoutProps,
		SpaceProps,
		GridProps,
		PositionProps,
		BorderProps,
		BackgroundProps,
		TypographyProps,
		FlexboxProps,
		ShadowProps {
	ref?: any;
	cursor?: string;
	borderRadius?: string | number;
	variant?: Variant;
	firstLetterCapital?: boolean;
	textDecoration?: string;
	textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'initial' | 'inherit';
	whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'initial' | 'inherit';
	wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word' | 'initial' | 'inherit';
	textOverflow?: 'ellipsis';
	truncate?: boolean;
	className?: any;
}

export const Box = styled.div<BoxProps>`
	position: relative;
	${space}
	${color}
	${layout}
	${background}
	${position}
	${grid}
	${border}
	${flexbox}
	${shadow}
	${variant({
		variants,
	})}
	${typography}
	${whiteSpace}
	${({ cursor }) =>
		cursor &&
		css`
			cursor: ${cursor};
		`}
	${({ truncate }) =>
		truncate &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`}
	${({ textTransform }) =>
		textTransform &&
		css`
			text-transform: ${textTransform};
		`}
	${({ textDecoration }) =>
		textDecoration &&
		css`
			text-decoration: ${textDecoration};
		`}
	${({ textTransform }) =>
		textTransform &&
		css`
			text-transform: ${textTransform};
		`}
	${({ firstLetterCapital }) =>
		firstLetterCapital &&
		css`
			&::first-letter {
				text-transform: uppercase;
			}
		`}
`;
