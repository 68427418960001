import moment from 'moment';
import { UserSubscriptionStatus } from 'typings/enums/users';

export function userBlock(user: any) {
	return (
		UserSubscriptionStatus.BLOCKED === user?.userSubscriptionStatus ||
		(UserSubscriptionStatus.INACTIVE === user?.status &&
			moment(user?.renewalDate).isAfter(moment()))
	);
}
