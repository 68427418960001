import { useNavigate } from 'react-router-dom';

import './terms.css';

import MemoLogoBeta from 'assets/icons/LogoBeta';
import { Box, Text } from 'atoms';

export const PP: React.FC = () => {
	const navigate = useNavigate();

	function goToHome() {
		navigate('/');
	}

	return (
		<Box paddingY="50px" paddingX="20px" className="tc-container" color="primary.50">
			<Box textAlign="center" mb="2rem" onClick={goToHome} cursor="pointer">
				{/* <MemoLogo width="15rem" height="2.5rem" /> */}
				<MemoLogoBeta width="15rem" height="2.5rem" />
			</Box>
			<Text
				fontFamily="semibold"
				fontSize="20px"
				color="primary.50"
				textAlign="center"
				marginBottom="30px"
			>
				Privacy Policy
			</Text>
			<Box paddingX="20px">
				<p>Online Privacy Statement - LAST UPDATED BY KO 08.05.21</p>
				<p>Last Reviewed Date: 07 05 2021</p>

				<h4 className="tc-sub-title">Introduction</h4>
				<p>
					‘eHuddle’; ‘we’; ‘us’; and ‘our’; means EHuddle LTD of Unit 2, City Limits,
					Danehill, Reading, Berkshire, England, RG6 4UP, company no: 13239544.
				</p>
				<p>
					eHuddle is a platform designed to facilitate online coaching between Coaches,
					Fellow Coaches, Supervisors and our Clients. The safety and security of your
					personal data is our paramount consideration. All parties who utilise eHuddle
					can share messages, session summaries, assignments, presentations and other
					coaching files, with the confidence that your data is safe in our hands.
				</p>
				<p>
					In order to provide our services to you, we must process personal data about
					you, or Personally Identifiable Information (PII). We are committed to
					maintaining the highest standards of compliance with regulatory environments.
				</p>
				<p>
					This Privacy Policy aims to explain to our Users of our Service and Website how
					we process personal data, so that you can make well-informed decisions regarding
					your legal rights. The policy also aims to outlay our duties and
					responsibilities in processing data about our clients and coaches.
				</p>

				<h4 className="tc-sub-title">When does this Privacy Policy apply?</h4>

				<p>
					This policy applies to the personal information that we collect, use or ask you
					to provide to help us create and manage your eHuddle account. We are the Data
					Controller of the data being processed, and instruct Data Processors on how to
					process your data on our behalf. This Privacy Policy applies as soon as you
					access our Services, visit our website, contact or engage with us.
				</p>
				<p>
					We may update this Privacy Policy from time to time to keep up with legislative
					or regulatory changes, and to ensure that it remains in-line with our business
					needs and obligations. Any update or modification of the current version of this
					Policy will be considered applicable from the time of its publication. If we
					make material changes, such as how we may use your personal information, we will
					notify you prior to the change becoming effective. Our notification will be via
					email or a notice to our home page and in order to obtain consent for new uses
					to your personal information, if required. We strongly suggest that you read our
					Terms and Conditions so you may understand the terms that may apply to the use
					of our Website and Service. Should you have any questions, comments or
					suggestions with regard to our privacy policy, we ask that you contact us at{' '}
					<a href="mailto:support@ehuddle.com">support@ehuddle.com</a>.
				</p>

				<p>
					Without affecting your rights under any current applicable legislation, this
					Privacy Statement is not contractual by nature and does not form part of your
					contract with us. By using our services or products, you are presumed to have
					read and understood this Privacy Statement.
				</p>

				<h4 className="tc-sub-title">Types of data that we collect</h4>

				<p>
					We strive to be as transparent about our practices concerning collection and
					usage of personal data, or PII (Personally Identifiable Information).
				</p>

				<div className="pl-30 ">
					<p>
						<strong>Contact Data: </strong>Full name, email address, phone number,
						address, preferred pronoun
					</p>
					<p>
						<strong>Client Data – </strong>Message content, session summaries, To Dos,
						notes of session, reflection, summary of the session, resources, schedule
					</p>
					<p>
						<strong>Coach Data - </strong>Training and personal development information,
						specialism, coach location (country), accreditations, session hours, message
						content, To Do lists, session/client notes (private to coach), uploads and
						attachments, Fellow Coaches, Supervisors, Triparties (where applicable),
						Type of User
					</p>
					<p>
						<strong>System Data – </strong>IP address, Device Type, Browser Type,
						Timestamp (log-ins/offs), Date and Times of messages sent
					</p>
					<p>
						<strong>Cookie Data – </strong>Session cookies for authentication purposes
						(Google); Whiteboard Functionality (Limnu)
					</p>
					<p>
						<strong>Marketing Data – </strong>email address, marketing preference (opt
						in/opt out), records of consent, list of unsubscribed clients and marketing
						leads
					</p>

					<p>
						<strong>Transaction Data – </strong>information about payments and
						subscriptions to the Services and past transaction history (via Stripe
						Payment Services)
					</p>
					<p>
						Information you provide to us during customer service interactions and to
						receive technical assistance from us
					</p>
					<p>
						<strong>Optional Data: </strong>messages, photos and videos, notes.
					</p>
					<p>
						<strong>Account data – </strong>eHuddle username and password in encrypted
						form only
					</p>
					<p>
						Aggregated, anonymised data or other information that does not identify
						individuals, for example information regarding our page views. App
						downloads, email delivery, test delivery and engagement.
					</p>
				</div>

				<h4 className="tc-sub-title">How we use the data we collect</h4>

				<p>
					We may use your data in the following means to deliver our service to you and
					maximise your user experience-
				</p>

				<h4 className="tc-sub-title">Where you are a client:</h4>

				<ul className="points">
					<li>
						<div>
							To provide you with our Service features including but not limited to:
						</div>
						<div className="outer-points">
							<p>
								- Video Conferencing Sessions (third party provider -{' '}
								<a
									href="https://quickblox.com/privacy-policy/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Quikblox
								</a>
								)
							</p>
							<p>
								- Instant Messages (Chat) (third party provider -{' '}
								<a
									href="https://quickblox.com/privacy-policy/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Quikblox
								</a>
								)
							</p>
							<p>
								- Communal Whiteboard Area (third party provider -{' '}
								<a
									href="https://limnu.com/privacy/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Limnu
								</a>
								)
							</p>
							<p>- Tasks/To Dos</p>
							<p>- Session Summaries</p>
							<p>- Resources (Offered by Client or Coach)</p>
							<p>- Pronoun</p>
						</div>
					</li>
					<li>
						We may retain a record of your sessions for coaches to provide evidence of
						coaching sessions conducted when they apply for accreditation from
						professional bodies.
					</li>
					<li>Create and manage your eHuddle account</li>
					<li>Allow coaches to verify your eligibility for the service</li>
					<li>
						Monitor your usage of our service for analytical purposes and to identify
						areas of potential development.
					</li>
					<li>Provide you with technical and customer support</li>
					<li>
						We retain anonymised data about your use of the system, for research
						purposes
					</li>
					<li>Provide you with service messages about our products and services</li>
					<li>
						We may use session cookies to authenticate your account. For more
						information on the use of Cookies, please see our Cookie Policy.
					</li>
				</ul>

				<h4 className="tc-sub-title">Where you are a Coach:</h4>

				<ul className="points">
					<li>
						Generate an ‘hours coached’ figure based on time entry by you for
						accreditation purposes
					</li>
					<li>Build a coaching profile about you to be displayed on the platform</li>
					<li>
						Facilitate arecord of coaching history for accreditation evidence purposes
					</li>
					<li>Onboarding Information for fellow coaches, supervisors and triparties</li>
					<li>
						Your diary availability, including any event notes, are made available to
						Clients for scheduling purposes
					</li>
				</ul>

				<h4 className="tc-sub-title">What grounds do we process your data under?</h4>
				<p>
					Both the UK and the EU General Data Protection Regulations (GDPRs) forbids the
					processing of personal data unless we are collecting and using your in line with
					one of 6 lawful bases. Please note that the personal data we maintain on the
					eHuddle platform is processed solely for the purposes of providing coaching
					Services to you. The lawful bases we rely on are:
				</p>

				<table className="info-table" aria-describedby="info">
					<thead>
						<tr>
							<th scope="left">Processing Activity</th>
							<th scope="right">Lawful Basis</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div>
									<p>
										<strong>Contact Data: </strong>Name, email address, contact
										number, date of birth
									</p>
									<p>
										<strong>Transaction Data: </strong>
										payment history, past transactions, account payment status,
										payments made to Stripe Payment Services (view via Stripe
										platform only)
									</p>
									<p>
										<strong>
											Client System Use Data (Facilitation and Hosting ONLY):{' '}
										</strong>
										session summaries, To Dos, diaries, messages,, reflections
										on sessions, resources, diary schedule
									</p>
									<p>
										<strong>
											Coach System Use Data (Facilitation and Hosting ONLY):{' '}
										</strong>
										coaching profile, qualifications, total hours coached, diary
										schedule
									</p>
								</div>
							</td>
							<td>
								<div>
									<p>
										<strong>Performance of a contract (Art 6(1)(b))</strong>
									</p>
									<p>
										Processing is necessary for the performance of a contract to
										which the date subject is party to, or in order to take
										steps at the request of the data subject prior to entering
										into a contract
									</p>
									<p>Create and manage your e-huddle account</p>
									<p>
										Allow coaches to verify you are over 16 year old and
										eligible for coaching.
									</p>
									<p>
										Provide you with technical support and assistance where
										necessary
									</p>
									<p>
										Provide you with service messages about our system i.e,
										system upgrades, system outage periods etc.
									</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div>
									<p>
										<strong>Marketing Data: </strong>email address for the
										purposes of serving marketing communications to potential
										clients
									</p>
								</div>
							</td>
							<td>
								<div>
									<p>
										<strong>Consent (Art 4(11))</strong>
									</p>
									<p>
										Specified, informed and unambiguous individuation of the
										data subject’s wishes by a statement or clear action,
										signifies agreement to the processing of personal data
										relating to them.
									</p>
									<p>
										We rely on Consent for direct marketing purposes. This
										consent must be well-informed and freely given and can be
										withdrawn by you at any time by contacting{' '}
										<a href="mailto:hello@eHuddle.net">hello@eHuddle.net</a> or
										by clicking ‘unsubscribe’ at the footer of any email
										correspondence you receive from us.
									</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div>
									<p>
										<strong>System Data (aggregated, anonymised data)</strong>
									</p>
									<p>
										System usage levels, system use common behaviours, analytics
										about system including peak time usage, performance levels,
										system development{' '}
									</p>
								</div>
							</td>
							<td>
								<div>
									<p>
										<strong>Legitimate Interests (6(1)(f))</strong>
									</p>
									<p>
										Processing is necessary for the purpose of the legitimate
										interest pursued by the Controller (eHuddle) or by a third
										party (coaches) where such interests override the interests
										or rights and freedoms of the data subject.
									</p>
									<p>
										To ensure the eHuddle system is available and performing
										optimally.{' '}
									</p>
									<p>
										Identify improvements and development opportunities for the
										system
									</p>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div>
									<p>
										<strong>Data Subject Rights Requests</strong>
									</p>
									<p>
										Correspondence between parties, original request, controller
										response, record of action taken and dates of each.{' '}
									</p>
									<p>
										<strong>Complaints and legal challenges</strong>{' '}
										Correspondence
									</p>
									<p>
										<strong>Financial Data</strong> relating to the eHuddle
										company (for tax reporting purposes to HMRC)
									</p>
									<p>Any data requiring us to process data under a court order</p>
								</div>
							</td>
							<td>
								<div>
									<p>
										<strong>Legal Obligation (Art 6(1)(b))</strong>
									</p>
									<p>
										Processing is necessary for compliance with a legal
										obligation to which the controller is subject
									</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>

				<h4 className="tc-sub-title">COPPA and Children’s Data</h4>
				<p>
					We do not process child data and do not offer the Service to any persons under
					16. However, if we extend the Service to minors in the future, we will only
					process child data in line with the provisions set out in the Children’s Online
					Privacy and Protection Act (USA “COPPA”), eHuddle rely on each User to obtain
					and retain verifiable records of appropriate parental consent for processing
					their child's data, which may be requested by eHuddle at any time. We are
					committed to ensuring that no data is processed about any living individual
					under the age of 16 without parental or guardian consent. Should we later
					discover that data relating to a child under 13 has been processed by us without
					consent being sought, we will securely destroy it immediately upon discovery.
				</p>

				<h4 className="tc-sub-title">How long do we keep your personal data?</h4>

				<p>
					We process your personal data only for as long as necessary to achieve the
					purposes for which it was originally collected. Once you feel you have finished
					coaching with us, we will delete your eHuddle account and the data involved, as
					appropriate.
				</p>

				<h4 className="tc-sub-title">Who do we share your data with?</h4>
				<p>
					We may share your data with third parties for the purposes of fulfilling our
					commitment to you. The PII that we collect and receive from a client or a coach
					(including fellow coaches, supervisors and triparties) is
					<strong>solely</strong> processed for the purposes of service provision, within
					the scope of this policy. Please note that we will never sell your data to a
					third party:
				</p>

				<ul className="extra-bottom-space">
					<li>
						eHuddle platform providers for the purposes of managing your account
						preferences beyond personal configuration
					</li>
					<li>
						Data processing companies and suppliers from time to time to help manage our
						website and services, analysing the use of our app and website, sending
						emails (Service Messages and marketing correspondence), communication
						platform providers (Audio and Video Conferencing Centres), website hosting
						providers, such as MongoDB Atlas or Amazon Web Services, providing technical
						support and communications to our clients and coaches.
					</li>
					<li>
						Payment Service Providers who assist us in processing your payment card
						details and transactions on our behalf, such as Stripe (PCI-DSS Compliant).
						Please note that payment details are processed directly by Stripe and are
						not visible to eHuddle.
					</li>
					<li>
						Technology service providers who host our information systems, backup
						servers or that offer us technological support. These parties will be
						subject to the terms of this Privacy Policy, our terms of Use and their own
						security and privacy obligations.
					</li>
					<li>
						Administrative, judicial and/or legal authorities in response to
						requirements, as long as they are required in accordance with the applicable
						law and regulations;
					</li>
					<li>
						Our legal advisors when a claim is presented in relation to our services and
						products.
					</li>
					<li>
						In the event that we sell or buy any business or assets, in which case we
						may need to disclose your data to the prospective seller, buyer or business
						partner.
					</li>
					<li>Submit Financial Data to our preferred accountants for tax reporting.</li>
					<li>
						Where we use a third party or service provider to provide our Service to
						you, we enter into written agreements with them as sub-processors, including
						specific data processing terms with the same level of protection as afforded
						by us.
					</li>
				</ul>

				<h4 className="tc-sub-title">International Transfers of Data</h4>

				<ul className="extra-bottom-space">
					<li>
						All our data is hosted in the United States (Amazon). For those users who
						are in a country or region outside of the United States, please be advised
						the laws governing your private information may differ significantly from
						those laws in the United States that govern the collection and securing of
						personal information. By submitting your Personal Data to us, you agree to
						the transfer, storage and processing of your Personal Data in the United
						States.
					</li>
					<li>
						Where we use certain service providers out with the EEA or process a EU
						citizen’s data, we may use specific contracts approved by the European
						Commission which give personal data the same protection as it has in Europe
						called Standard Contractual Clauses (SCCs).
					</li>
					<li>
						We ensure that all third party providers and Data Processors that we rely on
						have embedded Standard Contractual Clauses into their business model before
						we engage with them.
					</li>
				</ul>

				<h4 className="tc-sub-title">Security of your data</h4>

				<ul className="extra-bottom-space">
					<li>
						eHuddle has incorporated technical and organisational security measures in
						protecting PII. When client or coach data is transferred, it is done so in
						an encrypted nature and transmitted securely. Access to the eHuddle site is
						only through authentication of one’s username and password, which should
						remain confidential to the user.
						<strong>
							{' '}
							If a breach has occurred, you should notify eHuddle without undue delay
							by contacting
						</strong>{' '}
						<a href="mailto:hello@eHuddle.net">hello@eHuddle.net</a>.
					</li>
					<li>
						Your information is protected on a server that is behind a firewall; while
						utilising security software so as to maintain the confidentiality of the
						personal information we have accumulated. In the unlikely event of a
						security breach of a user’s personal information, we shall notify all users
						whose information may have been compromised, in accordance with all
						applicable laws and regulations and in our capacity as data controller.
					</li>
				</ul>

				<h4 className="tc-sub-title">Links to other sites</h4>
				<p>
					Our website may contain links to other third-party websites and services. This
					Privacy Policy does not apply to any links provided to you by anyone other than
					us. Third parties will appoint their own privacy policies and these should be
					consulted should you have any queries about how they process your data. We have
					no control over such third party websites and have any responsibility for their
					actions.
				</p>

				<h4 className="tc-sub-title">Your Rights</h4>
				<p>
					Any personal information you supply will be treated in accordance with
					applicable data protection laws including the GDPR (UK and EU) and any other
					applicable or superseding laws. We are committed to delivering the rights that
					individuals are entitled to and upholding a transparent approach in processing
					data. These are:
				</p>

				<ul className="extra-bottom-space">
					<li>
						The{' '}
						<strong>
							right to request a copy of the personal information we collected about
							you in the last 12 months (USA) and from the beginning of our
							relationship (UK and EU)
						</strong>
						. To do this, please contact us at hello@eHuddle.net to request a copy
						including full details of what you require. You may also be required to
						submit or demonstrate proof of your identity.
					</li>
					<li>
						<strong>The right to object (right to be forgotten)</strong> to your
						personal information being used for certain purposes. Where required, we
						ensure we will obtain your consent before undertaking marketing or data
						selling and you will always have the ability to opt out at any time.
					</li>
					<li>
						<strong>The right to rectification:</strong> You may request that we correct
						any inaccurate and/or complete any incomplete personal information. You may
						review, update, correct and add or delete your personal information in your
						account.{' '}
					</li>
					<li>
						<strong>The right to withdraw consent:</strong> Where we are processing your
						personal information on the basis that you have given us your consent To Do
						so, you may withdraw your consent at any time.
					</li>
					<li>
						<strong>The right of erasure:</strong> You may request that we erase your
						personal information and we will comply, unless there is a lawful reason for
						not doing so. For example, there may be an overriding legitimate ground for
						retaining your personal information, such as keeping your payment
						information for several years to comply with tax laws or protect eHuddle in
						any legal disputes.
					</li>
					<li>
						<strong>The right to data portability:</strong> Under GDPR, in certain
						circumstances, you may request that we provide your personal information to
						you in a structured, commonly used and machine readable format and have it
						transferred to another provider of the same or similar services to us. Where
						this right is applicable, we will comply with such transfer as far as it is
						technically feasible.
					</li>
				</ul>

				<h4 className="tc-sub-title">Other Rights and Responsibilities</h4>

				<ul className="extra-bottom-space">
					<li>
						eHuddle agrees to fulfil their data privacy obligations, including the
						fulfilment of data subject right requests. Coaches must assist eHuddle as
						appropriate upon receiving a DSR request.{' '}
					</li>
					<li>
						eHuddle will ensure that all company personnel with access to client or
						coach data, regardless of how limited that access may be, is subject to
						confidentiality clauses in line with data privacy laws.
					</li>
					<li>
						In the event that you submit to us a data subject access request, or require
						copies of your Data held by us, we will provide you with copies of requested
						data within a thirty (30) day period, starting on date of receipt
					</li>
					<li>
						We commit to ensuring that you will be notified in accordance with all
						applicable laws and regulations if a breach or unauthorised release of PII
						occurs. We will notify you by email as permitted by law if it is determined
						that a data security incident occurred.
					</li>
					<li>
						eHuddle will never require you or your child to disclose more information
						than is reasonably necessary to participate in an activity.
					</li>
					<li>
						Should you decide you no longer wish to receive marketing communication from
						us, you may unsubscribe or withdraw your consent by clicking on the
						unsubscribe link in footer of the EHuddle marketing email which was sent to
						you or reply to the email. Another option is to contact us at{' '}
						<a href="mailto:hello@eHuddle.net">hello@eHuddle.net</a>, and make this
						request. We will honor your request and respond to you within a reasonable
						timeframe.
					</li>
				</ul>

				<h4 className="tc-sub-title">
					The right to lodge a complaint with the supervisory authority
				</h4>
				<p>
					We suggest that you contact us about any questions or if you have a complaint in
					relation to how we process your personal information. You can reach us at
					hello@eHuddle.net. However, you have the right to lodge a complaint with a
					supervisory authority – in particular the supervisory authority of the Member
					State of your habitual residence, place of work, or place of the alleged
					infringement – if you believe that the processing of your Personal Data does not
					comply with legal requirements.
				</p>
				<p>
					A list of Data Protection Authorities within the European Union is available
					here:{' '}
				</p>
				<a
					href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
				</a>
				<p>
					For UK citizens, you have the right to lodge a complaint with the Information
					Commissioner's Office (ICO) if you are unsatisfied with our processing of your
					data (www.ico.org.uk)
				</p>
			</Box>
		</Box>
	);
};
