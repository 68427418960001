import * as React from 'react';

function SurveyIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="18"
			viewBox="0 0 15 18"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.0541 2.18189C11.45 2.21913 11.8443 2.262 12.2369 2.31043C13.3612 2.44911 14.167 3.41371 14.167 4.51659V15.75C14.167 16.9926 13.1596 18 11.917 18H2.41699C1.17435 18 0.166992 16.9926 0.166992 15.75V4.51659C0.166992 3.41371 0.97278 2.44911 2.09704 2.31043C2.48965 2.26201 2.88397 2.21913 3.2799 2.18189C3.63602 0.922667 4.79376 0 6.16699 0H8.16699C9.54023 0 10.698 0.922667 11.0541 2.18189ZM4.66699 3C4.66699 2.17157 5.33856 1.5 6.16699 1.5H8.16699C8.99542 1.5 9.66699 2.17157 9.66699 3V3.5H4.66699V3Z"
				fill="#6B7280"
			/>
		</svg>
	);
}

export const MemoSurveyIcon = React.memo(SurveyIcon);
export default MemoSurveyIcon;
