import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { VideoQuality } from '@zoom/videosdk';
import classnames from 'classnames';
import { Box, Text } from 'atoms';
import { Avatar as Ava } from 'antd';
import _ from 'lodash';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import { useShare } from './hooks/useShare';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useMount, useSizeCallback } from '../../hooks';
import { Participant } from '../../index-types';
import { SELF_VIDEO_ID } from './video-constants';
import { isShallowEqual } from '../../utils/util';
import { useLocalVolume } from './hooks/useLocalVolume';
import './video.scss';
import { useNetworkQuality } from './hooks/useNetworkQuality';

import useSessions from 'pages/Session/context/sessions';
import useAuth from 'pages/Auth/context/Authentication';

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
	const zmClient = useContext(ZoomContext);
	const {
		state: { currentSession },
		actions: { updateSession },
	} = useSessions();

	const {
		state: { currentPortal: userType },
	} = useAuth();

	useEffect(() => {
		if (userType == 'coach') {
			const url = window.location.pathname;
			const sessionId = url.substring(url.lastIndexOf('/') + 1);

			const payload = {
				status: currentSession?.status || 1,
			};
			updateSession(sessionId, payload);
		}
	}, [userType]);
	const {
		mediaStream,
		video: { decode: isVideoDecodeReady },
	} = useContext(ZoomMediaContext);
	const videoRef = useRef<HTMLCanvasElement | null>(null);
	const shareRef = useRef<HTMLCanvasElement | null>(null);
	const selfShareRef = useRef<HTMLCanvasElement & HTMLVideoElement>(null);
	const shareContainerRef = useRef<HTMLDivElement | null>(null);
	const [participants, setParticipants] = useState<Participant[]>([]);
	const [activeVideo, setActiveVideo] = useState<number>(0);
	const [chat, setChat] = useState(false);
	const [isStartedVideo, setIsStartedVideo] = useState(false);

	const previousActiveUser = useRef<Participant>();
	const canvasDimension = useCanvasDimension(mediaStream, videoRef);
	const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(
		zmClient,
		mediaStream,
		shareRef
	);
	const isSharing = isRecieveSharing || isStartedShare;
	const [containerDimension, setContainerDimension] = useState({
		width: 0,
		height: 0,
	});
	const [shareViewDimension, setShareViewDimension] = useState({
		width: 0,
		height: 0,
	});
	const { userVolumeList, setLocalVolume } = useLocalVolume();
	const networkQuality = useNetworkQuality(zmClient);

	useParticipantsChange(zmClient, (payload) => {
		setParticipants(payload);
	});
	const videoPreview = (value: any) => {
		setIsStartedVideo(value);
	};
	const onActiveVideoChange = useCallback((payload) => {
		const { userId } = payload;
		setActiveVideo(userId);
	}, []);
	const chatOpened = (value: boolean) => {
		console.log('value changed: ', value);
		setChat(value);
	};
	useEffect(() => {
		zmClient.on('video-active-change', onActiveVideoChange);
		return () => {
			zmClient.off('video-active-change', onActiveVideoChange);
		};
	}, [zmClient, onActiveVideoChange]);

	const activeUser = useMemo(
		() => participants.find((user) => user.userId === activeVideo),
		[participants, activeVideo]
	);
	const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
	useEffect(() => {
		if (mediaStream && videoRef.current && isVideoDecodeReady) {
			if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
				if (activeUser?.bVideoOn) {
					mediaStream.renderVideo(
						videoRef.current,
						activeUser.userId,
						canvasDimension.width,
						canvasDimension.height,
						0,
						0,
						VideoQuality.Video_360P as any
					);
				} else {
					if (previousActiveUser.current?.bVideoOn) {
						mediaStream.stopRenderVideo(
							videoRef.current,
							previousActiveUser.current?.userId
						);
					}
				}
			}
			if (
				activeUser?.bVideoOn &&
				previousActiveUser.current?.bVideoOn &&
				activeUser.userId !== previousActiveUser.current.userId
			) {
				mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
				mediaStream.renderVideo(
					videoRef.current,
					activeUser.userId,
					canvasDimension.width,
					canvasDimension.height,
					0,
					0,
					VideoQuality.Video_360P as any
				);
			}
			previousActiveUser.current = activeUser;
		}
	}, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension]);
	useMount(() => {
		if (mediaStream) {
			setActiveVideo(mediaStream.getActiveVideoId());
		}
	});
	useEffect(() => {
		if (isSharing && shareContainerRef.current) {
			const { width, height } = sharedContentDimension;
			const { width: containerWidth, height: containerHeight } = containerDimension;
			const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
			setShareViewDimension({
				width: Math.floor(width * ratio),
				height: Math.floor(height * ratio),
			});
		}
	}, [isSharing, sharedContentDimension, containerDimension]);

	const onShareContainerResize = useCallback(({ width, height }) => {
		_.throttle(() => {
			setContainerDimension({ width, height });
		}, 50)();
	}, []);
	useSizeCallback(shareContainerRef.current, onShareContainerResize);
	useEffect(() => {
		if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
			mediaStream?.updateSharingCanvasDimension(
				shareViewDimension.width,
				shareViewDimension.height
			);
		}
	}, [mediaStream, sharedContentDimension, shareViewDimension]);
	return (
		<div className={`viewport ${chat ? 'w-50' : ''}`}>
			<div
				className={classnames('share-container', {
					'in-sharing': isSharing,
				})}
				ref={shareContainerRef}
			>
				<div
					className="share-container-viewport"
					style={{
						width: `${shareViewDimension.width}px`,
						height: `${shareViewDimension.height}px`,
					}}
				>
					<canvas
						className={classnames('share-canvas', { hidden: isStartedShare })}
						ref={shareRef}
					/>
					{mediaStream?.isStartShareScreenWithVideoElement() ? (
						<video
							className={classnames('share-canvas', { hidden: isRecieveSharing })}
							ref={selfShareRef}
						/>
					) : (
						<canvas
							className={classnames('share-canvas', { hidden: isRecieveSharing })}
							ref={selfShareRef}
						/>
					)}
				</div>
			</div>
			<div
				className={classnames('video-container', {
					'in-sharing': isSharing,
				})}
			>
				<canvas
					className="video-canvas"
					id="video-canvas"
					width="800"
					height="600"
					ref={videoRef}
				/>
				{mediaStream?.isRenderSelfViewWithVideoElement() ? (
					<video
						id={SELF_VIDEO_ID}
						className={classnames('self-video', {
							'single-self-video': participants.length === 1,
							'self-video-show': isCurrentUserStartedVideo,
						})}
					/>
				) : (
					<canvas
						id={SELF_VIDEO_ID}
						width="254"
						height="143"
						className={classnames('self-video', {
							'single-self-video': participants.length === 1,
							'self-video-show': isCurrentUserStartedVideo,
						})}
					/>
				)}
				{activeUser && (
					<Avatar
						participant={activeUser}
						isActive={false}
						className="single-view-avatar"
						volume={userVolumeList.find((u) => u.userId === activeUser.userId)?.volume}
						setLocalVolume={setLocalVolume}
						networkQuality={networkQuality[`${activeUser.userId}`]}
					/>
				)}
			</div>
			{/* {
        participants === 2 && (
          <Box
              width="15%"
              position= "absolute"
              bottom="70px"
              right="30px"
            >
              <Box position="relative">
                <video id="js-preview-video1" className="self-preview" muted={true} data-video="0" />
                <Text
                  color="#ffffffcc"
                  position="absolute"
                  bottom="6px"
                  left="6px"
                  fontWeight="600"
                  textShadow="0px 0px 3px #1f1f1f"
                >{name}
                </Text>
                {
                  !isStartedVideo && (
                    <Box
                      position= "absolute"
                      top="calc( 50% - 40px)"
                      left="calc( 50% - 40px)"
                  >
                    <Text
                      as="h5"
                        color="#ffffffcc"
                        position="absolute"
                        bottom="6px"
                        left="6px"
                        fontWeight="600"
                      >{name}
                      </Text>
                    </Box>
                  )
                }
              </Box>
          </Box>
        )
      } */}
			<VideoFooter
				className="video-operations"
				sharing
				shareRef={selfShareRef}
				chatOpened={chatOpened}
				videoPreview={videoPreview}
			/>
		</div>
	);
};

export default VideoContainer;
