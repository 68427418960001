import * as React from 'react';

function ReportIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="18"
			viewBox="0 0 22 18"
			fill="none"
		>
			<path
				d="M2.375 0C1.33947 0 0.5 0.839466 0.5 1.875V2.625C0.5 3.66053 1.33947 4.5 2.375 4.5H19.625C20.6605 4.5 21.5 3.66053 21.5 2.625V1.875C21.5 0.839467 20.6605 0 19.625 0H2.375Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.08679 6L2.62657 15.1762C2.71984 16.7619 4.03296 18 5.62139 18H16.3783C17.9667 18 19.2799 16.7619 19.3731 15.1762L19.9129 6H2.08679ZM8.24976 9.75C8.24976 9.33579 8.58554 9 8.99976 9H12.9998C13.414 9 13.7498 9.33579 13.7498 9.75C13.7498 10.1642 13.414 10.5 12.9998 10.5H8.99976C8.58554 10.5 8.24976 10.1642 8.24976 9.75Z"
				fill="white"
			/>
		</svg>
	);
}

export const MemoReportIcon = React.memo(ReportIcon);
export default MemoReportIcon;
