import MemoCloseIcon from 'assets/icons/CloseIcon';
import { Box, Button, Flex, Modal, Text, Icon } from 'atoms';

export type AskConfirmationProps = {
	title: string;
	message: string | any;
	visible: boolean;
	onClose: () => void;
	onOk: () => void;
	onConfirm?: () => void;
	onCloseText?: string;
	onConfirmText?: string;
	onOkText?: string;
	showCloseIcon?: boolean;
	showDeleteIcon?: boolean;
	wrapActionButton?: boolean;
};

export const AskConfirmation = ({
	title,
	message,
	onClose,
	onOk,
	onConfirm,
	visible,
	onCloseText,
	onConfirmText,
	onOkText,
	showCloseIcon = false,
	showDeleteIcon = true,
	wrapActionButton = false,
}: AskConfirmationProps) => {
	return (
		<Modal isOpen={visible} onClose={onClose} minWidthPct={30} zIndex={10000}>
			<Box p="30px">
				{showCloseIcon && (
					<Flex justifyContent="flex-end" alignItems="center">
						<MemoCloseIcon
							fill="black"
							stroke="black"
							style={{ cursor: 'pointer' }}
							onClick={onClose}
						/>
					</Flex>
				)}
				<Flex alignItems="center" mb={!message ? '1.5rem' : ''}>
					<Box>
						<Text
							as="h4"
							fontSize="h4"
							mb="0"
							fontFamily="Inter-Bold"
							color="primary.50"
						>
							{title}
						</Text>
					</Box>
				</Flex>
				{message && (
					<Flex flexDirection="column">
						<Text mt="15px" fontSize="16px" mb="3rem">
							{message}
						</Text>
					</Flex>
				)}
				<Flex
					display={{
						xs: wrapActionButton ? 'block!important' : 'flex!important',
						sm: 'flex!important',
					}}
					alignItems="center"
					flexDirection="row"
				>
					{onConfirm && (
						<Button
							variant="transparent"
							px="20px"
							border="1px solid"
							borderColor="secondary.50"
							mr="2rem"
							onClick={onConfirm}
						>
							{onConfirmText ? onConfirmText : 'Confirm'}
						</Button>
					)}{' '}
					<Button type="submit" onClick={onOk}>
						<Flex>
							{showDeleteIcon && (
								<Icon name="deleteIcon" width="1.3rem" mr="1.5rem" />
							)}{' '}
							{onOkText || 'OK'}
						</Flex>{' '}
					</Button>
					{!onConfirm && (
						<Button
							variant="secondary"
							ml={wrapActionButton ? 0 : '1.6rem'}
							mt={wrapActionButton ? '1rem' : 'unset'}
							width={wrapActionButton ? '75%' : 'unset'}
							onClick={onClose}
						>
							{onCloseText || 'Cancel'}
						</Button>
					)}
				</Flex>
			</Box>
		</Modal>
	);
};
