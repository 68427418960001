import { FC, useEffect, useState, useContext } from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';

import { Box, Button, Text } from 'atoms';
import { FormInput } from 'molecules/FormInput';
import { FormContext } from '../RegisterPage';
import { useFormSubmitWithLoading } from 'hooks/useFormSubmitWithLoading';
import { ALPHANUMERIC_HYPHEN } from 'settings/constants';

export const generalValidationRules = () => {
	return yup.object().shape({
		code: yup.string().required('Code is required').matches(ALPHANUMERIC_HYPHEN, {
			message: 'Alphanumeric characters only allowed',
		}),
	});
};

type formValuesType = {
	code?: string;
};

export type SignupFormProps = {
	onSubmit?: (
		values?: formValuesType,
		formikHelpers?: FormikHelpers<formValuesType>
	) => void | Promise<void>;
	defaultInitialValues?: any;
	invitationCode?: any;
	userType?: string;
};

const ClientGetCode: FC<SignupFormProps> = ({ onSubmit, userType = 'coach' }) => {
	const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
	const { activeStepIndex, formData }: any = useContext(FormContext);

	function getInitValue() {
		if (formData) {
			return {
				code: formData?.code || undefined,
			};
		} else {
			return {
				code: undefined,
			};
		}
	}

	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

	const [initialValue, setInitialValue] = useState<any>(getInitValue());

	useEffect(() => {
		setInitialValue(getInitValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStepIndex]);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValue}
			validationSchema={generalValidationRules()}
			onSubmit={async (formValues, actions) => {
				// Double click 2 time api trigger issue fix
				//await delay(500);

				onSubmitHandler(formValues, actions);
				setTimeout(() => {
					actions.setSubmitting(false);
				}, 1000);
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<Box mt="3rem">
						<Text
							fontSize="23px"
							color="primary.50"
							my="0.7rem"
							fontFamily="Archivo-Bold"
							mb="2rem"
						>
							Welcome
						</Text>

						<Text fontSize="16px" mb="1.2rem">
							To get started please enter the access code you received from your
							organisation.
						</Text>

						<FormInput name="code" placeholder="Code" autoComplete="off" />

						<Box mb="4rem">
							<Button
								type="submit"
								fontSize="16px"
								my="1rem"
								height="41px"
								width="100%"
								loading={loading}
								disabled={isSubmitting}
							>
								Continue
							</Button>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
export default ClientGetCode;
