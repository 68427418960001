import AdminDashboardIcon from 'assets/icons/AdminDashboardIcon';
import ArrowDown from 'assets/icons/ArrowDown';
import ArrowUp from 'assets/icons/ArrowUp';
import CalendarIcon from 'assets/icons/Calendar';
import ChevronLeft from 'assets/icons/ChevronLeft';
import ChevronRight from 'assets/icons/ChevronRight';
import ChevronDown from 'assets/icons/ChevronDown';
import DeleteIcon from 'assets/icons/DeleteIcon';
import Diary from 'assets/icons/Diary';
import EmailIcon from 'assets/icons/Email';
import Exercise from 'assets/icons/Exercise';
import EyeCloseIcon from 'assets/icons/EyeCloseIcon';
import EyeOpenIcon from 'assets/icons/EyeOpenIcon';
import EditIcon from 'assets/icons/EditIcon';
import FileIcon from 'assets/icons/FileIcon';
import MemoMatching from 'assets/icons/MatchingIcon';

import GlobeIcon from 'assets/icons/GlobeIcon';
import ListIcon from 'assets/icons/ListIcon';
import MemoLogo from 'assets/icons/Logo';
import LogoutIcon from 'assets/icons/LogoutIcon';
import MailIcon from 'assets/icons/MailIcon';
import MaximizeIcon from 'assets/icons/MaximizeIcon';
import MinimizeIcon from 'assets/icons/MinimizeIcon';
import NotificationsIcon from 'assets/icons/NotificationsIcon';
import PhoneIcon from 'assets/icons/Phone';
import { ProfileIcon } from 'assets/icons/ProfileIcon';
import ReplyIcon from 'assets/icons/ReplyIcon';
import MemoInfoIcon from 'assets/icons/InfoIcon';
import MemoSearchIcon from 'assets/icons/SearchIcon';
import MemoThreeDots from 'assets/icons/ThreeDots';
import AttachmentIcon from 'assets/icons/AttachmentIcon';
import RefreshIcon from 'assets/icons/RefreshIcon';
import {
	MemoCoachesIcon,
	MemoCouponIcon,
	MemoHomeIcon,
	MemoMessageIcon,
	MemoReportIcon,
	MemoResourcesIcon,
	MemoScheduleIcon,
	MemoSessionIcon,
	MemoSettingsIcon,
	MemoSignOffIcon,
	MemoTodoIcon,
	MemoUsersIcon,
	MemoFaqIcon,
	MemoSupportIcon,
	MemoDashboardIcon,
	MemoTasksIcon,
	MemoTagIcon,
	MemoSchoolIcon,
	MemoCohortIcon,
	MemoOrganizationIcon,
} from 'assets/icons/Sidebar';

import {
	MemoContractIcon,
	MemoJournallingIcon,
	MemoTemplateIcon,
	MemoSurveyIcon,
} from 'assets/icons/Tasks';

import VideoIcon from 'assets/icons/Meeting/VideoIcon';
import AudioIcon from 'assets/icons/Meeting/AudioIcon';
import ShareScreenIcon from 'assets/icons/Meeting/ShareScreenIcon';
import ChatIcon from 'assets/icons/Meeting/ChatIcon';

import UsersIcon from 'assets/icons/UsersIcon';
import MemoUserIcon from 'assets/icons/UserIcon';
import MemoDocPlusIcon from 'assets/icons/DocPlusIcon';
import MemoHeadedArrow from 'assets/icons/HeadedArrow';
import { PlusIcon } from 'assets/icons/PlusIcon';
import MemoTickWithCircle from 'assets/icons/TickWithCircle';
import UploadIcon from 'assets/icons/Upload';
import { Box, BoxProps } from 'atoms/Box';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';

interface IconWrapperProps extends BoxProps {
	color?: string;
	disabled?: boolean;
	type?: string;
	style?: any;
}

export const IconWrapper = styled(Box)<IconWrapperProps>`
	display: grid;
	opacity: ${(props) => (props.disabled ? 0.25 : 1)};
	& > svg {
		height: 100%;
		width: 100%;
		${system({
			fill: {
				property: 'fill',
				scale: 'colors',
			},
		})}
	}
`;

export const icons = {
	attachments: AttachmentIcon,
	chevronRight: ChevronRight,
	chevronLeft: ChevronLeft,
	ChevronDown: ChevronDown,
	profile: ProfileIcon,
	plus: PlusIcon,
	logo: MemoLogo,
	logout: LogoutIcon,
	usersIcon: UsersIcon,
	fileIcon: FileIcon,
	listIcon: ListIcon,
	globeIcon: GlobeIcon,
	adminDashboardIcon: AdminDashboardIcon,
	mailIcon: MailIcon,
	notificationsIcon: NotificationsIcon,
	eyeOpenIcon: EyeOpenIcon,
	deleteIcon: DeleteIcon,
	eyeCloseIcon: EyeCloseIcon,
	arrowUp: ArrowUp,
	arrowDown: ArrowDown,
	email: EmailIcon,
	phone: PhoneIcon,
	maximizeIcon: MaximizeIcon,
	minimizeIcon: MinimizeIcon,
	replyIcon: ReplyIcon,
	calendarIcon: CalendarIcon,
	diary: Diary,
	exercise: Exercise,
	home: MemoHomeIcon,
	messages: MemoMessageIcon,
	reports: MemoReportIcon,
	resources: MemoResourcesIcon,
	schedules: MemoScheduleIcon,
	sessions: MemoSessionIcon,
	settings: MemoSettingsIcon,
	signoff: MemoSignOffIcon,
	todo: MemoTodoIcon,
	users: MemoUsersIcon,
	faq: MemoFaqIcon,
	support: MemoSupportIcon,
	dashboard: MemoDashboardIcon,
	tasks: MemoTasksIcon,
	coupons: MemoCouponIcon,
	coaches: MemoCoachesIcon,
	info: MemoInfoIcon,
	user: MemoUserIcon,
	docplus: MemoDocPlusIcon,
	headedArrow: MemoHeadedArrow,
	tag: MemoTagIcon,
	school: MemoSchoolIcon,
	cohort: MemoCohortIcon,
	organization: MemoOrganizationIcon,
	tickCicle: MemoTickWithCircle,
	contract: MemoContractIcon,
	journaling: MemoJournallingIcon,
	template: MemoTemplateIcon,
	survey: MemoSurveyIcon,
	search: MemoSearchIcon,
	edit: EditIcon,
	threeDots: MemoThreeDots,
	matching: MemoMatching,
	video: VideoIcon,
	audio: AudioIcon,
	shareScreen: ShareScreenIcon,
	chat: ChatIcon,
	refresh: RefreshIcon,
	upload: UploadIcon,
};

export interface IconProps extends IconWrapperProps {
	name: keyof typeof icons;
	fill?: string;
	onClick?: () => void;
	isselected?: boolean;
	title?: string;
	disabled?: boolean;
	type?: string;
	stroke?: string;
}

export const Icon: FC<IconProps> = ({
	name,
	height,
	width,
	isselected,
	type,
	stroke,
	...props
}: IconProps) => {
	const IconSVG: any = useMemo(() => icons[name], [name]) as any;

	return (
		<IconWrapper height={height} width={width} {...props}>
			{isselected && (
				<Box
					bg="secondary.0"
					width="5px"
					height="5px"
					borderRadius="2.5px"
					position="absolute"
					top="45%"
					left="-50%"
				/>
			)}
			<IconSVG
				key={name}
				isselected={isselected}
				fill={props?.fill}
				type={type}
				stroke={stroke}
			/>
		</IconWrapper>
	);
};
