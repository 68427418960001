import * as React from 'react';

function ChevronDown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="9"
			viewBox="0 0 16 9"
			fill="none"
		>
			<path
				d="M15 1L8 8L1 1"
				stroke="#4BB7BA"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

const MemoChevronDown = React.memo(ChevronDown);
export default MemoChevronDown;
