import { Box, BoxProps } from 'atoms';
import { FC } from 'react';

export interface BodyContainerProps extends BoxProps {
	bg?: string;
	width?: any;
	minWidth?: any;
	mb?: string;
}

export const BodyContainer: FC<BodyContainerProps> = ({
	children,
	width = '100%',
	bg = 'white.0',
	minWidth,
	mb = '0',
}) => {
	return (
		<Box overflowX="hidden" overflowY="auto" width={width} minWidth={minWidth} bg={bg} mb={mb}>
			{children}
		</Box>
	);
};
