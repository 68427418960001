import * as React from 'react';
import IconProps from './IconProps';
function MessageIcon({ isselected, type, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="22"
				height="20"
				viewBox="0 0 22 20"
				fill="none"
			>
				<path
					d="M3.91307 0.658231C5.9877 0.38888 8.10296 0.25 10.2503 0.25C12.3974 0.25 14.5124 0.388852 16.5869 0.65815C18.5091 0.907693 19.8783 2.51937 19.9923 4.38495C19.6665 4.27614 19.3212 4.20396 18.96 4.17399C17.5715 4.05874 16.1673 4 14.75 4C13.3326 4 11.9285 4.05874 10.54 4.17398C8.1817 4.36971 6.5 6.36467 6.5 8.60821V12.8937C6.5 14.5844 7.45468 16.1326 8.9328 16.8779L6.28033 19.5303C6.06583 19.7448 5.74324 19.809 5.46299 19.6929C5.18273 19.5768 5 19.3033 5 19V14.9705C4.63649 14.9316 4.27417 14.8887 3.91308 14.8418C1.90466 14.581 0.5 12.8333 0.5 10.8626V4.63738C0.5 2.66672 1.90466 0.918985 3.91307 0.658231Z"
					fill={props?.fill || 'white'}
				/>
				<path
					d="M14.75 5.5C13.3741 5.5 12.0114 5.55702 10.6641 5.66884C9.12476 5.7966 8 7.10282 8 8.60821V12.8937C8 14.4014 9.12797 15.7083 10.6692 15.8341C11.9131 15.9357 13.17 15.9912 14.4384 15.999L17.2197 18.7803C17.4342 18.9948 17.7568 19.059 18.037 18.9429C18.3173 18.8268 18.5 18.5533 18.5 18.25V15.8601C18.6103 15.8518 18.7206 15.8432 18.8307 15.8342C20.372 15.7085 21.5 14.4015 21.5 12.8938V8.60822C21.5 7.10283 20.3752 5.79661 18.836 5.66885C17.4886 5.55702 16.1259 5.5 14.75 5.5Z"
					fill={props?.fill || 'white'}
				/>
			</svg>
		);
	} else if (type === 'single') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="15"
				viewBox="0 0 16 15"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 7C0 3.0334 3.69006 0 8 0C12.3099 0 16 3.0334 16 7C16 10.9666 12.3099 14 8 14C7.48658 14 6.98381 13.9577 6.49617 13.8766C5.51177 14.5834 4.3037 15 3 15C2.85237 15 2.70585 14.9947 2.56065 14.9841C2.29888 14.9651 2.06608 14.8107 1.9468 14.5769C1.82753 14.3431 1.83914 14.064 1.97742 13.8409C2.29476 13.329 2.48371 12.7294 2.49899 12.0848C0.978492 10.8253 0 9.02442 0 7Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="20"
			viewBox="0 0 22 20"
			fill="none"
		>
			<path
				d="M3.91307 0.658231C5.9877 0.38888 8.10296 0.25 10.2503 0.25C12.3974 0.25 14.5124 0.388852 16.5869 0.65815C18.5091 0.907693 19.8783 2.51937 19.9923 4.38495C19.6665 4.27614 19.3212 4.20396 18.96 4.17399C17.5715 4.05874 16.1673 4 14.75 4C13.3326 4 11.9285 4.05874 10.54 4.17398C8.1817 4.36971 6.5 6.36467 6.5 8.60821V12.8937C6.5 14.5844 7.45468 16.1326 8.9328 16.8779L6.28033 19.5303C6.06583 19.7448 5.74324 19.809 5.46299 19.6929C5.18273 19.5768 5 19.3033 5 19V14.9705C4.63649 14.9316 4.27417 14.8887 3.91308 14.8418C1.90466 14.581 0.5 12.8333 0.5 10.8626V4.63738C0.5 2.66672 1.90466 0.918985 3.91307 0.658231Z"
				fill={props?.fill || 'white'}
			/>
			<path
				d="M14.75 5.5C13.3741 5.5 12.0114 5.55702 10.6641 5.66884C9.12476 5.7966 8 7.10282 8 8.60821V12.8937C8 14.4014 9.12797 15.7083 10.6692 15.8341C11.9131 15.9357 13.17 15.9912 14.4384 15.999L17.2197 18.7803C17.4342 18.9948 17.7568 19.059 18.037 18.9429C18.3173 18.8268 18.5 18.5533 18.5 18.25V15.8601C18.6103 15.8518 18.7206 15.8432 18.8307 15.8342C20.372 15.7085 21.5 14.4015 21.5 12.8938V8.60822C21.5 7.10283 20.3752 5.79661 18.836 5.66885C17.4886 5.55702 16.1259 5.5 14.75 5.5Z"
				fill={props?.fill || 'white'}
			/>
		</svg>
	);
}

export const MemoMessageIcon = React.memo(MessageIcon);
export default MemoMessageIcon;
