import * as React from 'react';

function DocPlusIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="16"
			viewBox="0 0 14 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.5 0C0.671573 0 0 0.671573 0 1.5V14.5C0 15.3284 0.671573 16 1.5 16H12.5C13.3284 16 14 15.3284 14 14.5V5.62132C14 5.2235 13.842 4.84197 13.5607 4.56066L9.43934 0.43934C9.15804 0.158035 8.7765 0 8.37868 0H1.5ZM7 6C7.41421 6 7.75 6.33579 7.75 6.75V8.25H9.25C9.66421 8.25 10 8.58579 10 9C10 9.41421 9.66421 9.75 9.25 9.75H7.75V11.25C7.75 11.6642 7.41421 12 7 12C6.58579 12 6.25 11.6642 6.25 11.25V9.75H4.75C4.33579 9.75 4 9.41421 4 9C4 8.58579 4.33579 8.25 4.75 8.25H6.25V6.75C6.25 6.33579 6.58579 6 7 6Z"
				fill={props?.fill || '#4B5563'}
			/>
		</svg>
	);
}

const MemoDocPlusIcon = React.memo(DocPlusIcon);
export default MemoDocPlusIcon;
