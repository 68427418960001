import * as React from 'react';
import editImg from 'assets/icons/png/edit.png';

function EditIcon(props: React.SVGProps<SVGSVGElement>) {
	if (props?.type === 'img') return <img id="edit" src={editImg} alt="edit" />;
	if (props?.type === 'inline') {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="25"
				viewBox="0 0 24 25"
				fill="none"
			>
				<mask
					id="mask0_339_8406"
					//style="mask-type:alpha"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="24"
					height="25"
				>
					<rect y="0.5" width="24" height="24" fill="#D9D9D9" />
				</mask>
				<g mask="url(#mask0_339_8406)">
					<path
						d="M2 24.5002V20.5002H22V24.5002H2ZM4 18.5002V14.7502L13.05 5.70018L16.8 9.45018L7.75 18.5002H4ZM6 16.5002H6.9L14 9.45018L13.05 8.50018L6 15.6002V16.5002ZM17.925 8.35018L14.175 4.60018L15.975 2.80018C16.1583 2.60018 16.3917 2.50418 16.675 2.51218C16.9583 2.52085 17.1917 2.61685 17.375 2.80018L19.725 5.15018C19.9083 5.33351 20 5.56285 20 5.83818C20 6.11285 19.9083 6.35018 19.725 6.55018L17.925 8.35018Z"
						fill="#6B7280"
					/>
				</g>
			</svg>
		);
	}
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.5 1.50003C8.63132 1.36871 8.78722 1.26454 8.9588 1.19347C9.13038 1.1224 9.31428 1.08582 9.5 1.08582C9.68572 1.08582 9.86962 1.1224 10.0412 1.19347C10.2128 1.26454 10.3687 1.36871 10.5 1.50003C10.6313 1.63135 10.7355 1.78725 10.8066 1.95883C10.8776 2.13041 10.9142 2.31431 10.9142 2.50003C10.9142 2.68575 10.8776 2.86964 10.8066 3.04123C10.7355 3.21281 10.6313 3.36871 10.5 3.50003L3.75 10.25L1 11L1.75 8.25003L8.5 1.50003Z"
				fill={props.fill || '#fff'}
				stroke={props.stroke || '#0C0047'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const MemoEditIcon = React.memo(EditIcon);
export default MemoEditIcon;
