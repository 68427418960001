import MemoCloseIcon from 'assets/icons/CloseIcon';
import MemoDeleteIcon from 'assets/icons/DeleteIcon';
import { Box, BoxProps, Flex, Text } from 'atoms';
import React from 'react';

export interface ActionableAlertProps extends BoxProps {
	selectionCount?: number;
	onCancel?: () => void;
	onDelete?: () => void;
}

export const ActionableAlert: React.FC<ActionableAlertProps> = ({
	bg = 'secondary.0',
	left = 'calc(50% - 200px)',
	selectionCount = 0,
	onCancel,
	onDelete,
	...props
}: ActionableAlertProps) => {
	return (
		<Box
			bg={bg}
			position="fixed"
			bottom="40px"
			width="400px"
			left={left}
			px="30px"
			py="10px"
			borderRadius="10px"
			zIndex={100}
		>
			<Flex p="10px" justifyContent="space-around" alignItems="center">
				<Flex alignItems="center" cursor="pointer" onClick={onCancel}>
					<MemoCloseIcon stroke="white" />
				</Flex>
				<Text
					as="p"
					p="0"
					m="0"
					color="white.0"
					px="30px"
					fontSize="16px"
					borderX="1px solid #efefef"
					fontFamily="regular"
				>
					<Text
						as="span"
						mr="10px"
						borderRadius="50%"
						backgroundColor="white.0"
						color="primary.0"
						width="36px"
						height="36px"
						lineHeight="36px"
						display="inline-block"
						textAlign="center"
						fontWeight="medium"
						fontFamily="medium"
					>
						{selectionCount}
					</Text>
					Items Selected
				</Text>
				<Flex alignItems="center" mt="-5px" cursor="pointer" onClick={onDelete}>
					<MemoDeleteIcon />
				</Flex>
			</Flex>
		</Box>
	);
};
