import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

export const SESSIONS: string = `/meeting-sessions`;
export const USERS: string = `/users`;
export const TAGS: string = `/tag/tag-type`;
export const PROFILE: string = `/users/profile-management`;

const initScheduleSession = async () => {
	let user = localStorage.hasOwnProperty('user')
		? JSON.parse(localStorage.getItem('user'))
		: null;
	if (user) {
		const id = user?.user ? user.user._id : user._id;
		const response = await axios.get(
			`${process.env.REACT_APP_API_HOST}/meeting-sessions/coaches/${id}/types-locations`
		);
		return response?.data;
	}
};
const getSessionById = async (id: string) => {
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_HOST}${SESSIONS}/${id}`);
		return res.data.meetingSession;
	} catch (e) {
		message.error('Unable to get this Session. Please reload the page');
	}
};
const getSessionTypes = async () => {
	let user = localStorage.hasOwnProperty('user')
		? JSON.parse(localStorage.getItem('user'))
		: null;
	if (user) {
		const id = user?.user ? user.user._id : user._id;
		const response = await axios.get(`${process.env.REACT_APP_API_HOST}/meeting-session-types`);
		const sessionTypes = response?.data?.meetingSessionTypes?.map((obj: any) => {
			obj.label = obj.name;
			obj.value = obj._id;
			return obj;
		});
		return sessionTypes;
	}
};
const getCoacheeList = async () => {
	try {
		const users = await axios.get(
			`${process.env.REACT_APP_API_HOST}/users?all=true&page=1&filter=status%3Dactive&userType=coachee`
		);
		const allCoachees = users?.data?.data
			?.filter((user: any) => user.firstName !== '' && user.lastName !== '')
			?.map((user: any) => {
				let obj = {
					name: `${user.firstName} ${user.lastName}`,
					email: user.email,
					id: user._id,
					img:
						user?.images?.basePath !== ''
							? `${user?.images?.basePath}/${user?.images?.directoryName}/${user?.images?.fileName}`
							: '',
					org: user?.organization,
				};
				return obj;
			});
		return allCoachees;
	} catch (e) {
		message.error('Unable to get Coachee List. Please reload the page');
		return [];
	}
};
const getManipulateList = async (list: any) => {
	const users = list?.connections
		?.filter(
			(connection: any) =>
				connection?.coachee?.firstName !== '' &&
				connection?.coachee?.lastName !== '' &&
				connection?.coachee?.status
		)
		?.map((connection: any) => {
			let obj = {
				name: `${connection?.coachee?.firstName} ${connection.coachee?.lastName}`,
				email: connection?.coachee?.email,
				connectionId: connection._id,
				id: connection?.coachee?._id,
				img:
					connection?.coachee?.images?.basePath !== ''
						? `${connection?.coachee?.images?.basePath}/${connection?.coachee?.images?.directoryName}/${connection?.coachee?.images?.fileName}`
						: '',
				org: connection?.coachee?.organization,
			};
			return obj;
		});
	return _.uniqBy(users, 'id');
};
const createSession = async (payload: any) => {
	try {
		await axios.post(`${process.env.REACT_APP_API_HOST}${SESSIONS}`, payload);
		toast.success('Session created successfully');
	} catch (e) {
		toast.error('Failed to create Session. Contact Admin.');
	}
};
const updateSession = async (id: string, payload: any) => {
	try {
		await axios.patch(`${process.env.REACT_APP_API_HOST}${SESSIONS}/${id}`, payload);
		toast.success('Session updated successfully');
	} catch (e) {
		toast.error(e);
		// message.error("Unable to Update Session. Please try again");
	}
};
const removeSession = async (id: string) => {
	try {
		const res = await axios.delete(`${process.env.REACT_APP_API_HOST}${SESSIONS}/${id}`);
		return '';
	} catch (e) {
		message.error('Unable to get Coachee List. Please reload the page');
	}
};
const statusUpdate = async (id: string, payload: any) => {
	try {
		const res = await axios.patch(
			`${process.env.REACT_APP_API_HOST}${SESSIONS}/${id}/response`,
			payload
		);
		message.success('Status Updated');
	} catch (e) {
		message.error('Failed to create Session. Contact Admin.');
	}
};
const getAreasWorkedOnOptions = async () => {
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_HOST}${TAGS}`);
		const areas = res.data.data.documents.find(
			(tag: any) => tag.name == 'Topics I feel comfortable working on with Coachees'
		);
		const tags = areas.tags?.map((obj: any) => {
			obj.label = obj.name;
			obj.value = obj._id;
			return obj;
		});
		return tags;
	} catch (e) {
		message.error('Failed to get Tag types');
	}
};
const getCoachProfile = async (id: string) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_HOST}${PROFILE}/${id}?userType=coach`
		);
		if (res?.data?.data?.preference) {
			return res?.data?.data?.preference?.coach;
		}
		return {};
	} catch (e) {
		message.error('Failed to get Profile');
	}
};
const updateCoachProfile = async (id: string, payload: any) => {
	try {
		const res = await axios.put(`${process.env.REACT_APP_API_HOST}${PROFILE}/${id}`, payload);
	} catch (e) {
		message.error('Failed to get Profile');
	}
};
export {
	getSessionTypes,
	getCoacheeList,
	getManipulateList,
	getSessionById,
	createSession,
	updateSession,
	initScheduleSession,
	removeSession,
	statusUpdate,
	getAreasWorkedOnOptions,
	getCoachProfile,
	updateCoachProfile,
};
