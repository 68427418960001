import { PhoneInput, PhoneInputProps } from 'atoms/PhoneInput';
import { useField } from 'formik';
import { FC } from 'react';

export interface FormPhoneNumberProps extends PhoneInputProps {
	name: string;
	setCountryCode?: any;
	variant?: string;
	onChangeCustom?: any;
	showOptional?: boolean;
}

export const FormPhoneNumber: FC<FormPhoneNumberProps> = ({
	name,
	setCountryCode,
	onChangeCustom,
	...props
}) => {
	const [field, meta] = useField(name);
	const error = (meta.touched && meta.error) || '';
	return (
		<PhoneInput
			{...field}
			{...props}
			error={error}
			onChange={(e, data: any, country: any) => {
				field.onChange({
					target: { name, value: e },
				});
				onChangeCustom && onChangeCustom(e);
				setCountryCode(data?.dialCode);
			}}
		/>
	);
};
