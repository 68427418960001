import * as React from 'react';
import IconProps from './IconProps';

function CoachesIcon({ isselected, ...props }: IconProps) {
	if (isselected) {
		return (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.1108 19.5387V17.9233C21.1103 17.2075 20.8809 16.5121 20.4585 15.9463C20.0362 15.3806 19.4449 14.9765 18.7775 14.7976M15.6664 5.10536C16.3356 5.28329 16.9288 5.68746 17.3523 6.25414C17.7759 6.82081 18.0058 7.51777 18.0058 8.23513C18.0058 8.95249 17.7759 9.64945 17.3523 10.2161C16.9288 10.7828 16.3356 11.187 15.6664 11.3649M16.4442 19.5385V17.9232C16.4442 17.0663 16.1164 16.2446 15.533 15.6387C14.9495 15.0328 14.1582 14.6924 13.3331 14.6924H7.11095C6.28584 14.6924 5.49453 15.0328 4.91109 15.6387C4.32765 16.2446 3.99988 17.0663 3.99988 17.9232V19.5385H16.4442ZM13.3331 8.23098C13.3331 10.0153 11.9402 11.4617 10.222 11.4617C8.50384 11.4617 7.11097 10.0153 7.11097 8.23098C7.11097 6.44669 8.50384 5.00024 10.222 5.00024C11.9402 5.00024 13.3331 6.44669 13.3331 8.23098Z"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<circle cx="10" cy="8" r="3" fill="white" />
				<path
					d="M4 19.5V16.5C5.2 15.3 8.16667 14.6667 9.5 14.5L14.5 15L16 17V19.5H4Z"
					fill="white"
				/>
			</svg>
		);
	}
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 19.1537V17.5383C16 16.6815 15.6722 15.8598 15.0887 15.2539C14.5053 14.648 13.714 14.3076 12.8889 14.3076H6.66674C5.84163 14.3076 5.05032 14.648 4.46688 15.2539C3.88344 15.8598 3.55566 16.6815 3.55566 17.5383V19.1537"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.77782 11.0769C11.496 11.0769 12.8889 9.63049 12.8889 7.84621C12.8889 6.06193 11.496 4.61548 9.77782 4.61548C8.05962 4.61548 6.66675 6.06193 6.66675 7.84621C6.66675 9.63049 8.05962 11.0769 9.77782 11.0769Z"
				stroke="#FCFCFC"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.6666 19.1539V17.5386C20.666 16.8227 20.4366 16.1274 20.0143 15.5616C19.592 14.9959 19.0007 14.5918 18.3333 14.4128"
				stroke="#FCFCFC"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.2222 4.7207C15.8914 4.89864 16.4845 5.3028 16.9081 5.86948C17.3317 6.43616 17.5616 7.13311 17.5616 7.85047C17.5616 8.56783 17.3317 9.26479 16.9081 9.83147C16.4845 10.3981 15.8914 10.8023 15.2222 10.9802"
				stroke="#FCFCFC"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const MemoCoachesIcon = React.memo(CoachesIcon);
export default MemoCoachesIcon;
