import { Flex, StyledNavLink, Text } from "atoms";

export const Page404 = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text as="h1" fontWeight="100" fontSize="7rem" color="primary.600">
        404 :()
      </Text>
      <Text as="h2">Oops!!! The page you are requesting is not found.</Text>
      <StyledNavLink to="/">
        <Text as="p" fontSize="h1" color="secondary.0">
          ← Go back to Home Page
        </Text>
      </StyledNavLink>
    </Flex>
  );
};
