import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @ts-ignore
export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window?.document?.querySelector('#layout')?.scrollTo(0, 0);
		//window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

//export default ScrollToTop;
