import css from '@styled-system/css';
import { Box, Flex, Text } from 'atoms';
import { Error } from 'atoms/Error';
import { Input, InputBaseProps } from 'atoms/Input';
import { TextAreaBase } from 'atoms/TextArea';
import { Warning } from 'atoms/Warning';
import { FC } from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Merge } from 'typings/utils';

export const InputFieldBase = styled(Box)<InputFieldBaseProps>`
	position: relative;
	width: 100%;
	display: grid;
	align-items: center;
	gap: 8px;
	${space}
	input {
		${css({
			fontFamily: 'regular',
			borderRadius: '5px',
			borderWidth: '1px',
			fontSize: '16px',
			boxSizing: 'border-box',
			boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
			'&:focus': {
				outlineColor: 'secondary.50',
				outlineStyle: 'auto',
			},
			'&::placeholder': {
				color: 'white.300',
			},
			':disabled': {
				pointerEvents: 'none',
			},
		})}
	}
	textarea {
		${css({
			fontSize: '16px',
			'&:focus': {
				outlineColor: 'secondary.50',
				outlineStyle: 'auto',
			},
			'&::placeholder': {
				fontSize: '16px',
			},
		})};
	}
`;

interface InputFProps {
	label?: string;
	error?: string;
	warning?: string;
	labelHide?: boolean;
	placeholder?: string;
	value?: string;
	showOptional?: boolean;
	showOptionalText?: string;
}

export type InputFieldProps = Merge<InputFProps, InputBaseProps>;

export type InputFieldBaseProps = Merge<InputFieldProps, SpaceProps>;

export const InputField: FC<InputFieldProps> = ({
	label,
	placeholder,
	error,
	warning,
	labelHide = false,
	type,
	value,
	minHeight,
	showOptional,
	showOptionalText,
	...props
}) => {
	return (
		<InputFieldBase>
			{!labelHide && (
				<Flex justifyContent={'space-between'}>
					<Text as="label" fontSize="h5" color="primary.50" fontFamily="semibold">
						{label}
					</Text>
					{showOptional && <Text>{showOptionalText || 'Optional'}</Text>}
				</Flex>
			)}
			<Box marginBottom={error ? '0' : '1.6rem'}>
				{type === 'textarea' ? (
					<TextAreaBase
						placeholder={placeholder ?? label}
						position="relative"
						backgroundColor={
							error || warning ? 'red.100' : props.disabled ? 'white.30' : 'white.0'
						}
						onChange={(e: any) => props.onChange && props.onChange(e)}
						//mb={props.mb}
						border={'1px solid'}
						borderColor={error ? 'red.300' : 'grey.100'}
						borderRadius="5px"
						minHeight={minHeight ? minHeight : '100px'}
						defaultValue={props.defaultValue}
						disabled={props?.disabled ?? false}
						value={value && value}
					/>
				) : (
					<Input
						placeholder={placeholder ?? label}
						position="relative"
						backgroundColor={
							error || warning ? 'red.100' : props.disabled ? 'white.30' : 'white.0'
						}
						borderColor={error ? 'red.300' : 'grey.100'}
						type={type}
						disabled={props?.disabled ?? false}
						value={value && value}
						mb={error ? '0' : ''}
						{...props}
					/>
				)}
			</Box>{' '}
			{error && <Error text={error} mb="1.6rem" />}
			{warning && <Warning text={warning} />}
		</InputFieldBase>
	);
};
