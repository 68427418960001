import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Box, Button, Flex, StyledNavLink, Text, Icon } from 'atoms';
import { FormInput } from 'molecules';

import useAuth from '../context/Authentication';

import useVisibleState from 'hooks/useVisibleStates';
import Strings from 'strings';
const { User: UserStrings } = Strings;

const ForgotPasswordValidationSchema = yup.object().shape({
	Email: yup
		.string()
		.trim()
		.email(UserStrings.email.invalid)
		.required(UserStrings.email.required),
});

export type ForgotPasswordProps = {};

export const ForgotPassword: FC<ForgotPasswordProps> = () => {
	const {
		actions: { outsetaForgotPassword, checkEmailAlreadyExists },
	} = useAuth();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const { visible, show } = useVisibleState();

	const onSubmit = useCallback(
		(values) => {
			setLoading(true);
			checkEmailAlreadyExists({ email: values?.Email }, 'forgot', (data) => {
				if (data)
					outsetaForgotPassword(values, () => {
						show();
					});
			});

			setTimeout(() => {
				setLoading(false);
			}, 500);
		},
		[outsetaForgotPassword, show]
	);

	return (
		<Box
			mx={{ xs: '5%', sm: '2rem', lg: '16rem' }}
			minWidth={{ sm: '32rem', lg: '44rem' }}
			maxWidth="90%"
		>
			<Box mb="2rem">
				<Flex justifyContent={'start'} mt="0.7rem" mb="5.6rem">
					<Icon name="logo" height="7rem" type={'text'} />
				</Flex>
			</Box>

			<Box>
				<Text
					fontSize="h3"
					lineHeight="h3"
					color="primary.50"
					fontFamily="Archivo-Bold"
					mt="3rem"
					mb="2.5rem"
				>
					Forgot Password
				</Text>

				{visible ? (
					<>
						<Text color="primary.50" fontSize="14px" fontFamily="semibold" my="40px">
							We’ve sent a reset link to your email address.
						</Text>
						<Text color="primary.50" fontSize="14px" fontFamily="regular">
							Click the link you receive and you’ll be able to change your password
							and access My Coaching Place again!
						</Text>
						<Button
							width="100%"
							loading={loading}
							my="3rem"
							bg="secondary.50"
							color="white.0"
							fontFamily="semibold"
							onClick={() => navigate('/login')}
						>
							Go Back
						</Button>
						{/* <Box textAlign="center" mt="1.5rem">
						<Text as="h2" fontSize="h2" fontFamily="medium">
							Not received,{' '}
							<StyledNavLink
								to="#"
								onClick={() => {
									hide();
								}}
							>
								<Text as="span" fontFamily="semibold">
									Resend
								</Text>
							</StyledNavLink>
						</Text>
					</Box> */}
					</>
				) : (
					<Formik
						initialValues={{ Email: '' }}
						onSubmit={(formValues) => {
							onSubmit({
								...formValues,
								Email: formValues.Email.trim().toLowerCase(),
							});
						}}
						validationSchema={ForgotPasswordValidationSchema}
					>
						{() => (
							<Form>
								<Box mt="3rem">
									<FormInput
										label="Email"
										placeholder={UserStrings.email.placeholder}
										name="Email"
										mb="1rem"
									/>

									<Button
										type="submit"
										fontFamily="semibold"
										fontSize="16px"
										my="1rem"
										width="100%"
										height="41px"
										loading={loading}
									>
										Send reset email
									</Button>
								</Box>
								<Box textAlign="center" mt="1.5rem">
									<Text as="h2" fontSize="16px" fontFamily="medium">
										Go back to{' '}
										<StyledNavLink to="/login">
											<Text as="span" fontFamily="semibold">
												Login
											</Text>
										</StyledNavLink>
									</Text>
								</Box>
							</Form>
						)}
					</Formik>
				)}
			</Box>
		</Box>
	);
};
