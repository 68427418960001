import css from '@styled-system/css';
import { Box, BoxProps } from 'atoms/Box';
import { FC } from 'react';
import styled from 'styled-components';
import {
	border,
	color,
	ColorProps,
	fontSize,
	FontSizeProps,
	layout,
	LayoutProps,
	space,
	SpaceProps,
	typography,
	TypographyProps,
	variant,
} from 'styled-system';
import { Flex } from '../Flex';
import Loader from '../Loader';
import { Text } from '../Text';

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

const variants = {
	primary: {
		color: 'white.0',
		//transition: 'all 0.3s',
		borderRadius: 5,
		backgroundColor: 'secondary.50',
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
		'&:hover': {
			backgroundColor: 'secondary.50',
		},
		'&:focus': {
			outline: 0,
		},
		'&:disabled': {
			backgroundColor: 'grey.100',
			borderColor: 'grey.300',
			color: 'white.0',
		},
	},
	secondary: {
		color: 'secondary.50',
		borderRadius: 5,
		backgroundColor: 'white.0',
		border: '1px solid',
		borderColor: 'secondary.50',
		//transition: 'all 0.3s',
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
		'&:hover': {
			//color: 'primary.200',
			backgroundColor: 'white.0',
			borderColor: 'secondary.50',
		},
		'&:active': {
			backgroundColor: 'secondary.400',
			borderColor: 'secondary.50',
		},
		'&:disabled': {
			backgroundColor: 'white.30',
			color: 'white.0',
		},
	},
	// white: {
	// 	color: 'grey.100',
	// 	px: 8,
	// 	py: '7px',
	// 	backgroundColor: 'white.0',
	// 	transition: 'all 0.3s',
	// 	border: 1,
	// 	borderColor: 'grey.50',
	// 	borderStyle: 'solid',
	// 	fontWeight: 600,
	// 	letterSpacing: 0.4,
	// 	cursor: 'pointer',
	// 	borderRadius: 5,
	// 	boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
	// 	fontFamily: 'Inter-Semibold',
	// 	'&:hover': {
	// 		backgroundColor: 'white.0',
	// 	},
	// 	'&:focus': {
	// 		outline: 0,
	// 	},
	// 	'&:disabled': {
	// 		backgroundColor: 'white.300',
	// 		borderColor: 'white.300',
	// 		color: 'white.0',
	// 	},
	// },
	danger: {
		color: 'white.0',
		px: 6,
		py: 2,
		transition: 'all 0.3s',
		backgroundColor: 'red.300',
		border: '1px solid',
		borderColor: 'red.300',
		borderRadius: 4,
		'&:hover': {
			color: 'white.0',
			backgroundColor: 'error.0',
			borderColor: 'error.0',
			boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
		},
	},
	dark: {
		color: 'white.0',
		px: 6,
		py: 2,
		transition: 'all 0.3s',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		border: '2px solid',
		borderColor: 'white.300',
		borderRadius: 4,
		'&:hover': {
			color: 'white.0',
			backgroundColor: 'rgba(255, 255, 255, 0.3)',
			borderColor: 'white.0',
			boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
		},
	},
	transparent: {
		color: 'secondary.0',
		px: 6,
		py: 3,
		transition: 'all 0.3s',
		border: 0,
		backgroundColor: 'transparent',
		borderRadius: 4,
		'&:disabled': {
			borderColor: 'white.300',
			color: 'white.300',
		},
	},
	disabled: {
		color: 'white.0',
		backgroundColor: 'secondary.0',
		borderRadius: 4,
		border: '1px solid',
		borderColor: 'secondary.0',
		transition: 'all 0.3s',
		h3: {
			textTransform: 'uppercase',
		},
		'&:hover': {
			color: 'white.0',
			backgroundColor: 'secondary.0',
		},
	},
};

type Variant = keyof typeof variants;

interface ContainerProps
	extends ColorProps,
		LayoutProps,
		SpaceProps,
		TypographyProps,
		BoxProps,
		FontSizeProps {
	color?: string;
	size?: Size;
	variant?: Variant;
	type?: 'button' | 'submit' | 'reset';
	onClick?(e?: any): any | void;
	whiteSpace?: any;
	disabled?: boolean;
	id?: string;
	value?: string;
	className?: string;
}

export const Container = styled(Box)<ContainerProps>`
	cursor: pointer;
	position: relative;
	font-family: 'Inter-Semibold';
	white-space: nowrap;
	&.xl {
		font-size: 16px;
		padding: 13px 25px;
		height: 49px;
	}
	&.lg {
		font-size: 16px;
		padding: 9px 17px;
		height: 41px;
	}
	&.md {
		font-size: 14px;
		padding: 9px 17px;
		height: 38px;
	}
	&.sm {
		font-size: 14px;
		padding: 9px 13px;
		height: 38px;
	}
	&.xs {
		font-size: 12px;
		padding: 7px 11px;
		height: 32px;
	}
	${variant({
		variants,
	})}
	${({ disabled }) =>
		disabled &&
		css({
			// backgroundColor: 'gray.200',
			boxShadow: 'none',
			cursor: 'not-allowed',
		})}
  ${({ whiteSpace }) =>
		whiteSpace &&
		css({
			whiteSpace,
		})}
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${fontSize}
  ${border}
  &:disabled {
		background: #9ca3af;
	}
`;

export interface ButtonProps extends ContainerProps {
	title?: string;
	loading?: boolean;
	disabled?: boolean;
	'data-testid'?: string;
	id?: string;
	className?: string;
}

export const Button: FC<ButtonProps> = ({
	disabled,
	loading,
	children,
	fontFamily,
	variant: v = 'primary',
	className = 'lg',
	...rest
}) => {
	return (
		<Container
			variant={v}
			as="button"
			{...rest}
			disabled={disabled || loading}
			overflow="hidden"
			className={className}
		>
			{loading && (
				<Flex
					left={0}
					right={0}
					position="absolute"
					justifyContent="center"
					alignItems="center"
					fontSize={2}
					height="15px"
					width="15px"
					mx="auto"
				>
					<Loader loading={loading} />
				</Flex>
			)}
			<Text opacity={loading ? 0 : 1} fontFamily={fontFamily} id={rest.id}>
				{children}
			</Text>
		</Container>
	);
};
