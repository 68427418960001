import * as React from "react";

function MinimizeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M13.333 6.66663L9.33301 6.66663L9.33301 2.66663"
        stroke={props.stroke||"black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 9.33337L6.6665 9.33337L6.6665 13.3334"
        stroke={props.stroke||"black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33333 6.66663L14 1.99996"
        stroke={props.stroke||"black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 9.33333L1.99984 14"
        stroke={props.stroke||"black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMinimizeIcon = React.memo(MinimizeIcon);
export default MemoMinimizeIcon;
